import React from 'react';
import { FormattedMessage } from 'react-intl';
import empl1 from '../Images/ATS-V2-SiegeSocial.jpg';
import empl2 from '../Images/ATS-V2-Gestionnaires.jpg';
import empl3 from '../Images/ATS-V2-RPA.jpg';
import empl4 from '../Images/ATS-V2-multi.jpg';
import emplhands from '../Images/ATS-V2-CeQuiVousAttend.jpg';
import cogirlogo1 from '../Images/ATS-V2-cogirlogo1.png';
import humaniser from '../Images/ATS-V2-HUMANISER-GREEN.png';
import creer from '../Images/ATS-V2-CREER-GREEN.png';
import sedepasser from '../Images/ATS-V2-SEDEPASSER-GREEN.png';

const homepagebanners = () => {
  
  return (
    <div className="mb-4">
      <div className="encadrecoworker">
        <div className="row">
          <div className="col mt-4 text-center">
            <p>
              <span className="coworkertitle1 text-uppercase"><FormattedMessage id="coworker.title1" /></span>
              <span className="coworkertitle2 text-uppercase"><FormattedMessage id="coworker.title2" /></span>
            </p>
          </div>
        </div>
        <div className="card-deck mt-3">
          <div className="card mb-2">
            <img src={empl1} alt="Employé" />
            <div className="card-body coworkerbody">
              <p className="card-text coworkertitle3 text-uppercase"><strong><FormattedMessage id="coworker.titreprofessionnel" /></strong></p>
              <p className="card-text coworkerparagraphe"><FormattedMessage id="coworker.paragrapheprofessionnel1" /></p>
              <p className="card-text coworkerparagraphe"><FormattedMessage id="coworker.paragrapheprofessionnel2" /></p>
            </div>
          </div>
          <div className="card mb-2">
            <img src={empl2} alt="Employé" />
            <div className="card-body coworkerbody">
              <p className="card-text coworkertitle3 text-uppercase"><strong><FormattedMessage id="coworker.titregestionnaire" /></strong></p>
              <p className="card-text coworkerparagraphe"><FormattedMessage id="coworker.paragraphegestionnaire1" /></p>
              <p className="card-text coworkerparagraphe"><FormattedMessage id="coworker.paragraphegestionnaire2" /></p>
            </div>
          </div>
          <div className="card mb-2">
            <img src={empl3} alt="Employé" />
            <div className="card-body coworkerbody">
              <p className="card-text coworkertitle3 text-uppercase"><strong><FormattedMessage id="coworker.titrerpa" /></strong></p>
              <p className="card-text coworkerparagraphe"><FormattedMessage id="coworker.paragrapherpa1" /></p>
              <p className="card-text coworkerparagraphe"><FormattedMessage id="coworker.paragrapherpa2" /></p>
            </div>
          </div>
          <div className="card mb-2">
            <img src={empl4} alt="Employé" />
            <div className="card-body coworkerbody">
              <p className="card-text coworkertitle3 text-uppercase"><strong><FormattedMessage id="coworker.titrehorsrpa" /></strong></p>
              <p className="card-text coworkerparagraphe"><FormattedMessage id="coworker.paragraphehorsrpa1" /></p>
              <p className="card-text coworkerparagraphe"><FormattedMessage id="coworker.paragraphehorsrpa2" /></p>
            </div>
          </div>
        </div>
      </div>
      <div className="row encadrecogiroffer mt-4">
        <div className="col-md-6">
          <p className="emplhandstitle text-uppercase"><FormattedMessage id="emplhands.title" /></p>
          <ul className='empllisttext'>
            <li><FormattedMessage id="emplhands.list1" /></li>
            <li><FormattedMessage id="emplhands.list2" /></li>
            <li><FormattedMessage id="emplhands.list3" /></li>
            <li><FormattedMessage id="emplhands.list4" /></li>
            <li><FormattedMessage id="emplhands.list5" /></li>
            <li><FormattedMessage id="emplhands.list6" /></li>
            <li><FormattedMessage id="emplhands.list7" /></li>
            <li><FormattedMessage id="emplhands.list8" /></li>
          </ul>
        </div>
        <div className="col-md-6">
          <img className="img-fluid" src={emplhands} alt="hands" />
        </div>
      </div>
      <div className="row encadrevotre">
        <div className="col mt-4 mb-4">
          <div className="text-center">
            <img className="img-fluid" src={cogirlogo1} alt="logo1" />
          </div>
          <div className="container mt-2">
            <div className="row">
              <div className="col-md-4 mt-4">
                <p className="chevrontitle text-uppercase"><FormattedMessage id="chevrons.titre1" /></p>
                <div className='shortgreenline10'></div>
                <div className='chevrontextbox mt-4'>
                  <p className="chevrontext"><FormattedMessage id="chevrons.text1" /></p>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <p className="chevrontitle text-uppercase"><FormattedMessage id="chevrons.titre2" /></p>
                <div className='shortgreenline10'></div>
                <div className='chevrontextbox mt-4'>
                  <p className="chevrontext"><FormattedMessage id="chevrons.text2" /></p>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <p className="chevrontitle text-uppercase"><FormattedMessage id="chevrons.titre3" /></p>
                <div className='shortgreenline10'></div>
                <div className='chevrontextbox mt-4'>
                  <p className="chevrontext"><FormattedMessage id="chevrons.text3" /></p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around">
              <div className="col-md-4 mt-4">
                <p className="chevrontitle text-uppercase"><FormattedMessage id="chevrons.titre4" /></p>
                <div className='shortgreenline10'></div>
                <div className='chevrontextbox mt-4'>
                  <p className="chevrontext"><FormattedMessage id="chevrons.text4" /></p>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <p className="chevrontitle text-uppercase"><FormattedMessage id="chevrons.titre5" /></p>
                <div className='shortgreenline10'></div>
                <div className='chevrontextbox mt-4'>
                  <p className="chevrontext"><FormattedMessage id="chevrons.text5" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col mt-4 text-center">
          <p>
            <span className="coworkertitle2 text-uppercase"><FormattedMessage id="adn.text1" /></span>
            <span className="coworkertitle1 text-uppercase"><FormattedMessage id="adn.text2" /></span>
          </p>
            <img className="img-fluid mr-4" src={humaniser} alt="humaniser" />
            <img className="img-fluid mr-4" src={creer} alt="creer" />
            <img className="img-fluid" src={sedepasser} alt="sedepasser" />
        </div>
      </div>
    </div>

  );
}

export default homepagebanners;
