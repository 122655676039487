import React from 'react';
import {FormattedMessage} from 'react-intl';

class ProgressBar extends React.Component {

  gotToForm = (val1) => {
    let dataObj = this.props.dataObj;
    let step = {"step" : val1};
    step.dataObj = dataObj;
    this.props.nextForm(step);
  }


  render() {
    const step = this.props.step;
    //[myClassf0, myClassf1, myClassfc1, myClassfl1, myClassfl2, myClassfl3, myClassfl4, myClassfl5, myClassfl6];
    
    let edu = (this.props.values[3] && this.props.values[4]);
    let job = (this.props.values[5] && this.props.values[6]);

    return (
        <div className="container text-center">
          <div className="btn-group" role="group" aria-label="Progress Bar">
            <button type="button" className={step === "fl1" ? "btn btn-dark" : "btn btn-outline-dark"} onClick={() => this.gotToForm("fl1")} disabled={false}><FormattedMessage id="progress.contact"/></button>
            <button type="button" className={step === "fl3" ? "btn btn-dark" : "btn btn-outline-dark"} onClick={() => this.gotToForm("fl3")} disabled={!edu}><FormattedMessage id="progress.education"/></button>
            <button type="button" className={step === "fl5" ? "btn btn-dark" : "btn btn-outline-dark"} onClick={() => this.gotToForm("fl5")} disabled={!job}><FormattedMessage id="progress.jobsHistory"/></button>
          </div>

          <hr />
        </div>
    );
  }
}

export default ProgressBar;
