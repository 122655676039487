import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { FormattedMessage } from 'react-intl';
import TextInput from '../Controls/TextInput.js';
import Select from 'react-select';
import 'react-table/react-table.css';
import axios from 'axios';
import MyMapComponent from '../Controls/MapComponent1.js';
import Homepagebanners from '../Controls/AccueilWidget.js';
import Coworker from '../Controls/Coworker.js';
import ZoomLevelListName from '../translations/ZoomLevelListName.json';
import Modal from '../Controls/Modal.js';
import { formatVolet, formatSearchTable, formatList, setJobApply, formatBuildingsList, formatBuildingForSelect, formatStringWithSymbol, getBuildingsForSelect1 } from '../Utils/Search';

class Search extends React.Component {
  constructor(props) {
    super(props);
    let dataObj = this.props.dataObj;
    let sc = dataObj.searchMap ? dataObj.searchMap.selectedCategory : "";
    let sr = dataObj.searchMap ? dataObj.searchMap.selectedRegion : "";

    if (dataObj.reset) {
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.history.replaceState({}, document.title, "/");
      window.location.replace("/");
    }

    this.myRef = [];
    this.myMap = React.createRef();
    this.markers = [];
    this.circleMeters = [];

    let noValidate = dataObj ? dataObj.noValidate : false;

    this.state = {
      activepage: 1,
      perpage: 5,
      perPageList: [5, 10, 25, 50, 100],
      numberofjob: 0,
      dataPerPage: [],
      totalpage: 1,
      firstPage: 1,
      lastPage: 1,

      errorFromServer: false,
      errorFromServerMessage: [],

      /** CATEGORY **/

      jobsCategoryList: [],
      categoryPstMsg: false,
      categoryFindPst: false,

      /** REGIONS **/

      regionsList: [],

      /** JOBS **/

      data: [], //jobList affichÃ© par page
      allJobs: [],

      /** ClassName for building**/
      classeList: [],
      selectedClass: "",

      checkedJobsAvailable: false,

      buildingList: [],
      prp: [], //liste des immeubles pour la carte
      buildSel: [], //liste des immeubles du select
      buildingListByFilter: [],
      buildOnlyJobAvailable: [], //liste des immeubles oÃ¹ il y a des emplois disponibles
      search: "",
      myZoom: 5,
      myCenter: { lat: 46.7641162, lng: -71.33518850000002 },
      myLat: 45.460841,
      mylng: -73.467085,
      myText: {},//"Cogir",
      showInfo: false,

      selectedCategory: sc,
      selectedRegion: sr,
      selectedBuilding: "",
      selectedBuildingOnlyJob: "",

      selectedKm: "",
      ZoomLevelList: this.props.language === 'fr' ? ZoomLevelListName.fr : ZoomLevelListName.en, //liste des niveau de zoom en km
      zipaddress: "",

      noValidate: noValidate,
      showModal: false
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.filterList = this.filterList.bind(this);
    this.closeInfoBox = this.closeInfoBox.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.handleRegion = this.handleRegion.bind(this);
    this.handleKm = this.handleKm.bind(this);
    this.handleZip = this.handleZip.bind(this);
    this.handleBuilding = this.handleBuilding.bind(this);
    this.handleBuildingOnlyJobsAvailable = this.handleBuildingOnlyJobsAvailable.bind(this);
    this.handleClasseList = this.handleClasseList.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  //Language switcher
  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.ajaxGetData();
      this.setState({ selectedCategory: "" });
      this.setState({ selectedBuilding: "" });
      this.setState({ selectedBuildingOnlyJob: "" });
      this.setState({ selectedRegion: "" });
      this.setState({ selectedKm: "" });
      this.initMap();
      let joblang = this.getListLanguage(this.state.allJobs);
      this.initPagination(joblang);
    }
  }

  componentDidMount() {
    window.history.replaceState({}, document.title, "/");
    this.ajaxGetData();
  }

  ajaxGetData = () => {

    const url = this.props.dataObj.env ? this.props.dataObj.env.urlJson : "https://jobs.cogir.net/ajax/ConnectDb.php";
    axios.post(url, {
      request: "jobsearch"
    }, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {

        let cat = formatList(response.data.cat, "cat");
        let reg = formatList(response.data.reg, "reg");
        let vol = formatVolet(response.data.vol);
        let job = formatSearchTable(response.data, cat, reg, vol);
        let classe = formatList(response.data.class, "class");

        let joblang = this.getListLanguage(job);

        let prp = formatBuildingsList(response.data.prp, joblang);

        let buildSel = formatBuildingForSelect(response.data.prp, joblang, false, response.data.class);

        let buildSellang = this.getListLanguage(buildSel);

        let buildOnlyJobAvailable = formatBuildingForSelect(response.data.prp, joblang, true, response.data.class);



        this.setState({ jobsCategoryList: cat });
        this.setState({ regionsList: reg });
        this.setState({ numberofjob: joblang.length });
        this.setState({ allJobs: job });

        this.setState({ prp: prp });
        this.setState({ buildingList: prp });
        this.setState({ buildSel: buildSel });
        this.setState({ buildingListByFilter: buildSellang });
        this.setState({ buildOnlyJobAvailable: buildOnlyJobAvailable });
        this.setState({ classeList: classe });

        let dataObj = this.props.dataObj;

        if (dataObj.resid === "all") {
          this.setState({ checkedJobsAvailable: true });
          let objForClass = this.getListLanguage(classe);
          this.handleClasseList(objForClass[1]);
          let allJobsLg = this.getListLanguage(job);
          let onlyRes = allJobsLg.filter(a => a.Class === 1);

          this.adjustJobList(onlyRes, []);
        }

        if (dataObj.searchMap) {
          this.validateSearchMapValue(joblang, dataObj);
        }
        else {
          this.initPagination(joblang);
        }

      })
      .catch((error) => {
        this.setState({ errorFromServerMessage: this.state.errorFromServerMessage.concat(error.message) });
        this.setState({ errorFromServer: true });
      });
  }

  setDataObj = (valueToAdd) => {
    const { checkedJobsAvailable, selectedClass, selectedBuilding, classeList, selectedCategory, selectedRegion, search, activepage,
      perpage, firstPage, lastPage, myText, data, buildingList, zipaddress, selectedKm, numberofjob, buildingListByFilter } = this.state;
    let dataObj = this.props.dataObj;
    dataObj.appliedTo = valueToAdd;

    dataObj.searchMap = {
      "selectedClass": selectedClass,
      "classeList": classeList,
      "selectedBuilding": selectedBuilding,
      "checkedJobsAvailable": checkedJobsAvailable,
      "selectedCategory": selectedCategory,
      "selectedRegion": selectedRegion,
      "search": search,
      "activepage": activepage,
      "perpage": perpage,
      "firstPage": firstPage,
      "lastPage": lastPage,
      "buildingOnMap": myText,
      "data": data,
      "buildingList": buildingList,
      "zipaddress": zipaddress,
      "selectedKm": selectedKm,
      "numberofjob": numberofjob,
      "buildingListByFilter": buildingListByFilter,
      "myRef": this.myRef,
      "myMap": this.myMap,
      "markers": this.markers,
      "circleMeters": this.circleMeters
    };

    return dataObj;
  }

  validateSearchMapValue = (joblang, dataObj) => {
    //maps
    let buildonmap = dataObj.searchMap.buildingOnMap;
    let selectedClass = dataObj.searchMap.selectedClass;
    let checkedJobsAvailable = dataObj.searchMap.checkedJobsAvailable;
    let selectedBuilding = dataObj.searchMap.selectedBuilding;
    let classeList = dataObj.searchMap.classeList;
    let buildingList = dataObj.searchMap.buildingList;
    let zipaddress = dataObj.searchMap.zipaddress;
    let selectedKm = dataObj.searchMap.selectedKm;
    let numberofjob = dataObj.searchMap.numberofjob;
    let buildingListByFilter = dataObj.searchMap.buildingListByFilter;
    let circleMeters = dataObj.searchMap.circleMeters;

    let setData = true;

    this.setState({ checkedJobsAvailable: checkedJobsAvailable });
    this.setState({ selectedClass: selectedClass });
    this.setState({ selectedBuilding: selectedBuilding });
    this.setState({ buildingList: buildingList });
    this.setState({ classeList: classeList });
    this.setState({ buildingListByFilter: buildingListByFilter });

    this.setState({ zipaddress: zipaddress });
    this.setState({ selectedKm: selectedKm });

    this.setState({ numberofjob: numberofjob });

    //Si un immeuble est sélectionnés dans la liste des propriétés
    if (selectedBuilding) {
      this.setMapForBuildingSelect(selectedBuilding);
    }

    else {
      //Si un icône d'immeuble a été cliqué sur la carte
      if (buildonmap.hasOwnProperty("item") && buildonmap.hasOwnProperty("positions") && buildonmap.hasOwnProperty("center")) {
        this.handleMarkerClick(buildonmap.item, buildonmap.positions, buildonmap.center);
      }
    }

    //Si une recherche par code postal ou adresse a été effectuée
    if (dataObj.searchMap.myMap && zipaddress && selectedKm.value && dataObj.searchMap.markers && circleMeters) {
      this.filterMap();
    }

    //Filtre de la liste de poste
    let sm = dataObj.searchMap;
    let ss = sm.search
    if (ss) {
      this.setSearch(sm.search);
      setData = false;
    }

    let sc = sm.selectedCategory && sm.selectedCategory.length;
    let sr = sm.selectedRegion && sm.selectedRegion.length;

    if (sc || sr) {
      this.setValueForJobListFilter(sm.selectedRegion, sm.selectedCategory);
      setData = false;
    }

    //Pagination
    let pp = parseInt(sm.perpage);
    let page = sm.activepage !== 1;

    if (page) {
      this.getPage(sm.activepage);
      this.setState({ firstPage: sm.firstPage });
      this.setState({ lastPage: sm.lastPage });
    }

    if (pp > 5) {
      this.setPerPageAndList(pp, sm.activepage);
    }

    if (setData) {
      this.setState({ data: dataObj.searchMap.data });
    }

    if (joblang.length === numberofjob && !page && pp === 5) {
      this.initPagination(joblang);
    }

    //position de la page dans l'écran
    let top = document.getElementById("jobsList").offsetTop;
    window.scrollTo(0, top);
  }

  //fonctions utilitaires
  getListLanguage = (list) => {
    const lg = this.props.language;
    return lg === "fr" ? list.fr : list.en;
  }

  setCheckJobAvailable = (val, classeList, selectedClass, allJobsLg) => {

    this.setState({ checkedJobsAvailable: val });

    let classNameList = this.getListLanguage(classeList);

    if (val) {
      if (selectedClass && selectedClass.value !== 0) {
        this.handleClasseList(selectedClass, true);
      }

      else {
        this.handleClasseList(classNameList[0], true);
      }
    }
    else {
      this.setState({ selectedBuilding: "" });
      this.setState({ selectedClass: "" });
      if (selectedClass && selectedClass.value !== 0) {
        this.handleClasseList(selectedClass, false);
      }

      else {
        this.handleClasseList(classNameList[0], false);
      }
    }
  }

  //set some variables state
  handleCheck(event) {
    const { classeList, selectedClass, allJobs } = this.state;
    let val = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    let allJobsLg = this.getListLanguage(allJobs);
    this.initPagination(allJobsLg);
    this.setCheckJobAvailable(val, classeList, selectedClass, allJobsLg);
  }

  handleCategory(selected) {

    let data = this.getListLanguage(this.state.allJobs);
    this.initPagination(data);
    this.setState({ selectedCategory: selected });
    this.setState({ categoryPstMsg: false });
    this.setState({ categoryFindPst: false });
    this.setState({ search: "" });
    this.setState({ perpage: 5 });
  }

  handleRegion(selected) {

    let data = this.getListLanguage(this.state.allJobs);
    this.initPagination(data);
    this.setState({ selectedRegion: selected });
    this.setState({ categoryPstMsg: false });
    this.setState({ categoryFindPst: false });
    this.setState({ search: "" });
    this.setState({ perpage: 5 });
  }

  handleZip(event) {
    const value = event.target.value;
    this.setState({ zipaddress: value });
  }

  handleKm(selected) {
    this.setState({ selectedKm: selected });
  }

  //Search by zipaddress and zoom level
  filterMap = () => {
    const { zipaddress, selectedKm } = this.state;
    const map = this.myMap.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    let markers = this.markers;
    this.SearchBy(map, zipaddress, selectedKm.value, markers, this.circleMeters);
  }

  SearchBy = (map, value, zoom, markers, circleMeters) => {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'address': value }, function (results, status) {
      if (results && status === 'OK') {
        let latLng = results[0].geometry.location;
        map.setCenter(results[0].geometry.location);
        map.setZoom(zoom);
        var marker = new window.google.maps.Marker({
          map: map,
          position: results[0].geometry.location
        });
        if (markers.length > 0) {
          markers[0].setMap(null);
        }
        markers[0] = marker;

        /*circle*/
        //meter by pixel
        let meters = 156543.03392 * Math.cos(latLng.lat() * Math.PI / 180) / Math.pow(2, zoom); //from stackoverflow

        let circleMeter = new window.google.maps.Circle({
          strokeColor: '#96c11e',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#96c11e',
          fillOpacity: 0.15,
          map: map,
          center: latLng,
          radius: (meters * 400) / 2
        });

        if (circleMeters.length > 0) {
          circleMeters[0].setMap(null);
        }
        circleMeters[0] = circleMeter;
      }
    });
  }

  /***********************************************/
  setValueForJobListFilter = (selectedRegion, selectedCategory) => {
    let data = this.getListLanguage(this.state.allJobs);

    this.setState({ categoryPstMsg: false });
    this.setState({ categoryFindPst: false });

    let regValues = [];
    let catValues = [];
    let fvalues = [];

    if (selectedRegion.length > 0) {
      for (let i = 0; i < selectedRegion.length; i++) {
        let res = data.filter(pst => pst.Region === selectedRegion[i].label);
        if (res.length > 0) {
          regValues.push(res);
        }
      }
    }

    if (selectedCategory.length > 0) {
      for (let i = 0; i < selectedCategory.length; i++) {
        let res = data.filter(pst => pst.Category === selectedCategory[i].label);
        if (res.length > 0) {
          catValues.push(res);
        }
      }
    }

    if (selectedRegion.length > 0 && selectedCategory.length === 0) {
      fvalues = regValues.flat();
    }

    if (selectedRegion.length === 0 && selectedCategory.length > 0) {
      fvalues = catValues.flat();
    }

    if (selectedRegion.length > 0 && selectedCategory.length > 0) {
      let allValues = regValues.concat(catValues);

      let allValuesflat = allValues.flat();

      for (let i = 0; i < allValuesflat.length; i++) {
        if (allValuesflat.includes(allValuesflat[i], i + 1)) {
          fvalues.push(allValuesflat[i]);
        }
      }
    }

    if (fvalues.length > 0) {
      this.adjustJobList(fvalues, []);
      this.setState({ categoryFindPst: true });
    }
    else {
      this.setState({ categoryPstMsg: true });
    }
  }

  //Filter job list by region and category
  filterBy = () => {

    const { selectedRegion, selectedCategory } = this.state;
    let data = this.getListLanguage(this.state.allJobs);
    this.initPagination(data);
    this.setValueForJobListFilter(selectedRegion, selectedCategory);
  }

  /***********************************************/

  //Google map marker and infowindow function related

  handleClasseList(selected, checkedjobstatus) {
    const { prp, buildSel, checkedJobsAvailable } = this.state;
    this.setState({ selectedBuilding: "" });
    const lg = this.props.language;
    this.setState({ selectedClass: selected });

    /*Map and Select*/
    let buildingListByClass = prp;
    let buildingListForSelect = this.getListLanguage(buildSel);
    if (selected.value !== 0) {
      buildingListByClass = prp.filter(p => p.classId === selected.value);
    }

    let buildingListToFormat = buildingListByClass;

    let jobstatus = checkedjobstatus === true || checkedjobstatus === false ? checkedjobstatus : checkedJobsAvailable;

    if (jobstatus) {
      buildingListToFormat = buildingListByClass.filter(b => b.jobsAvailable > 0);
    }

    this.setState({ buildingList: buildingListToFormat });

    buildingListForSelect = getBuildingsForSelect1(buildingListToFormat, lg);

    this.setState({ buildingListByFilter: buildingListForSelect });

    this.initCenterAndZoom();
  }

  handleBuilding(selected) {
    this.setState({ selectedBuilding: selected });
    this.setMapForBuildingSelect(selected);
  }

  handleBuildingOnlyJobsAvailable(selected) {
    this.setState({ selectedBuildingOnlyJob: selected });
    this.setMapForBuildingSelect(selected);
  }

  setMapForBuildingSelect = (selected) => {
    const mapZoom = this.myMap.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    mapZoom.setZoom(15);

    const { prp } = this.state;

    let building = prp.filter(build => build.id === selected.value);

    this.setCenter(building[0].coord.lat, building[0].coord.lng);

    this.setInfoWindow(selected.value);
  }

  handleMarkerClick(item, ind, center) {
    const mapZoom = this.myMap.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    mapZoom.setZoom(15);

    const pos = center ? center : this.myRef[ind].current.getPosition();

    let build = {};
    build.name = item.name;
    build.class = formatStringWithSymbol(item.class, "/", this.props.language);
    build.classId = item.classId;
    build.positions = ind;
    build.item = item;
    build.center = pos;

    this.setState({ myCenter: pos });
    this.setState({ myLat: item.coord.lat });
    this.setState({ mylng: item.coord.lng });
    this.setState({ myText: build });
    this.setState({ showInfo: true });
  }

  setCenter = (lat, lng) => {
    let coord = { lat: lat, lng: lng };
    this.setState({ myCenter: coord });
    this.setState({ myLat: coord.lat });
    this.setState({ mylng: coord.lng });
  }

  setInfoWindow = (idBuilding) => {
    const { prp } = this.state;
    let building = prp.filter(build => build.id === idBuilding);
    let build = {};
    build.name = building[0].name;
    build.class = formatStringWithSymbol(building[0].class, "/", this.props.language);
    build.item = building[0];

    this.setState({ myText: build });
    this.setState({ showInfo: true });
  }

  initMap = (onlyList) => {

    this.setState({ zipaddress: "" });
    this.setState({ selectedKm: "" });
    this.setState({ selectedBuilding: "" });
    this.setState({ selectedBuildingOnlyJob: "" });
    this.setState({ selectedClass: "" });
    this.setState({ checkedJobsAvailable: false });
    this.setState({ buildingList: this.state.prp });
    this.setState({ search: "" });
    this.setState({ myText: {} });
    this.setState({ categoryPstMsg: false });
    this.setState({ categoryFindPst: false });
    this.setState({ selectedCategory: "" });
    this.setState({ selectedRegion: "" });

    let allJobsLg = this.getListLanguage(this.state.allJobs);
    this.initPagination(allJobsLg);

    if (this.markers.length > 0) {
      this.markers[0].setMap(null);
    }

    if (this.circleMeters.length > 0) {
      this.circleMeters[0].setMap(null);
    }

    const mapZoom = this.myMap.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    mapZoom.setZoom(5);

    let myCenter = { lat: 46.7641162, lng: -71.33518850000002 };

    this.setState({ myCenter: myCenter });
    this.setState({ showInfo: false });
  }

  initCenterAndZoom = () => {
    const mapZoom = this.myMap.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    mapZoom.setZoom(5);

    let myCenter = { lat: 46.7641162, lng: -71.33518850000002 };

    this.setState({ myCenter: myCenter });
    this.setState({ showInfo: false });
  }

  createDynamicRef = (num) => {
    for (let i = 0; i < num; i++) {
      this.myRef.push(React.createRef());
    }
  }


  //filter job list when infowindow button is click
  filterList = (item) => {
    if (item.jobsAvailable > 0) {

      this.setState({ selectedCategory: "" });
      this.setState({ selectedRegion: "" });
      this.setState({ search: "" });

      let data = this.getListLanguage(this.state.allJobs);
      let filterData = data.filter(pst => pst.LocId === item.id);

      this.adjustJobList(filterData, []);

      let top = document.getElementById("jobsList").offsetTop;
      window.scrollTo(0, top);
    }
  }

  closeInfoBox = () => {
    this.setState({ showInfo: false });
  }

  /***********************************************/

  setSearch = (value) => {

    this.setState({ selectedCategory: "" });
    this.setState({ selectedRegion: "" });
    this.setState({ perpage: 5 });

    this.setState({ search: value });
    const list = this.getListLanguage(this.state.allJobs);
    const filterList = list.filter(pst => pst.Title.toLowerCase().includes(value));

    if (list.length === filterList.length) {
      this.initPagination(list);
    }

    else {
      this.adjustJobList(filterList, []);
    }

  }

  //Search by job title
  handleSearch(event) {
    const value = event.target.value;
    this.setSearch(value);
  }

  /***********************************************/

  //get and move job chosen to jobdescription
  getDataSelected = (Id) => {
    let step = { "step": "JobDesc" };

    let data = {
      fr: this.state.allJobs.fr.filter(d => d.Id === Id),
      en: this.state.allJobs.en.filter(d => d.Id === Id)
    };

    let objFormat = setJobApply(data);
    step.dataObj = this.setDataObj(objFormat);

    this.props.dataFrom(data);
    this.props.nextForm(step);
  }

  setSpontaneous = () => {

    let jobApply = { fr: [{ Title: "Candidature spontanée" }], en: [{ Title: "Spontaneous application" }], type: "spontaneous" };
    let objFormat = setJobApply(jobApply);

    let step = { "step": "fc1" };
    step.dataObj = this.setDataObj(objFormat);

    this.props.nextForm(step);
  }

  setNextForm = (step) => {
    this.props.nextForm(step);
  }

  moveData = (data) => {
    this.props.dataFrom(data);
  }

  //Fonction de pagination
  adjustJobList = (joblist, perPageList) => {
    this.setState({ data: joblist });
    this.setState({ dataPerPage: perPageList });
    this.setState({ numberofjob: joblist.length });
    this.setState({ perpage: 5 });
    this.setState({ firstPage: 1 });
    this.setState({ lastPage: 1 });
  }

  initPagination = (all) => {
    let totalpage = this.getTotalLength(5, all);
    let a = 0;
    let b = all.length >= 5 ? 5 : all.length;
    let l = totalpage >= 5 ? 5 : totalpage;

    let newarray = [];
    for (let i = a; i < b; i++) {
      newarray.push(all[i])
    }

    this.setState({ data: newarray });
    this.setState({ perpage: 5 });
    this.setState({ dataPerPage: all });
    this.setState({ numberofjob: all.length });
    this.setState({ firstPage: 1 });
    this.setState({ lastPage: l });
    this.setState({ activepage: 1 });
  }

  getTotalLength = (perpagenumber, alljob) => {
    let totallength = alljob.length;
    let perpage = totallength / perpagenumber;
    let modulus = totallength % perpagenumber;

    if (modulus > 0) {
      perpage += 1;
    }
    return Math.floor(perpage);
  }

  getPerPage = (perpagenumber, alljob) => {
    if (alljob) {
      let totalpage = this.getTotalLength(perpagenumber, alljob);
      return totalpage > 5 ? 5 : totalpage;
    }
  }

  getNext = (number, pageslist) => {
    let alljob = this.getListLanguage(this.state.allJobs);
    let perpagenumber = this.state.perpage;
    let totalpage = this.getTotalLength(perpagenumber, alljob);
    let active = number;

    if (number < totalpage) {
      active = number + 1;
      let val = number % 5;
      if (val === 0) {
        let last = number + 5;
        if (last > totalpage) {
          last = totalpage;
        }
        this.setState({ firstPage: active });
        this.setState({ lastPage: last });
      }
    }
    this.getPage(active);
  }

  getPrev = (number, pageslist) => {
    let active = number - 1;
    if (active > 0) {
      let val = active % 5;

      if (val === 0) {
        let first = number - 5;
        if (first < 1) {
          first = 1;
        }
        this.setState({ firstPage: first });
        this.setState({ lastPage: active });
      }
      this.getPage(active);
    }
  }

  getPage = (pagenumber, perpagevalue, list) => {
    const { perpage, allJobs } = this.state;
    this.setState({ activepage: pagenumber });

    let all = this.getListLanguage(allJobs);

    if (list) {
      all = list;
    }

    let totallength = all.length;
    let modulus = totallength % perpage;

    let perpagecp = perpagevalue ? perpagevalue : perpage;

    let a = 0;
    let b = perpagecp;

    if (pagenumber > 1) {
      a = perpagecp * (pagenumber - 1);
      b = perpagecp * pagenumber;

      if (b > totallength) {
        b = a + modulus;
      }
    }

    if (perpagecp > totallength) {
      a = 0
      b = totallength;
    }

    let newarray = []
    for (let i = a; i < b; i++) {
      newarray.push(all[i])
    }

    this.setState({ data: newarray });
    this.setState({ dataPerPage: all });
  }

  setFirstPage = (items) => {
    let active = this.state.activepage;
    let last = active > 5 ? 5 : items.length;

    this.setState({ firstPage: 1 });
    this.setState({ lastPage: last });
    this.getPage(1);
  }

  setLastPage = (items) => {
    let joblang = this.getListLanguage(this.state.allJobs);
    let totalpage = this.getTotalLength(this.state.perpage, joblang);
    let first = 1;
    let lastPage = items.length;

    if (totalpage > 5) {
      let modulo = totalpage % 5;
      let result = totalpage - modulo;
      if (modulo === 0) {
        first = result - 4;
        lastPage = totalpage;
      }
      else {
        first = result + 1;
        lastPage = totalpage;
      }

    }
    this.setState({ firstPage: first });
    this.setState({ lastPage: lastPage });
    this.getPage(lastPage);
  }

  setPerPageAndList = (value, pagenumber) => {
    this.setState({ perpage: value });
    let all = this.getListLanguage(this.state.allJobs);
    this.setState({ numberofjob: all.length });
    this.getPage(pagenumber, value);
    let totalpage = this.getTotalLength(value, all);
    let b = totalpage >= 5 ? 5 : totalpage;
    this.setState({ firstPage: 1 });
    this.setState({ lastPage: b });

    this.setState({ selectedCategory: "" });
    this.setState({ selectedRegion: "" });
    this.setState({ search: "" });

  }

  //menu deroulant
  setPerPage = (event) => {
    const value = event.target.value;
    this.setPerPageAndList(value, 1);
  }

  render() {

    const lg = this.props.language;
    const { categoryPstMsg, data, regionsList, jobsCategoryList, myZoom, myCenter, myLat, mylng, myText, showInfo,
      prp, selectedBuilding, selectedCategory, selectedKm, zipaddress, selectedRegion, noValidate, selectedClass, classeList, buildingList,
      buildingListByFilter, activepage, perpage, perPageList, numberofjob, firstPage, lastPage } = this.state;
    this.createDynamicRef(prp.length);

    const category = this.getListLanguage(jobsCategoryList);
    const regions = this.getListLanguage(regionsList);
    const zoomLL = this.getListLanguage(ZoomLevelListName);
    const buildSelect = buildingListByFilter;
    const classNameList = this.getListLanguage(classeList);
    const msie = this.props.dataObj.msie;

    let showPagination = firstPage === 1 && lastPage === 1 ? false : true;

    let items = [];
    for (let number = firstPage; number <= lastPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === activepage} onClick={() => this.getPage(number)}>
          {number}
        </Pagination.Item>,
      );
    }

    return (
      <div>
        <Homepagebanners dataObj={this.props.dataObj} />
        <div className="container mt-4">
          <div className="row mb-1">
            <div className="col-md-6">
              <input className="mr-2" type="checkbox" id="jobsAvailable" name="jobsAvailable" onChange={this.handleCheck} checked={this.state.checkedJobsAvailable} />
              <label className="form-check-label" htmlFor="jobsAvailable">
                <FormattedMessage id="src.buildingListJobOffer" />
              </label>
            </div>
          </div>
          <div className="backgrtools">
            <div className="row mb-4">
              <div className="col-md-6">
                <FormattedMessage id="src.buildingType">
                  {plc => <Select className="m-1 text-uppercase" placeholder={plc} value={selectedClass} onChange={this.handleClasseList} options={classNameList} />}
                </FormattedMessage>
              </div>
              <div className="col-md-6">
                <FormattedMessage id="src.buildingList1">
                  {plc => <Select className="m-1 text-uppercase" placeholder={plc} value={selectedBuilding} onChange={this.handleBuilding} options={buildSelect} />}
                </FormattedMessage>
              </div>
            </div>
          </div>
          <div className="mt-2 mb-2">
            <div className="backgrtools">
              <div className="row mb-4">
                <div className="col-md-6 d-none d-md-block">
                  <FormattedMessage id="src.zipaddress">
                    {plc => <input type="text" id="zipaddress1" name="zipaddress" className="form-control m-1 text-uppercase zipaddress" placeholder={plc} value={zipaddress} onChange={this.handleZip} />}
                  </FormattedMessage>
                </div>
                <div className="col-md-6 d-md-none">
                  <FormattedMessage id="src.zipaddress">
                    {plc => <input type="text" id="zipaddress" name="zipaddress" className="form-control m-1 text-uppercase zipaddressmb" placeholder={plc} value={zipaddress} onChange={this.handleZip} />}
                  </FormattedMessage>
                </div>
                <div className="col-md-4">
                  <FormattedMessage id="src.zoom">
                    {plc => <Select className="m-1 text-uppercase" placeholder={plc} value={selectedKm} onChange={this.handleKm} options={zoomLL} />}
                  </FormattedMessage>
                </div>
                <div className="col-md-2">
                  <button type="button" className="btn btn-link text-white" onClick={() => this.filterMap()}><i className="fas fa-search fa-2x fa-rotate-90"></i></button>
                </div>
              </div>
            </div>

          </div>
          <MyMapComponent
            ref={this.myMap}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            onMarkerClick={this.handleMarkerClick}
            filterList={this.filterList}
            close={this.closeInfoBox}
            zoom={myZoom}
            center={myCenter}
            myLat={myLat}
            mylng={mylng}
            myText={myText}
            showInfo={showInfo}
            myMap={this.myMap}
            myRef={this.myRef}
            buildings={buildingList}
          />

          <button type="button" className="btn btn-sm btn-light mt-2 mr-2" onClick={() => this.initMap(false)}><FormattedMessage id="app.resetMap" /></button>
          {noValidate ?
            <button type="button" className="btn btn-sm btn-success mt-2" onClick={() => this.setSpontaneous()}><FormattedMessage id="app.spontaneous" /></button>
            :
            <Modal prov="spontaneous" language={lg} dataFrom={this.moveData} nextForm={this.setNextForm} dataObj={this.props.dataObj} />
          }
          <div id="jobsList" className="line mt-4"></div>
          <div className="col text-center mb-4 mt-4">
            <h5 className="text-uppercase"><FormattedMessage id="app.availablePositions" /></h5>
            <div><span className="text-danger">{categoryPstMsg ? <FormattedMessage id="app.noJobResult" /> : ""}</span></div>
            {/*<span className="text-success">{ categoryFindPst ?  <FormattedMessage id="app.findJob" values={{count: data.length}}/> : ""}</span></div>*/}
          </div>
          <div className="backgrtools">
            <div className="row">
              <div className="col-md-5">
                <FormattedMessage id="app.category1">
                  {plc => <Select className="m-1 text-uppercase" placeholder={plc} value={selectedCategory} onChange={this.handleCategory} options={category} isMulti />}
                </FormattedMessage>
              </div>
              {/*REACT TABLE*/}
              <div className="col-md-5">
                <FormattedMessage id="app.region">
                  {plc => <Select className="m-1 text-uppercase" placeholder={plc} value={selectedRegion} onChange={this.handleRegion} options={regions} isMulti />}
                </FormattedMessage>
              </div>
              <div className="col-md-2">
                <button type="button" className="btn btn-link text-white" onClick={() => this.filterBy()}><i className="fas fa-search fa-2x fa-rotate-90"></i></button>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-4">
            <FormattedMessage id="app.searchBy">
              {plc => <TextInput name="search" value={this.state.search} placeholder={plc} onChange={this.handleSearch} />}
            </FormattedMessage>
          </div>

          {items.length > 0 ?
            <div>
              <select className="custom-select col-md-2 mb-2 mr-2" name="perpage" value={perpage} onChange={this.setPerPage}>
                {perPageList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
              <button type="button" className="btn btn-sm btn-light mb-2" onClick={() => this.initMap(false)}><FormattedMessage id="app.resetList" /></button>
              <span className="float-right d-none d-md-block">{numberofjob} <FormattedMessage id="app.jobsavailable" /></span>
              <div className="text-center d-md-none">{numberofjob} <FormattedMessage id="app.jobsavailable" /></div>
            </div>
            :
            <div>
              <button type="button" className="btn btn-sm btn-light mb-2" onClick={() => this.initMap(false)}><FormattedMessage id="app.resetList" /></button>
              <span className="float-right mb-2 d-none d-md-block">{numberofjob} <FormattedMessage id="app.jobsavailable" /></span>
              <div className="text-center d-md-none">{numberofjob} <FormattedMessage id="app.jobsavailable" /></div>
            </div>
          }

          <div>
            {data && data.map((pst) => (
              <div className="card mb-4" key={pst.Id}>
                <div className="card-header d-flex justify-content-between">
                  <h4>{pst.Title}</h4>
                  <button type="button" className="btn btn-success d-none d-md-block" onClick={() => this.getDataSelected(pst.Id)}><FormattedMessage id="app.moreinfo" /></button>
                  <button type="button" className="btn btn-sm btn-success d-block d-md-none btnListJobMb" onClick={() => this.getDataSelected(pst.Id)}><i className="fas fa-plus"></i></button>
                </div>
                <div className="card-body">
                  <div><strong><FormattedMessage id="app.dateopen" /> : </strong>{pst.DateOpen}</div>
                  <div><strong><FormattedMessage id="app.category2" /> : </strong>{pst.Category}</div>
                  <div><strong><FormattedMessage id="pst.LocationCity" /> : </strong>{pst.LocationCity}</div>
                  <div><strong><FormattedMessage id="pst.LocationName" /> : </strong>{pst.LocationName}</div>
                  <hr />
                  <div>{pst.ShortDescription}</div>
                  <div className="text-center mt-4">
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div id="pagination" className="row">
            <div className="mx-auto">
              {showPagination &&
                <Pagination size="md">
                  <Pagination.First onClick={() => this.setFirstPage(items)} />
                  <Pagination.Prev onClick={() => this.getPrev(activepage, items)} />
                  {items}
                  <Pagination.Next onClick={() => this.getNext(activepage, items)} />
                  <Pagination.Last onClick={() => this.setLastPage(items)} />
                </Pagination>
              }
            </div>
          </div>
          <div className="row">
            <div className="mx-auto">
              {noValidate ?
                <button type="button" className="btn btn-sm btn-success mt-2" onClick={() => this.setSpontaneous()}><FormattedMessage id="app.spontaneous" /></button>
                :
                <Modal prov="spontaneous" language={lg} dataFrom={this.moveData} nextForm={this.setNextForm} dataObj={this.props.dataObj} />
              }
            </div>
          </div>
        </div>
        {!msie &&
          <div>
            <Coworker />
            <div className='text-center'>
            <button type="button" className="btn btn-sm btn-success mt-2" onClick={() => this.setSpontaneous()}><FormattedMessage id="app.spontaneous" /></button>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Search;

/**/