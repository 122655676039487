import React from 'react';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import UploadFile from '../Controls/UploadFile.js';
import JobApplyAlert from '../Controls/InfoMessage.js';
import NextPrevious from '../Controls/NextPrevious.js';
import TextInput from '../Controls/TextInput.js';
import EmailInput from '../Controls/EmailInput.js';
import TextAreaInput from '../Controls/TextArea.js';
import RadioInput from '../Controls/RadioInput.js';
import Select from 'react-select';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import {validateRequired, validateUpload, validateEmail, validatePhone } from '../Utils/Validate';
import {formatList} from '../Utils/Search';
import {formatShortSubmitData, countCharBack, getJobListByLanguage} from '../Utils/Format';

class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    let dataObj = this.props.dataObj;

    let form = dataObj.fc1//JSON.parse(sessionStorage.getItem('fc1'));
    let form1 = dataObj.fl1;//JSON.parse(sessionStorage.getItem('fl1'));

    let fl6 = dataObj.fl6;//JSON.parse(sessionStorage.getItem('fl6'));
    let f1 = dataObj.f0;//JSON.parse(sessionStorage.getItem('f0'));
    
    let q7Statut = false;
    if(fl6) {
      q7Statut = fl6.q7 === "1" || fl6.q7 === 1 ? true : false;
    }

    var jobtitlelist = [];
    var postuledJob = [];
    var rowData = [];
    var regionsData = [];

    if (f1) {
      if (f1.hasOwnProperty("jobsTitleList")) {
        jobtitlelist = f1.jobsTitleList ? f1.jobsTitleList : [];
      }
      if (f1.hasOwnProperty("postuledJob")) {
        postuledJob = f1.postuledJob ? f1.postuledJob : [];
      }
      if (f1.hasOwnProperty("rowData")) {
        rowData = f1.rowData ? f1.rowData : [];
      }
      if (f1.hasOwnProperty("regionsData")) {
        regionsData = f1.regionsData ? f1.regionsData : [];
      }
    }

    this.state = {
      fname: {value:(form1) ? (form1.fname ? form1.fname : '') : '', validate:(form1) ? (form1.fname ? true : false) : false, touch:(form1) ? (form1.fname ? true : false) : false},
      lname: {value:(form1) ? (form1.lname ? form1.lname : '') : '', validate:(form1) ? (form1.lname ? true : false) : false, touch:(form1) ? (form1.lname ? true : false) : false},
      email: {value:(form1) ? (form1.email ? form1.email : '') : '', validate:(form1) ? (form1.email ? true : false) : false, touch:(form1) ? (form1.email ? true : false) : false},
      phone1: {value:(form1) ? (form1.phone1 ? form1.phone1 : '') : '', validate:(form1) ? (form1.phone1 ? true : false) : false, touch:(form1) ? (form1.phone1 ? true : false) : false},
      phone2: {value:(form1) ? (form1.phone2 ? form1.phone2 : '') : '', validate:(form1) ? (form1.phone2 ? true : false) : false, touch:(form1) ? (form1.phone2 ? true : false) : false},
      phone3: {value:(form1) ? (form1.phone3 ? form1.phone3 : '') : '', validate:(form1) ? (form1.phone3 ? true : false) : false, touch:(form1) ? (form1.phone3 ? true : false) : false},
      /**/
      cv: (form) ? {"name" : form.CvName, "type": "", "size" : ""} : {"name" : "", "type": "", "size" : ""},
      cl: (form) ? {"name" : form.ClName, "type": "", "size" : ""} : {"name" : "", "type": "", "size" : ""},
      cvValid: false,
      clValid: true,
      cvUpload: null,
      clUpload: null,
      urlCv:'',
      urlCl:'',

      errorFromServer: false,
      errorFromServerMessage: "",
      update: dataObj.update ? true : false, //JSON.parse(sessionStorage.getItem('update')) !== null ? true : false,

      /*QUESTIONS*/

      q1: {value:(fl6) ? fl6.q1 : "0", touch:(fl6) ? true : false},
      q2: {value:(fl6) ? fl6.q2 : null, touch:(fl6) ? true : false},
      q2Details: {value:(fl6) ? fl6.q2Details : '', validate:(fl6) ? true : false, touch:(fl6) ? true : false},
      q3: {value:(fl6) ? fl6.q3 : null, touch:(fl6) ? true : false},
      q3Details: {value:(fl6) ? fl6.q3Details : '', validate:(fl6) ? true : false, touch:(fl6) ? true : false},
      q4: {value:(fl6) ? fl6.q4 : "0", touch:(fl6) ? true : false},
      q5: {value:(fl6) ? fl6.q5 : "0", touch:(fl6) ? true : false},
      q6: {value:(fl6) ? fl6.q6 : '', count: (fl6) ? fl6.count : 500},
      q7: {value: q7Statut, touch:(fl6) ? true : false},
      appliedTo: dataObj.appliedTo, //JSON.parse(sessionStorage.getItem('appliedTo')),
      submit:false,

      /** CATEGORY **/

      jobsListComplete: [],
      jobsTitleList: jobtitlelist,
      jobsCategoryList: [],

      selectedJob: postuledJob,
      touchJ: (f1) ? true : false,

      /** Job list Datatable**/

      rowData: rowData,
      touch: (f1) ? true : false,
      validate: (f1) ? true : false,

      /** REGIONS **/

      regionsList: [],

      selectedRegion: regionsData,
      touchR: (f1) ? true : false,
      alreadyapplied: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleSelectJobs = this.handleSelectJobs.bind(this);
    this.handleSelectRegions = this.handleSelectRegions.bind(this);
  }

  componentDidMount() {
        window.history.replaceState({}, document.title, "/");

        let top = document.getElementById("header").offsetTop;
        window.scrollTo(0, top);

        const url = this.props.dataObj.env.urlJson; //Default devrait être prod.
        axios.post(url, {
          request: "jobsearch"
        },{ headers: {'Content-Type': 'application/json'}})
        .then((response)  => {
          let cat = formatList(response.data.cat, "cat");
          let reg = formatList(response.data.reg, "reg");
          let job = getJobListByLanguage(response.data.job);
          this.setState({ jobsCategoryList : cat });
          this.setState({ regionsList : reg });
          this.setState({ jobsListComplete : job });
        })
        .catch((error)  => {
          this.setState({errorFromServerMessage: this.state.errorFromServerMessage.concat(error.message)});
          this.setState({errorFromServer: true});
        });


        let cvExist = this.state.cv.name !== null ? this.state.cv.name : "";
        let clExist = this.state.cl.name !== null ? this.state.cl.name : "";
        let urlCv = "";
        let urlCl = "";

        if(this.state.update && cvExist.includes(".pdf")) {
          urlCv = this.buildUrlDoc(cvExist, "cv");
          this.setState({urlCv: urlCv});
        }
        if(this.state.update && clExist.includes(".pdf")) {
          urlCl = this.buildUrlDoc(clExist, "cl");
          this.setState({urlCl: urlCl});
        }
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      const {regionsList, selectedRegion, selectedJob, jobsCategoryList} = this.state;
      this.getjobTitleList(selectedJob);
      this.setSelectedList(selectedJob, jobsCategoryList, "category");
      this.setSelectedList(selectedRegion, regionsList, "region");
      //this.setState({ selectedRegion : []});
    }
}

  setSelectedList(selected, list, type) {
    let lst = this.props.language === "fr" ? list.fr : list.en;
    let selectedValue = [];
    for (let j = 0; j < selected.length; j++) {
      selectedValue.push(lst.filter(l => l.value === selected[j].value));
    }

    let selectedValueFlat = selectedValue.flat();

    if(type === "region") {
      this.setState({ selectedRegion : selectedValueFlat });
    }
    if(type === "category") {
      this.setState({ selectedJob : selectedValueFlat });
    }

  }

/*Function related to job category*/

  handleSelectJobs(values, ev) {
    if(ev.action === "remove-value") {
      this.removeCategory(ev.removedValue.value);
    }

    if(values) {
      this.setState({ selectedJob : values});
      this.getjobTitleList(values);
    }
  }

  getjobTitleList(values) {

    let jobsList = [];
    let lg = this.props.language;

    for (let j = 0; j < values.length; j++) {
      let jobTitleList = (lg === "fr") ? this.state.jobsListComplete.fr.filter(x => x.category === values[j].value) : this.state.jobsListComplete.en.filter(x => x.category === values[j].value);
      jobsList.push(jobTitleList);
    }

    let jobListFlat = jobsList.flat();

    this.setState({ jobsTitleList : jobListFlat });
  }

  removeCategory = (cat) => {

    let a = [...this.state.rowData];

    let t = a.filter(x => x._original.category !== cat);

    this.setState({ rowData : t}, this.validateInput("jobwish", t));
  }

  getDataSelected = (rowSelected) => {

    let ind = this.state.rowData.findIndex(x => x._original.id === rowSelected._original.id);

    if (ind === -1) {
      let a = this.state.rowData.concat(rowSelected);
      this.setState({ rowData : a}, this.validateInput("jobwish", a));
    }

    else {
      let a = [...this.state.rowData];
      a.splice(ind, 1);
      this.setState({ rowData : a}, this.validateInput("jobwish", a));
    }

    this.setState({ touch : true });
  }


  handleSelectRegions(values, ev) {
    this.setState({ selectedRegion : values});
  }

  handleChange(event) {

    const value = event.target.value;
    const name = event.target.name;

    switch(name) {
      case "lname":
        this.setState(prevState => ({ lname: {...prevState.lname, value: value}}), this.validateInput(name, value));
        break;
      case "fname":
        this.setState(prevState => ({ fname: {...prevState.fname, value: value}}), this.validateInput(name, value));
        break;
      case "email":
        this.setState(prevState => ({ email: {...prevState.email, value: value}}), this.validateInput(name, value));
        break;
      case "phone1":
        this.setState(prevState => ({ phone1: {...prevState.phone1, value: value}}), this.validateInput(name, value));
        break;
      case "phone2":
        this.setState(prevState => ({ phone2: {...prevState.phone2, value: value}}), this.validateInput(name, value));
        break;
      case "phone3":
        this.setState(prevState => ({ phone3: {...prevState.phone3, value: value}}), this.validateInput(name, value));
        break;
      case "uploadcv":
        this.setState({cv: event.target.files[0]}, this.validateInput(name, event.target.files[0]));
        break;
      case "uploadcl":
        this.setState({cl: event.target.files[0]}, this.validateInput(name, event.target.files[0]));
        break;
        case "q2":
          this.setState(prevState => ({ q2: {...prevState.q2, value: value}}));
          this.setState(prevState => ({ q2: {...prevState.q2, touch: true}}));
        break;
        case "q2Details":
          this.setState(prevState => ({ q2Details: {...prevState.q2Details, value: value}}), this.validateInput(name, value));
          break;
        case "q3":
          this.setState(prevState => ({ q3: {...prevState.q3, value: value}}));
          this.setState(prevState => ({ q3: {...prevState.q3, touch: true}}));
          break;
        case "q3Details":
          this.setState(prevState => ({ q3Details: {...prevState.q3Details, value: value}}), this.validateInput(name, value));
          break;
        case "q6":
            this.setState(prevState => ({ q6: {...prevState.q6, value: value}}), this.validateInput(name, value));
          break;
        case "q7":
          let val = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
          this.setState(prevState => ({ q7: {...prevState.q7, value: val}}));
          break;
      default:

    }
  }

  validateInput = (name, value) => {

    switch(name) {
      case "fname":
        this.setState(prevState => ({ fname: {...prevState.fname, touch: true}}));
        validateRequired(value) ? this.setState(prevState => ({ fname: {...prevState.fname, validate: true}})) : this.setState(prevState => ({ fname: {...prevState.fname, validate: false}}));
        break;
      case "lname":
        this.setState(prevState => ({ lname: {...prevState.lname, touch: true}}));
        validateRequired(value) ? this.setState(prevState => ({ lname: {...prevState.lname, validate: true}})) : this.setState(prevState => ({ lname: {...prevState.lname, validate: false}}));
        break;
      case "email":
        this.setState(prevState => ({ email: {...prevState.email, touch: true}}));
        validateEmail(value) ? this.setState(prevState => ({ email: {...prevState.email, validate: true}})) : this.setState(prevState => ({ email: {...prevState.email, validate: false}}));
        break;
      case "phone1":
        this.setState(prevState => ({ phone1: {...prevState.phone1, touch: true}}));
        if(validatePhone(value, 3)) {
          this.setState(prevState => ({ phone1: {...prevState.phone1, validate: true}}));
          document.getElementById("phone2").focus();
        }
        else {
          this.setState(prevState => ({ phone1: {...prevState.phone1, validate: false}}));
        }
        //validatePhone(value, 3) ? this.setState(prevState => ({ phone1: {...prevState.phone1, validate: true}})) : this.setState(prevState => ({ phone1: {...prevState.phone1, validate: false}}));
        break;
      case "phone2":
        this.setState(prevState => ({ phone2: {...prevState.phone2, touch: true}}));
        if(validatePhone(value, 3)) {
          this.setState(prevState => ({ phone2: {...prevState.phone2, validate: true}}));
          document.getElementById("phone3").focus();
        }
        else {
          this.setState(prevState => ({ phone2: {...prevState.phone2, validate: false}}));
        }
        //validatePhone(value, 3) ? this.setState(prevState => ({ phone2: {...prevState.phone2, validate: true}})) : this.setState(prevState => ({ phone2: {...prevState.phone2, validate: false}}));
        break;
      case "phone3":
        this.setState(prevState => ({ phone3: {...prevState.phone3, touch: true}}));
        validatePhone(value, 4) ? this.setState(prevState => ({ phone3: {...prevState.phone3, validate: true}})) : this.setState(prevState => ({ phone3: {...prevState.phone3, validate: false}}));
        break;
      case "uploadcv":
        this.setState({cvUpload: true});
        validateUpload(value) ? this.setState({cvValid: true}) : this.setState({cvValid: false});
        break;
      case "uploadcl":
        this.setState({clUpload: true});
        validateUpload(value) ? this.setState({clValid: true}) : this.setState({clValid: false});
        break;
        case "jobwish":
        value.length > 0 ? this.setState({ validate : true }) : this.setState({ validate : false });
        break;
        case "q2Details":
        this.setState(prevState => ({ q2Details: {...prevState.q2Details, touch: true}}));
        validateRequired(value) ? this.setState(prevState => ({ q2Details: {...prevState.q2Details, validate: true}})) : this.setState(prevState => ({ q2Details: {...prevState.q2Details, validate: false}}));
        break;
        case "q3Details":
        this.setState(prevState => ({ q3Details: {...prevState.q3Details, touch: true}}));
        validateRequired(value) ? this.setState(prevState => ({ q3Details: {...prevState.q3Details, validate: true}})) : this.setState(prevState => ({ q3Details: {...prevState.q3Details, validate: false}}));
        break;
        case "q6":
        this.setState(prevState => ({ q6: {...prevState.q6, count: countCharBack(value.replace(/(\r\n|\n|\r)/gm," "), 500)}}));
        break;
      default:
    }
  }

getSelectedRegions = () => {
  const { regionsList, appliedTo, selectedRegion } = this.state;
  const lg = this.props.language;
  let value = "";
  const applied = lg === "fr" ? appliedTo.fr : appliedTo.en;
  const reglist = lg === "fr" ? regionsList.fr : regionsList.en;

  if(applied.Title === "spontaneous") {
    value = selectedRegion;
  }
  else {
    value = selectedRegion.length > 0 ? selectedRegion : reglist.filter(r => r.label === applied.Region);
  }

  return value;

}

setdataObj = (idata, qdata, selectedJob, selectedRegion, rowData, jobsTitleList) => {
  let dataObj = this.props.dataObj;
  dataObj.fl1 = idata;
  //sessionStorage.setItem('fl1', JSON.stringify(idata));
  dataObj.fl6 = qdata;
  //sessionStorage.setItem('fl6', JSON.stringify(qdata));
  let jobReg = {postuledJob : selectedJob, regionsData: selectedRegion, rowData: rowData, jobsTitleList: jobsTitleList};
  dataObj.f0 = jobReg;
  //sessionStorage.setItem('f0', JSON.stringify(jobReg));
  return dataObj;
}

//Passage au formulaire long avec sauvegarde en Session
validateForm = (from) => {
    const { fname, lname, email, phone1, phone2, phone3} = this.state; //cv, cl
    const { q1, q2, q2Details, q3, q3Details, q4, q5, q6, q7, selectedJob, rowData, jobsTitleList } = this.state;

    let selReg = this.getSelectedRegions();

    let idata = {"fname": fname.value,"lname" : lname.value,"email": email.value,"phone1" : phone1.value,"phone2" : phone2.value, "phone3" : phone3.value};
    let qdata = {"q1": q1.value,"q2": q2.value,"q2Details": q2Details.value,"q3": q3.value,"q3Details": q3Details.value,"q4": q4.value,
    "q5": q5.value,"q6": q6.value,"q7": q7.value ? "1" : "0", "count" : q6.count};
    let step = {"step" : "fl1", "fromUpload" : false};

    let q2DetailsOk = true;
    let q3DetailsOk = true;

    if(q2.value === "1" && !q2Details.validate) {
      q2DetailsOk = false;
    }

    if(q3.value === "1" && !q3Details.validate) {
      q3DetailsOk = false;
    }

    if(fname.validate && lname.validate && email.validate && phone1.validate && phone2.validate && phone3.validate
      && q2.value && q2DetailsOk && q3.value && q3DetailsOk && selectedJob.length > 0 && rowData.length > 0) {
        let dataObj = this.setdataObj(idata, qdata, selectedJob, selReg, rowData, jobsTitleList);
        step.dataObj = dataObj;
        this.props.nextForm(step);
    }

    if (!fname.validate){
        this.setState(prevState => ({ fname: {...prevState.fname, touch: true}}));
    }
    if (!lname.validate){
        this.setState(prevState => ({ lname: {...prevState.lname, touch: true}}));
    }
    if (!email.validate){
        this.setState(prevState => ({ email: {...prevState.email, touch: true}}));
    }
    if (!phone1.validate){
        this.setState(prevState => ({ phone1: {...prevState.phone1, touch: true}}));
    }
    if (!phone2.validate){
        this.setState(prevState => ({ phone2: {...prevState.phone2, touch: true}}));
    }
    if (!phone3.validate){
        this.setState(prevState => ({ phone3: {...prevState.phone3, touch: true}}));
    }

    /*****QUESTIONS******/

    if(q2.value) {
        if (!q2Details.validate && q2.value === "1") {
            this.setState(prevState => ({ q2Details: {...prevState.q2Details, touch: true}}));
        }
    }

    else {
          this.setState(prevState => ({ q2: {...prevState.q2, touch: true}}));
    }

    if(q3.value) {
        if (!q3Details.validate && q3.value === "1") {
            this.setState(prevState => ({ q3Details: {...prevState.q3Details, touch: true}}));
        }
    }

    else {
          this.setState(prevState => ({ q3: {...prevState.q3, touch: true}}));
    }

    if (selectedJob.length === 0){
        this.setState({touchJ:true});
    }

    if (rowData.length === 0) {
        this.setState({touch:true});
    }
  }

  handleSubmit(event) {

    //this.getSelectedRegions();

    const { fname, lname, email, phone1, phone2, phone3, cvValid, clValid, update, urlCv} = this.state; //cv, cl
    const {q2, q2Details, q3, q3Details, selectedJob, rowData } = this.state;

    let ifCvValid = cvValid;//update ? (urlCv.length > 0 ? true : false) : cvValid;
    if (update && !cvValid) {
      ifCvValid = urlCv.length > 0;
    }

    let q2DetailsOk = true;
    let q3DetailsOk = true;

    if(q2.value === "1" && !q2Details.validate) {
      q2DetailsOk = false;
    }

    if(q3.value === "1" && !q3Details.validate) {
      q3DetailsOk = false;
    }

    if(fname.validate && lname.validate && email.validate && phone1.validate && phone2.validate && phone3.validate && ifCvValid && clValid
      && q2.value && q2DetailsOk && q3.value && q3DetailsOk && selectedJob.length > 0 && rowData.length > 0) {
        this.submitForm();
    }

    if (!fname.validate){
        this.setState(prevState => ({ fname: {...prevState.fname, touch: true}}));
    }
    if (!lname.validate){
        this.setState(prevState => ({ lname: {...prevState.lname, touch: true}}));
    }
    if (!email.validate){
        this.setState(prevState => ({ email: {...prevState.email, touch: true}}));
    }
    if (!phone1.validate){
        this.setState(prevState => ({ phone1: {...prevState.phone1, touch: true}}));
    }
    if (!phone2.validate){
        this.setState(prevState => ({ phone2: {...prevState.phone2, touch: true}}));
    }
    if (!phone3.validate){
        this.setState(prevState => ({ phone3: {...prevState.phone3, touch: true}}));
    }
    if (!cvValid) {
        this.setState({cvUpload: true});
    }
    if (!clValid) {
        this.setState({clUpload: true});
    }

    /*****QUESTIONS******/

    if(q2.value) {
        if (!q2Details.validate && q2.value === "1") {
            this.setState(prevState => ({ q2Details: {...prevState.q2Details, touch: true}}));
        }
    }

    else {
          this.setState(prevState => ({ q2: {...prevState.q2, touch: true}}));
    }

    if(q3.value) {
        if (!q3Details.validate && q3.value === "1") {
            this.setState(prevState => ({ q3Details: {...prevState.q3Details, touch: true}}));
        }
    }

    else {
          this.setState(prevState => ({ q3: {...prevState.q3, touch: true}}));
    }

    if (selectedJob.length === 0){
        this.setState({touchJ:true});
    }

    if (rowData.length === 0) {
        this.setState({touch:true});
    }

    event.preventDefault();
  }

  submitForm = () => {

    this.setState({submit:true});
    let dataObj = this.props.dataObj;
    const lg = this.props.language;
    const {fname, lname, email, phone1, phone2, phone3, cv, cl, cvValid, clValid, clUpload, update, appliedTo } = this.state;
    const { q1, q2, q2Details, q3, q3Details, q4, q5, q6, q7, rowData } = this.state;
    let emailref = dataObj.ref;//JSON.parse(sessionStorage.getItem('ref'));
    let selReg = this.getSelectedRegions();

    const applied = lg === "fr" ? appliedTo.fr : appliedTo.en;

    let idata = {'fname': fname.value, 'lname': lname.value, 'email': email.value, 'phone': phone1.value+phone2.value+phone3.value, 'cv': cv, 'cl': cl};

    let qdata = {"q1": q1.value,"q2": q2.value,"q2Details": q2Details.value,"q3": q3.value,"q3Details": q3Details.value,"q4": q4.value,
    "q5": q5.value,"q6": q6.value,"q7": q7.value ? "1" : "0", "count" : q6.count};

    let objData = {postuledJob : rowData, regionsData: selReg, Questions: qdata, Identifications: idata, appliedTo: applied};

    let data = formatShortSubmitData(objData, dataObj, lg);
    let object = {};
    data.forEach((value, key) => {object[key] = value});

    if(update) {
      data.append('Update', "1");
      data.append('EmailRef', emailref);
    }

    if (update && cvValid) {
      data.append('NewCV', "1");
    }

    if (update && clValid && clUpload) {
      data.append('NewCL', "1");
    }

    const url = this.props.dataObj.env ? this.props.dataObj.env.urlFormData : "https://jobs.cogir.net/ajax/processApplicant.php"; //Default devrait être prod

    axios({
      method: 'post',
      url: url,
      data: data,
      responseType:'text',
      config: { headers: {'Content-Type': 'multipart/form-data' }}
      })
      .then(res => {
        let fc1 = res.data;
        if (typeof fc1 === "string") {
          let ind = fc1.indexOf("-&&-");
          if (ind !== -1) {
            //let step = {"step" : "conf"};
            //this.props.nextForm(step);
            this.goToNextForm("conf");
          }
          else {
            this.setState({errorFromServerMessage: fc1});
            this.setState({errorFromServer: true});
          }
        }
        else if (fc1.hasOwnProperty("alreadyapplied")) {
          this.setState({alreadyapplied: fc1.alreadyapplied});
        }
        else {
          this.setState({errorFromServerMessage: fc1});
          this.setState({errorFromServer: true});
        }
      })
      .catch(error => {
        this.setState({errorFromServerMessage: error.message});
        this.setState({errorFromServer: true});
      })
  }

  goToLongForm = () => {
    this.validateForm("submit");
  }

  goToNextForm = (value) => {
    let dataObj = this.props.dataObj;
    if(value === "conf") {
      dataObj.reset = true;
      dataObj.update = true;
    }
    let step = {"step" : value};
    step.dataObj = dataObj;
    if(value === "fc1") {
      step = {"step" : value, "fromUpload" : true};
    }
    this.props.nextForm(step);
  }

  buildUrlDoc = (doc,type) => {
    let href = window.location.origin;
    let path = "";

    if (this.props.dataObj.env.myenv === "dev") {
      path = "Files";
      return href.concat("/", path, "/", doc);
    }

    else {
      if (type === "cv") {
        path = "CogCiGr332_2C/CV";
      }
      if (type === "cl") {
        path = "CogCiGr332_2C/CL";
      }
      return href.concat("/", path, "/", doc);
    }
  }

  setPageSize = (lst) => {
    let size = 5;

    if (lst.length > 5 ) {size = lst.length}

    return size;
  }

  removeDoc = (doc) => {
    //const {update, urlCl, clValid, clUpload} = this.state;
  }

  render() {

    const lg = this.props.language;

    const { q2, q3, q6, jobsCategoryList, selectedJob, regionsList, selectedRegion, errorFromServer, errorFromServerMessage, update,
      urlCv, urlCl, cv, cl, appliedTo, jobsTitleList, alreadyapplied } = this.state;
    const reglist = lg === "fr" ? regionsList.fr : regionsList.en;
    const joblist = lg === "fr" ? jobsCategoryList.fr : jobsCategoryList.en;
    const applied = lg === "fr" ? appliedTo.fr : appliedTo.en;

    let cvExist = "";
    let clExist = "";

    if (cv !== undefined) {
      cvExist = (cv !== null) ? cv.name : "";
    }

    if (cl !== undefined) {
      clExist = (cl !== null) ? cl.name : "";
    }

    const q2DetailsComp =
    <div className="row">
      <div className="col-sm-6">
      <FormattedMessage id="progress.detailsQ2">
      { plc=> <TextInput name="q2Details" value={this.state.q2Details.value} onChange={this.handleChange} placeholder={plc}/> }
      </FormattedMessage>
     <p><span className="text-danger">{(!this.state.q2Details.value && this.state.q2Details.touch) ? <FormattedMessage id="error.required"/> : ""}</span></p>
    </div>
  </div>;

    const q3DetailsComp =
    <div className="row">
      <div className="col-sm-6">
      <FormattedMessage id="progress.detailsQ3">
      { plc=>   <TextInput name="q3Details" value={this.state.q3Details.value} onChange={this.handleChange} placeholder={plc} /> }
      </FormattedMessage>
    <p><span className="text-danger">{(!this.state.q3Details.value && this.state.q3Details.touch) ? <FormattedMessage id="error.required"/> : ""}</span></p>
    </div>
  </div>;

    const pageSize = this.setPageSize(jobsTitleList);

    const columns = [{
      Header: <div className="text-left"><div><FormattedMessage id="app.jobsList"/></div><small className="text-muted"><FormattedMessage id="app.chooseInList"/></small></div>,
      accessor: 'job',
      filterable:true,
      minWidth: 200,
      filterMethod: (filter, rows, column) => {
        return rows.job.toLowerCase().includes(filter.value.toLowerCase(), 0);
      }
    }]

    return (
      <div>
          <div className="container mb-4">
          <JobApplyAlert gotToPrev={() => this.goToNextForm("Search")} jobApply={applied.Title} type={appliedTo.type}/>
          <form ref={this.form} onSubmit={this.handleSubmit} noValidate>
          <div className="row">
              <div className="col-sm-8">
                <h4><FormattedMessage id="app.contact"/></h4>
                <hr />
              </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2 my-auto">
            <FormattedMessage id="app.fname" /><span className="text-danger ml-2">*</span>
            </div>
            <div className="col-sm-6 my-auto">
            <TextInput name="fname" value={this.state.fname.value} onChange={this.handleChange}/>
            <span className="text-danger">{(this.state.fname.touch && !this.state.fname.validate) ? <FormattedMessage id="error.required"/> : ""}</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2 my-auto">
            <FormattedMessage id="app.lname" /><span className="text-danger ml-2">*</span>
            </div>
            <div className="col-sm-6 my-auto">
            <TextInput name="lname" value={this.state.lname.value} onChange={this.handleChange} />
            <span className="text-danger">{(this.state.lname.touch && !this.state.lname.validate) ? <FormattedMessage id="error.required"/> : ""}</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-2 my-auto">
            <FormattedMessage id="app.email" /><span className="text-danger ml-2">*</span>
            </div>
            <div className="col-sm-6 my-auto">
            { update ?
              <EmailInput name="email" value={this.state.email.value} onChange={this.handleChange} />
              :
              <EmailInput name="email" value={this.state.email.value} onChange={this.handleChange} disabled />
            }
            <span className="text-danger">{(this.state.email.touch && !this.state.email.validate) ? <FormattedMessage id="error.email"/> : ""}</span>
            </div>
          </div>
          {/* Phone */}
          <div className="row mb-3">
          <div className="col-sm-2">
          <FormattedMessage id="app.phone"/><span className="text-danger ml-2">*</span>
          </div>
          <div className="col-4 col-sm-2">
            <TextInput id="phone1" name="phone1" value={this.state.phone1.value} onChange={this.handleChange} maxLength="3"/>
            <p><span className="text-danger">{(this.state.phone1.touch && !this.state.phone1.validate) ? <FormattedMessage id="error.phone1"/> : ""}</span></p>
            </div>
            <div className="col-4 col-sm-2">
            <TextInput id="phone2" name="phone2" value={this.state.phone2.value} onChange={this.handleChange} maxLength="3"/>
            <p><span className="text-danger">{(this.state.phone2.touch && !this.state.phone2.validate) ? <FormattedMessage id="error.phone1"/> : ""}</span></p>
            </div>
            <div className="col-4 col-sm-2">
            <TextInput id="phone3" name="phone3" value={this.state.phone3.value} onChange={this.handleChange} maxLength="4"/>
            <p><span className="text-danger">{(this.state.phone3.touch && !this.state.phone3.validate) ? <FormattedMessage id="error.phone2"/> : ""}</span></p>
            </div>
          </div>
          {update && urlCv.length > 0 &&
            <div className="row mb-3">
              <div className="col-sm-2 my-auto">
                <FormattedMessage id="app.cvbackend"/>
              </div>
              <div className="col-sm-4 my-auto">
              <a href={urlCv} className="pdfStyle" target="blank"><i className="far fa-file-pdf fa-2x"></i></a>
              </div>
            </div>
          }
          {update && urlCl.length > 0 &&
            <div className="row mb-3">
              <div className="col-sm-2 my-auto">
                <FormattedMessage id="app.clbackend"/>
              </div>
              <div className="col-sm-4 my-auto">
              <a href={urlCl} className="pdfStyle" target="blank"><i className="far fa-file-pdf fa-2x"></i></a>
              </div>
            </div>
          }
            <div className="row mb-3">
              <div className="col-sm-2 my-auto">
              <FormattedMessage id="app.uploadcv"/><span className="text-danger ml-2"></span>
              </div>
              <div className="col-sm-6 my-auto">
              <UploadFile name="uploadcv" method={this.handleChange} placeholder={this.state.cvUpload && this.state.cvValid ? cvExist : <FormattedMessage id="app.choosefile"/>}
              accept="application/pdf,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*" capture/>
              {update && urlCv.length > 0 && <small className="text-muted"><FormattedMessage id="app.cvmessageupdate"/></small> }
              <span className={this.state.cv && this.state.cvValid ? "" : "text-danger"}>{this.state.cvUpload && !this.state.cvValid ? <FormattedMessage id="error.pdf"/> : ""}</span>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2 my-auto">
                <FormattedMessage id="app.uploadcl"/>
              </div>
              <div className="col-sm-6 my-auto">
              <UploadFile name="uploadcl" method={this.handleChange} placeholder={this.state.clUpload && this.state.clValid ? clExist : <FormattedMessage id="app.choosefile"/>}
              accept="application/pdf,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*" capture/>
              {update && urlCl.length > 0 && <small className="text-muted"><FormattedMessage id="app.clmessageupdate"/></small> }
              <span className={this.state.cl && this.state.clValid ? "" : "text-danger"}>{this.state.clUpload && !this.state.clValid ? <FormattedMessage id="error.pdf"/> : ""}</span>
              </div>
            </div>

          {/*****JOBS-REGIONS-QUESTIONS********/}

          <div className="row mt-2">
            <div className="col-sm-8">
            <hr />
            <h4><FormattedMessage id="app.preferences"/></h4>
            <small className="text-muted"><FormattedMessage id="app.subpreferences"/></small>
            <hr/>
            </div>
          </div>
              <div className="row">
                  <div className="col-sm-8">
                  <FormattedMessage id="app.category">
                  { plc=> <Select className="" placeholder={plc} value={selectedJob} onChange={this.handleSelectJobs} options={joblist} isMulti/> }
                  </FormattedMessage>
                  <span className="text-danger">{this.state.touchJ && this.state.selectedJob.length === 0 ? <FormattedMessage id="error.required"/> : ""}</span>
                  </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-8">
                <ReactTable
                getTdProps={(state, rowInfo, column, instance) => {
                  if (rowInfo && rowInfo.row) {
                            return {
                              onClick: (e, handleOriginal) => {

                                this.getDataSelected(rowInfo.row);

                                if (handleOriginal) {
                                  handleOriginal();
                                }
                              },
                              style: {
                                background: (this.state.rowData.findIndex(x => x._original.id === rowInfo.row._original.id) !== -1) ? "#A9A9A9" : "",
                                color: (this.state.rowData.findIndex(x => x._original.id === rowInfo.row._original.id) !== -1) ? "white" : "black",
                                cursor: "pointer",
                                textOverflow: "clip",
                                overflow: "auto",
                                //border: rowInfo.row._original.idTitle === -1 ? "2px solid blue" : ""
                              }
                            };
                          }
                         else {
                          return {}
                        }
                      }
                    }
                  getPaginationProps={(state, rowInfo, column, instance) => {
                      return {
                        style: {
                          color: "black"
                        }
                      }
                    }
                  }
                  data={jobsTitleList}
                  columns={columns}
                  pageSize={pageSize}
                  defaultPageSize={5}
                  previousText= {lg === "fr" ? "Précédent" : "Previous"}
                  nextText= {lg === "fr" ? "Suivant" : "Next"}
                  loadingText= {lg === "fr" ? "Chargement..." : "Loading..."}
                  noDataText= {lg === "fr" ? "" : ""}
                  pageText= {"Page"}
                  ofText= {lg === "fr" ? "de" : "of"}
                  rowsText= {lg === "fr" ? "rangées" : "rows"}
                />
                <p><span className={(!this.state.validate && this.state.touch) ? "text-danger" : ""}>{(!this.state.validate && this.state.touch) ? <FormattedMessage id="error.jobwish"/> : ""}</span></p>
                  <hr/>
                </div>
            </div>
              <div className="row">
                <div className="col-sm-8">
                  <FormattedMessage id="app.RegionsSelect">
                  { plc=> <Select className="mt-4" placeholder={plc} value={selectedRegion} onChange={this.handleSelectRegions} options={reglist} isMulti/> }
                  </FormattedMessage>
                </div>
            </div>

                <div>
                <div className="row">
                    <div className="col-sm-8">
                      <hr/>
                      <h4><FormattedMessage id="progress.questions"/></h4>
                      <hr />
                    </div>
                </div>

              <div className="row">
                <div className="col-sm-6 ">
                <FormattedMessage id="app.q2"/><span className="text-danger ml-2">*</span>
                </div>
                <div className="col-sm-2">
                  <div className="input-group">
                    <label className="pr-2"><RadioInput value="1" name="q2" checked={this.state.q2.value === "1"} onChange={this.handleChange}/><FormattedMessage id="app.yes"/></label>
                    <label className="pr-2"><RadioInput value="0" name="q2" checked={this.state.q2.value === "0"} onChange={this.handleChange}/><FormattedMessage id="app.no"/></label>
                  </div>
                  <p><span className="text-danger">{(!this.state.q2.value && this.state.q2.touch) ? <FormattedMessage id="error.required"/> : ""}</span></p>
              </div>
            </div>

            {q2.value === "1" &&
              q2DetailsComp
            }

            <div className="row">
              <div className="col-sm-6 ">
              <FormattedMessage id="app.q3"/><span className="text-danger ml-2">*</span>
              </div>
              <div className="col-sm-2">
                <div className="input-group">
                 <label className="pr-2"><RadioInput value="1" name="q3" checked={this.state.q3.value === "1"} onChange={this.handleChange}/><FormattedMessage id="app.yes"/></label>
                 <label className="pr-2"><RadioInput value="0" name="q3" checked={this.state.q3.value === "0"} onChange={this.handleChange}/><FormattedMessage id="app.no"/></label>
               </div>
               <p><span className="text-danger">{(!this.state.q3.value && this.state.q3.touch) ? <FormattedMessage id="error.required"/> : ""}</span></p>
            </div>
          </div>

          {q3.value === "1" &&
            q3DetailsComp
          }
        <div className="row mt-2">
          <div className="col-sm-6">
          <p><FormattedMessage id="app.q6"/></p>
          <TextAreaInput name="q6" value={q6.value} onChange={this.handleChange} />
          <small>{q6.count} <FormattedMessage id="app.characterCount"/></small>
        </div>
      </div>
  </div>

      <div className="form-check mb-2 mt-4">
      <div className="col-sm-6">
        <input className="form-check-input" type="checkbox" id="q7" name="q7" onChange={this.handleChange} checked={this.state.q7.value}/>
        <label className="form-check-label" htmlFor="q7">
          <FormattedMessage id="app.q7"/>
        </label>
        </div>
      </div>
          <div className="row">
              <div className="col-sm-8">
              <hr />
              {errorFromServer &&
              <div className="alert alert-danger" role="alert">
                <p><FormattedMessage id="error.generic"/></p>
                {errorFromServerMessage === "A1" ? <FormattedMessage id="error.emailAlreadyExist"/> : errorFromServerMessage}
              </div>
              }

              { alreadyapplied &&
                <div className="alert alert-danger" role="alert">
                  <div><FormattedMessage id="error.alreadyapplied" values={{date: moment(alreadyapplied).format('DD-MM-YYYY')}}/></div>
                </div>
              }
              </div>
          </div>

          <div className="alert alert-success col-sm-8" role="alert">
              <div className="row ml-2">
                <strong><FormattedMessage id="app.noCvMessage"/></strong>
                <FormattedMessage id="app.chooseForm1"/>
              </div>
              <div className="row ml-2">
                <button type="button" className="btn btn-outline-secondary mt-2" onClick={() => this.goToLongForm()}><FormattedMessage id="app.detailedApplication"/></button>
              </div>
          </div>

          <NextPrevious gotToPrev={() => this.goToNextForm("Search")} goToShort={() => this.goToNextForm("fc1")} long="1" apply="0" submit={this.state.submit}/>
          </form>
        </div>

      </div>
    );
  }
}

export default UploadForm;

/*<button type="button" className="btn btn-link mb-3" onClick={() => this.removeDoc("cl")}><i className="fas fa-trash-alt fa-2x"></i></button>*/
