import moment from 'moment';

export function validateRequired(value) {
  return (value.length > 0) ? true : false;
}

export function validateEmail(value) {
  let regex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
  return regex.test(value);
}

export function validateUpload(value) {
  if(value) {
    return (value.size < 4000000 && (value.type === "application/pdf" || value.type === "application/msword"
    || value.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || value.type === "image/jpeg" || value.type === "image/png"
    || value.type === "image/gif" || value.type === "image/tiff"))  ? true : false;
  }
  else {
    return false;
  }
}

export function validatePhone(value, length) {
  let regex = RegExp(/^\d+$/);
  return (regex.test(value) && value.length === length) ? true : false;
}

export function validatePostalCode(value) {
  //canadian and us RegExp(/^\d{5}-\d{4}|\d{5}|[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/)
  let regex = RegExp(/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/); //Canadian
  return regex.test(value);
  //return true;
}

export function validateValueInArrayOfObject(array, value) {
  let res = false;
  for (var i=0; i < array.length; i++) {
        if (array[i].value === value) {
            res = true;
        }
    }
  return res;
}

export function validateTwoDates(fdate, sdate) {
  let res = moment(fdate).isSameOrBefore(sdate);
  return res
}

export function validateDate(date) {
  return moment.isDate(date);
}

export function countCharBack(value, max) {
  var parsed = parseInt(value.length);
  return max - parsed;
}
