import React, { Component } from 'react';
import ErrorBoundary from './ErrorBoundary';
import Header from './Forms/Header';
import ProgressBar from './Forms/ProgressBar';
import Search from './Forms/SearchMapProps';
import JobDescription from './Forms/JobDescription';
import EmailLooking from './Forms/EmailLooking';
import UploadForm from './Forms/Upload';
import Contact from './Forms/Contact';
import Education from './Forms/Education';
import JobsHistory from './Forms/JobsHistory';
import Confirmation from './Forms/Confirmation';
import Footer from './Forms/Footer';
import WorkerBanner from './Forms/WorkerBanner';
import Identity from './Forms/Identity';
import MessageCenter from './Forms/MessageCenter';
import Landing from './Forms/Landing';
import MetaTags from 'react-meta-tags';
//import MapTest from './Forms/MapTest';
import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

class Forms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: this.setFirstStep(),
      language: this.props.language,
      fromUpload: false,
      sessionStorageValues: [],
      dataToTr: this.setDataToTr(),
      dataObj: this.setDataObj(),
      profilUpdate: false
    };
  }

  checkStorageValue = (dataObj) => {

    if(dataObj) {
      let myClassf0 = dataObj.hasOwnProperty("f0");
      let myClassf1 = dataObj.hasOwnProperty("f1");
      let myClassfc1 = dataObj.hasOwnProperty("fc1");
      let myClassfl1 = dataObj.hasOwnProperty("fl1");
      let myClassfl2 = dataObj.hasOwnProperty("fl2");
      let myClassfl3 = dataObj.hasOwnProperty("fl3");
      let myClassfl4 = dataObj.hasOwnProperty("fl4");
      let myClassfl5 = dataObj.hasOwnProperty("fl5");
      let myClassfl6 = dataObj.hasOwnProperty("fl6");

      return [myClassf0, myClassf1, myClassfc1, myClassfl1, myClassfl2, myClassfl3, myClassfl4, myClassfl5, myClassfl6];
    }
  }

  setFirstStep () {
    let firstStep = "Search";

    if (this.props.firstStep) {
      firstStep = this.props.firstStep.step;
    }

    return firstStep;
  }

  setDataObj () {
    let dataObj = {};

    if (this.props.firstStep) {
      dataObj = this.props.firstStep.dataObj;
    }

    return dataObj;
  }

  setDataToTr () {
    let data = {};

    if (this.props.firstStep) {
      data = this.props.firstStep.datatotr;
    }

    return data;
  }

  getLanguage = (language) => {
    this.props.setLanguage(language); //monte
    this.setState({language: language}); //descend
  }

  setNextForm = (form) => {

    this.setState({step: form.step});
    this.setState({dataObj: form.dataObj});

    if(form.fromUpload !== undefined) {
      this.setState({fromUpload: form.fromUpload});
      //sessionStorage.setItem('fromUpload', JSON.stringify(form.fromUpload));
    }

    let a = [...this.state.sessionStorageValues];
    a = this.checkStorageValue(form.dataObj);
    this.setState({sessionStorageValues: a});
  }

  moveData = (data) => {
    this.setState({dataToTr: data});
  }

  setProfilUpdate = () => {
    this.setState({profilUpdate: true});
  }

  render() {
    const {language, step, sessionStorageValues, dataToTr, profilUpdate, dataObj} = this.state;
    let element;
    let showProgressBar;
    const bodyEmailText = this.props.language === 'fr' ? "Donnez un sens à votre vie professionnelle" : "Give meaning to your career";
    const title = this.props.language === 'fr' ? "COGIR | Gestion de propriétés immobilières commerciales, industrielles et bureaux " : "COGIR | Management of real estate properties in commercial & industrial sectors ";
    const url = window.location.hostname === "emplois.cogir.net" ? "https://emplois.cogir.net" : "https://jobs.cogir.net";
    const imgUrl = window.location.hostname === "emplois.cogir.net" ? "https://emplois.cogir.net/ATS-V2-banniere.jpg" : "https://jobs.cogir.net/ATS-V2-banniere.jpg";

    const MyMetaTags = () => (
      <div className="wrapper">
          <MetaTags>
          <meta property="og:url" content={url} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={imgUrl} />
          <meta property="og:description" content={bodyEmailText} />
          <meta property="og:title" content={title} />
          </MetaTags>
        </div>
    )

    switch(step) {
      case "identity":
        element = <Identity />;
      break;
      case "Search":
        element = <Search nextForm={this.setNextForm} language={language} dataFrom={this.moveData} dataObj={dataObj}/>;
      break;
      case "JobDesc":
        element = <JobDescription nextForm={this.setNextForm} language={language} dataFrom={this.moveData} data={dataToTr} dataObj={dataObj}/>;
      break;
      case "EmailLooking":
        element = <EmailLooking nextForm={this.setNextForm} language={language} data={dataToTr} setProfilUpdate={this.setProfilUpdate} dataObj={dataObj}/>;
      break;
      case "fc1":
        element = <UploadForm nextForm={this.setNextForm} dataFrom={this.moveData} language={language} dataObj={dataObj}/>;
      break;
      case "fl1":
        element = <Contact nextForm={this.setNextForm} getData={this.state.dataToTr} language={language} dataObj={dataObj}/>;
        showProgressBar = <ProgressBar step={step} nextForm={this.setNextForm} values={sessionStorageValues} dataObj={dataObj}/>;
      break;
      case "fl3":
        element = <Education nextForm={this.setNextForm} language={language} dataObj={dataObj}/>;
        showProgressBar = <ProgressBar step={step} nextForm={this.setNextForm} values={sessionStorageValues} dataObj={dataObj}/>;
      break;
      case "fl5":
        element = <JobsHistory nextForm={this.setNextForm} language={language} dataObj={dataObj}/>;
        showProgressBar = <ProgressBar step={step} nextForm={this.setNextForm} values={sessionStorageValues} dataObj={dataObj}/>;
      break;
      case "mess":
        element = <MessageCenter nextForm={this.setNextForm} data={dataToTr} dataObj={dataObj} />
      break;
      case "conf":
        element = <Confirmation nextForm={this.setNextForm} dataObj={dataObj} language={language} />
      break;
      case "landing":
        element = <Landing nextForm={this.setNextForm} language={language} dataFrom={this.moveData} dataObj={dataObj}/>;
      break;
      default:
        element = <Search nextForm={this.setNextForm} language={language} dataFrom={this.moveData} dataObj={dataObj} />;
        showProgressBar = "";
    }

    return (
      <div>
        <ErrorBoundary setLanguage={this.getLanguage} nextForm={this.setNextForm} language={language} >
          <MyMetaTags />
          <Header setLanguage={this.getLanguage} nextForm={this.setNextForm} language={language} dataFrom={this.moveData} profilUpdate={profilUpdate} dataObj={dataObj} />
          <div>
          {showProgressBar}
          {step === "Search" ? "" : <WorkerBanner /> }
          {element}
          </div>
          <Footer nextForm={this.setNextForm} language={language} dataFrom={this.moveData} profilUpdate={profilUpdate} dataObj={dataObj} />
        </ErrorBoundary>
      </div>
    );
  }
}

export default Forms;
