import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';
import Modal from '../Controls/Modal.js';
import {setJobApply} from '../Utils/Search';
import logoFr from '../Images/cogir-rev-fr-110x110.png';
import logoEn from '../Images/cogir-rev-en-110x110.png';

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.language
    };
  }

  setLg = (value) => {
    this.setState({value: value});
    this.props.setLanguage(value);
  }

  goToNextForm = () => {
    let dataObj = this.props.dataObj;
    let step = {"step" : "Search"};
    step.dataObj = dataObj;
    this.props.nextForm(step);
    //window.location.reload();
  }

  setSpontaneous = () => {
    //let noValidate = JSON.parse(sessionStorage.getItem('noValidate'));
    let nojob = {fr:[{Title:"Mise à jour du profil"}], en:[{Title:"Profil update"}], type:"profil"};
    let dataObj = this.props.dataObj;

    if (dataObj.reset) {
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.replace("/");
    }

    else {
      let objData = setJobApply(nojob);
      dataObj.appliedTo = objData;

      let step = {"step" : "fc1"};
      step.dataObj = dataObj;

      this.props.nextForm(step);
    }
  }

  setNextForm = (step) => {
    let dataObj = this.props.dataObj;
    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  moveData = (data) => {
    this.props.dataFrom(data);
  }

  render() {
    const lg = this.props.language;
    const dataObj = this.props.dataObj;
    const noValidateData = dataObj.update;

    return (
      <div id="header">
      <nav className="navbar navbar-light bg-dark d-none d-md-block">
        <div className="container">
          <button type="button" className="btn btn-link" onClick={() => this.goToNextForm()}>
            <img src={this.state.value === "fr" ? logoFr : logoEn} alt="Logo" />
          </button>
          <div>
            <div className="btn-group" role="group" aria-label="language buttons">
              <button type="button" className={this.state.value === "en" ? "btn btn-light" : "btn btn-outline-light"} name="en" onClick={() => this.setLg("en")}>English</button>
              <button type="button" className={this.state.value === "fr" ? "btn btn-light" : "btn btn-outline-light"} name="fr" onClick={() => this.setLg("fr")}>Français</button>
            </div>
            <div>
            {noValidateData ?
              <button type="button" className="btn btn-link text-success pl-0 pt-2" onClick={() => this.setSpontaneous()}><FormattedMessage id="app.profil"/></button>
              :
              <Modal prov="profil" language={lg} dataFrom={this.moveData} nextForm={this.setNextForm} dataObj={this.props.dataObj} />
            }
            </div>
          </div>
        </div>
        </nav>

        <nav className="navbar navbar-light bg-dark d-block d-md-none">
          <div className="container">
            <button type="button" className="btn btn-link" onClick={() => this.goToNextForm()}>
              <img src={this.state.value === "fr" ? logoFr : logoEn} alt="Logo"/>
            </button>
            <div>
            <div className="btn-group btn-group-sm mt-2" role="group" aria-label="language buttons">
              <button type="button" className={this.state.value === "en" ? "btn btn-light" : "btn btn-outline-light"} name="en" onClick={() => this.setLg("en")}>English</button>
              <button type="button" className={this.state.value === "fr" ? "btn btn-light" : "btn btn-outline-light"} name="fr" onClick={() => this.setLg("fr")}>Français</button>
            </div>
            <div>
            {noValidateData ?
              <button type="button" className="btn btn-link text-success pl-0 pt-2" onClick={() => this.setSpontaneous()}><FormattedMessage id="app.profil"/></button>
              :
              <Modal prov="profil" language={lg} dataFrom={this.moveData} nextForm={this.setNextForm} dataObj={this.props.dataObj} />
            }
            </div>
          </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default header;
