import React, { Component } from 'react';
import Forms from './Forms';
import axios from 'axios';
import queryString from 'query-string';
import {mapData, getCookie} from './Utils/Update';
import {formatVolet, formatSearchTable, formatList, setJobApply } from './Utils/Search';
import {setJobFair } from './Utils/Format';
//React internationalization
import {IntlProvider} from "react-intl";
import {addLocaleData} from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_fr from 'react-intl/locale-data/fr';
import messages_fr from "./translations/fr.json";
import messages_en from "./translations/en.json";
import Origines from './translations/origines.json';
addLocaleData([...locale_en, ...locale_fr]);

const messages = {
    'fr': messages_fr,
    'en': messages_en
};

const navLg =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

class App extends Component {
  constructor(props) {
    super(props);

    let lg = navLg.toLowerCase().includes("fr") ? "fr" : "en";

    this.state = {
      errorFromServer: false,
      lg: lg,
      firstStep: {},
      ajaxCall: false,
      goto: "Search",
      resid: null,
      ori: null,
      env: {
        "staging" : {urlJson:"https://staging.cogir.net:8151", urlFormData:"https://staging.cogir.net:8152", myenv:"dev"},
        "home" : {urlJson:"http://127.0.0.1:8293", urlFormData:"http://127.0.0.1:8096", myenv:"dev"},
        "prod" : this.getProdUrl()
      }
    };

    this.setDocumentTitle(lg);
  }

  componentDidMount() {
    this.msieversion();
    window.onload = (event) => {
      const values = queryString.parse(window.location.search);
      let cookie = getCookie();
      let request = {};
      request = this.validateQueryStringValues(values);
      if (request.pass) {
        this.ajaxCall(request);
      }
      else {
        this.setDataObj(request, cookie);
      }
    };
  }

  getProdUrl = () => {
    let domain = window.location.hostname;

    let urlJson = "https://jobs.cogir.net/ajax/ConnectDb.php";
    let urlForm = "https://jobs.cogir.net/ajax/processApplicant.php";

    if (domain === "emplois.cogir.net") {
      urlJson = "https://emplois.cogir.net/ajax/ConnectDb.php";
      urlForm = "https://emplois.cogir.net/ajax/processApplicant.php";
    }
    return {urlJson:urlJson, urlFormData:urlForm, myenv:"prod"}
  }

  msieversion = () => {
    var res = false;

    if (/*@cc_on!@*/false || !!document.documentMode)
    {
      let response = {};
      response.unsubsc = "ie";
      this.setState({goto:"mess"});
      this.setDataObj(response, null);
      res = true;
    }

    return res;
}

  validateQueryStringValues = (values) => {
    let reqObj = {pass: false};

    if(values.oc && values.email) {
      reqObj.request = "validateOc";
      reqObj.oc = values.oc;
      reqObj.email = values.email;
      reqObj.pass = true;
      //this.setState({ori:values.ori});
      this.setState({goto:"fc1"});
    }

    if(values.job && values.ori) {
      let oriname = Origines[values.ori] ? Origines[values.ori] : null;
      let orivalue = oriname ? values.ori : 2
      reqObj.request = "jobdescription";
      reqObj.jobid = values.job;
      reqObj.pass = true;
      this.setState({ori: orivalue});
      this.setState({goto:"JobDesc"});
    }

    if(values.resid) {
      reqObj.resid = values.resid;
      reqObj.goto = values.resid === "all" ? "Search" : "landing";
      //reqObj.goto = "Search";
    }

    if(values.unsubsc) {
      reqObj.request = "unsubscribe";
      reqObj.token = values.unsubsc;
      reqObj.unsubsc = "unsubsc";
      reqObj.pass = true;
      this.setState({goto:"mess"});
    }

    if(values.requestcode) {
      if(values.requestcode === "profil" || values.requestcode === "spontaneous") {
        reqObj.profil = values.requestcode;
        this.setState({goto:"EmailLooking"});
      }
      else {
        reqObj.request = "jobdescription";
        reqObj.jobid = values.requestcode;
        reqObj.pass = true;
        this.setState({ori:"1"});
        this.setState({goto:"EmailLooking"});
      }
    }

    return reqObj;
  }

  ajaxCall = (request, finalObj) => {
    const url = this.setEnvVariables();//process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? "https://jobs.cogir.net/ajax/ConnectDb.php" : "http://10.10.10.60:8080";
    const urljson = url.urlJson;
    axios.post(urljson, request, { headers: {'Content-Type': 'application/json'}})
      .then((response)  => {
        if(response.data.candidat) {
          let dataObj = mapData(response.data.candidat, this.state.lg, {});
          let jobFair = setJobFair();
          dataObj.jobFairID = jobFair.jobFairIDV;
          dataObj.env = url;
          dataObj.appliedTo = this.setProfil("profil");
          let obj = {}
          obj.step = this.state.goto;
          obj.dataObj = dataObj;
          this.setReadyState(obj);
        }
        else {
          this.setDataObj(response.data, null);
        }
      })
      .catch((error)  => {
        this.setState({errorFromServer: true});
      });
  }

  setEnvVariables = () => {
    return this.state.env[process.env.REACT_APP_ENV];
  }

  getJobDesc = (response) => {
    let cat = formatList(response.cat, "cat");
    let reg = formatList(response.reg, "reg");
    let vol = formatVolet(response.vol);
    let job = formatSearchTable(response, cat, reg, vol);

    return job;
  }

  setProfil = (prov) => {
    let obj = {};

    switch(prov) {
      case "profil":
        obj = {fr:[{Title:"Mise à jour du profil"}], en:[{Title:"Profil update"}], type:"profil"};
      break;
      case "spontaneous":
        obj = {fr:[{Title:"Candidature spontanée"}], en:[{Title:"Spontaneous application"}], type:"spontaneous"};
      break;
      case "jobApply":
        obj = {};
      break;
      default:
        obj = {};
    }

    let objData = setJobApply(obj);
    return objData;
  }

  setDataObj = (response, cookie) => {
    let dataObj = {};
    dataObj.msie = response.unsubsc === "ie" ? true : false;

    let jobFair = setJobFair();
    let env = this.setEnvVariables();
    dataObj.jobFairID = jobFair.jobFairIDV;
    dataObj.env = env;
    let step = this.state.goto;
    let finalObj = {step:"Search", dataObj:dataObj};
    //let resid = this.state.resid;

    if(response) {
      if(response.JobDesc) {
        /*let cat = formatList(response.cat, "cat");
        let reg = formatList(response.reg, "reg");
        let vol = formatVolet(response.vol);
        let job = formatSearchTable(response, cat, reg, vol);*/
        let job = this.getJobDesc(response);

        dataObj.appliedTo = setJobApply(job);
        //dataObj.jobDescription = job;
        dataObj.origin = this.state.ori;

        finalObj = {step:step, dataObj:dataObj, datatotr:job};
      }
      if(response.unsubsc) {
        //dataObj.unsubsc = response.unsubsc;
        finalObj = {step:"mess", dataObj:dataObj, datatotr: response.unsubsc};
      }
      if(response.profil) {
        //passer job description from request
        dataObj.appliedTo = this.setProfil(response.profil);
        finalObj = {step:step, dataObj:dataObj};
      }
      if(response.resid) {
        dataObj.resid = response.resid;
        let obj = {step:response.goto, dataObj:dataObj};
        this.setState({firstStep : obj});
        this.setState({ajaxCall: true});
      }
    }

    if(cookie) {
      let reqObj = {};
      reqObj.request = "update";
      reqObj.sesskey = cookie;
      this.ajaxCall(reqObj, {step:step});
    }

    else {
      this.setReadyState(finalObj);
    }
  }

  setReadyState = (obj) => {
    this.setState({firstStep : obj});
    this.setState({ajaxCall: true});
  }

  getLanguage = (language) => {
    this.setState({lg: language});
    this.setDocumentTitle(language);
  }

  setDocumentTitle = (lg) => {
    let frTitle = "COGIR | Gestion de propriétés immobilières commerciales, industrielles et bureaux";
    let enTitle = "COGIR | Management of real estate properties in commercial & industrial sectors";
    document.title = (lg === "fr") ? frTitle : enTitle;
  }

  render() {
    const {lg, firstStep, ajaxCall} = this.state;

    return (
      <div>
      { ajaxCall &&
      <IntlProvider locale={lg} messages={messages[lg]}>
        <Forms setLanguage={this.getLanguage} language={lg} firstStep={firstStep} />
      </IntlProvider>
      }
      </div>
    );
  }
}

export default App;
