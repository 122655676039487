import React from 'react';
import {FormattedMessage} from 'react-intl';
import TextInput from '../Controls/TextInput.js';
import SelectInput from '../Controls/SelectInput.js';
import TextAreaInput from '../Controls/TextArea.js';
import RadioInput from '../Controls/RadioInput.js';
import NextPrevious from '../Controls/NextPrevious.js';
import JobApplyAlert from '../Controls/InfoMessage.js';
import grades from "../translations/grades.json";

import {validateRequired, countCharBack} from '../Utils/Validate';

class Education extends React.Component {
  constructor(props) {
    super(props);
    let dataObj = this.props.dataObj;
    let form = dataObj.fl3;//JSON.parse(sessionStorage.getItem('fl3'));
    let form1 = dataObj.fl4; //JSON.parse(sessionStorage.getItem('fl4'));

    let lastDegreeObj = {value:"", validate:false, touch:false};
    let lastDegreeInfoObj = {value:"", validate:false, touch:false, validationRequired:false, count:500};

    if(form ) {
      if(form.hasOwnProperty("lastDegree")) {
        lastDegreeObj.value = form.lastDegree;
        lastDegreeObj.validate = true;
        lastDegreeObj.touch = true;
        lastDegreeInfoObj.validationRequired = form.lastDegree.value === "other" ? true : false;
      }
      if(form.hasOwnProperty("lastDegreeInfo")) {
        lastDegreeInfoObj.value = form.lastDegreeInfo;
        lastDegreeInfoObj.validate = (form.lastDegreeInfo.length > 0) ? true : false;
        lastDegreeInfoObj.touch = true;
        lastDegreeInfoObj.count = form.count;
      }
    }


    this.state = {
      lastDegree: lastDegreeObj,
      lastDegreeInfo: lastDegreeInfoObj,
      degreeObtained: {value:(form ) ? form.degreeObtained : null, validate:(form ) ? true : false, touch:(form ) ? true : false},
      association: {value:(form ) ? form.association : ''},
      skillCard: {value:(form ) ? form.skillCard : ''},
      otherFormation: {value:(form ) ? form.otherFormation : ''},

      spFrLanguage: {value:(form1 ) ? form1.spFrLanguage.value : "", validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      spEnLanguage: {value:(form1 ) ? form1.spEnLanguage.value : "", validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      spOther : (form1 ) ? form1.spOther : "",
      wrFrLanguage: {value:(form1 ) ? form1.wrFrLanguage.value : "", validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      wrEnLanguage: {value:(form1 ) ? form1.wrEnLanguage.value : "", validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      wrOther : (form1 ) ? form1.wrOther : "",
      jobApply: dataObj.appliedTo//JSON.parse(sessionStorage.getItem('appliedTo'))
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    let top = document.getElementById("header").offsetTop;
    window.scrollTo(0, top);
  }

  handleSelect = (value) => {
    this.setState(prevState => ({ lastDegree: {...prevState.lastDegree, touch: true}}));
    (value) ? this.setState(prevState => ({ lastDegree: {...prevState.lastDegree, validate: true}})) :
    this.setState(prevState => ({ lastDegree: {...prevState.lastDegree, validate: false}}));
    this.setState(prevState => ({ lastDegree: {...prevState.lastDegree, value: value}}));
    if(value === "4") {
        this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, validationRequired: true}}));
        this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, touch: true}}));
        if(this.state.lastDegreeInfo.value.hasOwnProperty("value")) {
          (this.state.lastDegreeInfo.value.value.length > 0) ? this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, validate: true}})) :
          this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, validate: false}}));
        }
    }
    else {
      this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, validationRequired: false}}));
    }
  }

  handleChange(event) {

    const value = event.target.value;
    const name = event.target.name;

    switch(name) {
    case "lastDegree":
      this.handleSelect(value)
      break;
    case "lastDegreeInfo":
      this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, value: value}}), this.validateInput(name, value));
      break;
    case "degreeObtained":
      this.setState(prevState => ({ degreeObtained: {...prevState.degreeObtained, touch: true}}));
      this.setState(prevState => ({ degreeObtained: {...prevState.degreeObtained, validate: true}}));
      this.setState(prevState => ({ degreeObtained: {...prevState.degreeObtained, value: value}}));
      break;
    case "association":
      this.setState(prevState => ({ association: {...prevState.association, value: value}}));
      break;
    case "skillCard":
      this.setState(prevState => ({ skillCard: {...prevState.skillCard, value: value}}));
      break;
    case "otherFormation":
      this.setState(prevState => ({ otherFormation: {...prevState.otherFormation, value: value}}));
      break;
    case "spFrLanguage":
      this.setState(prevState => ({ spFrLanguage: {...prevState.spFrLanguage, value: value}}), this.validateInput(name, value));
      break;
    case "spEnLanguage":
      this.setState(prevState => ({ spEnLanguage: {...prevState.spEnLanguage, value: value}}), this.validateInput(name, value));
      break;
    case "spOther":
      this.setState({ spOther: value });
      break;
    case "wrFrLanguage":
      this.setState(prevState => ({ wrFrLanguage: {...prevState.wrFrLanguage, value: value}}), this.validateInput(name, value));
      break;
    case "wrEnLanguage":
      this.setState(prevState => ({ wrEnLanguage: {...prevState.wrEnLanguage, value: value}}), this.validateInput(name, value));
      break;
    case "wrOther":
      this.setState({ wrOther: value });
      break;
    default:
    }
  }

  validateInput = (name, value) => {

    switch(name) {
      case "lastDegreeInfo":
        this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, touch: true}}));
        if(this.state.lastDegreeInfo.validationRequired) {
          validateRequired(value) ? this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, validate: true}})) : this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, validate: false}}));
        }
        this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, count: countCharBack(value, 500)}}));
        break;
        case "spFrLanguage":
          this.setState(prevState => ({ spFrLanguage: {...prevState.spFrLanguage, touch: true}}));
          this.setState(prevState => ({ spFrLanguage: {...prevState.spFrLanguage, validate: true}}));
          //validateRequired(value) ? this.setState(prevState => ({ spFrLanguage: {...prevState.spFrLanguage, validate: true}})) : this.setState(prevState => ({ address: {...prevState.address, validate: false}}));
          break;
        case "spEnLanguage":
          this.setState(prevState => ({ spEnLanguage: {...prevState.spEnLanguage, touch: true}}));
          this.setState(prevState => ({ spEnLanguage: {...prevState.spEnLanguage, validate: true}}));
          break;
        case "wrFrLanguage":
          this.setState(prevState => ({ wrFrLanguage: {...prevState.wrFrLanguage, touch: true}}));
          this.setState(prevState => ({ wrFrLanguage: {...prevState.wrFrLanguage, validate: true}}));
          break;
        case "wrEnLanguage":
          this.setState(prevState => ({ wrEnLanguage: {...prevState.wrEnLanguage, touch: true}}));
          this.setState(prevState => ({ wrEnLanguage: {...prevState.wrEnLanguage, validate: true}}));
          break;
        default:
      }
    }

  handleSubmit(event) {

    const { lastDegree, lastDegreeInfo, degreeObtained, association, skillCard, otherFormation,
    spFrLanguage, spEnLanguage, spOther, wrFrLanguage, wrEnLanguage, wrOther } = this.state;

    let shdata = {"lastDegree":lastDegree.value, "lastDegreeInfo": lastDegreeInfo.value, "degreeObtained": degreeObtained.value,
    "association" : association.value, "skillCard" : skillCard.value ,"otherFormation" : otherFormation.value, "count" : lastDegreeInfo.count };

    let passDegreeInfo = true;

    if(lastDegreeInfo.validationRequired) {
      passDegreeInfo = lastDegreeInfo.validate;
    }

    if(!lastDegree.validate) {
      this.setState(prevState => ({ lastDegree: {...prevState.lastDegree, touch: true}}));
    }

    if(!degreeObtained.validate) {
      this.setState(prevState => ({ degreeObtained: {...prevState.degreeObtained, touch: true}}));
    }

    if(lastDegreeInfo.validationRequired) {
        this.setState(prevState => ({ lastDegreeInfo: {...prevState.lastDegreeInfo, touch: true}}));
    }

    let lgdata = {"spFrLanguage":spFrLanguage, "spEnLanguage" : spEnLanguage, "spOther": spOther,
                "wrFrLanguage":wrFrLanguage, "wrEnLanguage" : wrEnLanguage, "wrOther": wrOther};

    if(lastDegree.validate && degreeObtained.validate && passDegreeInfo) {
      let dataObj = this.props.dataObj;
      let step = {"step" : "fl5"};
      //sessionStorage.setItem('fl3', JSON.stringify(shdata));
      //sessionStorage.setItem('fl4', JSON.stringify(lgdata));
      dataObj.fl3 = shdata;
      dataObj.fl4 = lgdata;
      step.dataObj = dataObj;

      this.props.nextForm(step);
    }

    event.preventDefault();
  }

  goToNextForm = (value) => {
    let dataObj = this.props.dataObj;
    let step = {"step" : value};

    if(value === "fc1") {
      step = {"step" : value, "fromUpload" : true};
    }

    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  render() {

    const { lastDegreeInfo, spFrLanguage, spEnLanguage, wrFrLanguage, wrEnLanguage, wrOther, jobApply } = this.state;
    const lg = this.props.language;
    const applied = lg === "fr" ? jobApply.fr : jobApply.en;

    return (
      <div>
        <div className="container mb-4">
        <JobApplyAlert gotToPrev={() => this.goToNextForm("Search")} jobApply={applied.Title} type={jobApply.type}/>
          <form ref={this.form} onSubmit={this.handleSubmit} noValidate>
              <div className="row">
                  <div className="col-sm-8">
                    <h4><FormattedMessage id="progress.education"/></h4>
                    <hr />
                  </div>
              </div>
                <div className="row mb-2">
                  <div className="col-sm-2 ">
                  <FormattedMessage id="app.lastDegree" /><span className="text-danger ml-2">*</span>
                  </div>
                  <div className="col-sm-6">
                  <SelectInput name="lastDegree" value={this.state.lastDegree.value} options={this.props.language === "fr" ? grades.fr : grades.en} method={this.handleChange} />
                   <p><span className="text-danger">{(this.state.lastDegree.touch && !this.state.lastDegree.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-2 ">
                  <FormattedMessage id="app.lastDegreeInfo" />
                  </div>
                  <div className="col-sm-6">
                  <FormattedMessage id="app.lastDegreeInfoEx">
                  { plc=> <TextAreaInput name="lastDegreeInfo" value={lastDegreeInfo.value} onChange={this.handleChange} placeholder={plc}/> }
                  </FormattedMessage>
                  <small>{lastDegreeInfo.count} <FormattedMessage id="app.characterCount"/></small>
                   <p><span className="text-danger">{(this.state.lastDegreeInfo.touch && !this.state.lastDegreeInfo.validate && this.state.lastDegreeInfo.validationRequired) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                  <div className="col-sm-2 ">

                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-2 ">
                  <FormattedMessage id="app.degreeObtained"/><span className="text-danger ml-2">*</span>
                  </div>
                  <div className="col-sm-6">
                      <div className="input-group">
                        <label className="pr-2"><RadioInput value="0" name="degreeObtained" checked={this.state.degreeObtained.value === "0"} onChange={this.handleChange}/><FormattedMessage id="app.yes"/></label>
                        <label className="pr-2"><RadioInput value="1" name="degreeObtained" checked={this.state.degreeObtained.value === "1"} onChange={this.handleChange}/><FormattedMessage id="app.no"/></label>
                      </div>
                      <p><span className="text-danger">{(this.state.degreeObtained.touch && !this.state.degreeObtained.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-2">
                  <FormattedMessage id="app.association" />
                  </div>
                  <div className="col-sm-6">
                  <TextInput name="association" value={this.state.association.value} onChange={this.handleChange} />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-2">
                  <FormattedMessage id="app.skillCard" />
                  </div>
                  <div className="col-sm-6">
                  <TextInput name="skillCard" value={this.state.skillCard.value} onChange={this.handleChange} />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-2">
                  <FormattedMessage id="app.otherFormation" />
                  </div>
                  <div className="col-sm-6">
                  <TextInput name="otherFormation" value={this.state.otherFormation.value} onChange={this.handleChange} />
                  </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                    <hr />
                    </div>
                </div>
                {/*language*/}
                <div className="row">
                    <div className="col-sm-8">
                      <h4><FormattedMessage id="progress.language"/></h4>
                      <hr />
                    </div>
                </div>
                <p><strong><FormattedMessage id="app.french"/></strong></p>
                <div className="row">
                  <div className="col-sm-2 ">
                  <FormattedMessage id="app.spokenLanguage"/><span className="text-danger ml-2"></span>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <label className="pr-2"><RadioInput value="1" name="spFrLanguage" checked={spFrLanguage.value === "1"} onChange={this.handleChange}/><FormattedMessage id="app.beginner"/></label>
                      <label className="pr-2"><RadioInput value="2" name="spFrLanguage" checked={spFrLanguage.value === "2"} onChange={this.handleChange}/><FormattedMessage id="app.intermediate"/></label>
                      <label className="pr-2"><RadioInput value="3" name="spFrLanguage" checked={spFrLanguage.value === "3"} onChange={this.handleChange}/><FormattedMessage id="app.advanced"/></label>
                    </div>
                    <p><span className="text-danger">{(spFrLanguage.touch && !spFrLanguage.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 ">
                  <FormattedMessage id="app.writtenLanguage"/><span className="text-danger ml-2"></span>
                  </div>
                  <div className="col-sm-6">
                  <div className="input-group">
                      <label className="pr-2"><RadioInput value="1" name="wrFrLanguage" checked={wrFrLanguage.value === "1"} onChange={this.handleChange}/><FormattedMessage id="app.beginner"/></label>
                      <label className="pr-2"><RadioInput value="2" name="wrFrLanguage" checked={wrFrLanguage.value === "2"} onChange={this.handleChange}/><FormattedMessage id="app.intermediate"/></label>
                      <label className="pr-2"><RadioInput value="3" name="wrFrLanguage" checked={wrFrLanguage.value === "3"} onChange={this.handleChange}/><FormattedMessage id="app.advanced"/></label>
                    </div>
                    <p><span className="text-danger">{(wrFrLanguage.touch && !wrFrLanguage.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                </div>

                <p><strong><FormattedMessage id="app.english"/></strong></p>

                <div className="row">
                  <div className="col-sm-2 ">
                  <FormattedMessage id="app.spokenLanguage"/><span className="text-danger ml-2"></span>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-group">
                    <label className="pr-2"><RadioInput value="1" name="spEnLanguage" checked={spEnLanguage.value === "1"} onChange={this.handleChange}/><FormattedMessage id="app.beginner"/></label>
                    <label className="pr-2"><RadioInput value="2" name="spEnLanguage" checked={spEnLanguage.value === "2"} onChange={this.handleChange}/><FormattedMessage id="app.intermediate"/></label>
                    <label className="pr-2"><RadioInput value="3" name="spEnLanguage" checked={spEnLanguage.value === "3"} onChange={this.handleChange}/><FormattedMessage id="app.advanced"/></label>
                    </div>
                    <p><span className="text-danger">{(spEnLanguage.touch && !spEnLanguage.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 ">
                  <FormattedMessage id="app.writtenLanguage"/><span className="text-danger ml-2"></span>
                  </div>
                  <div className="col-sm-6">
                  <div className="input-group">
                  <label className="pr-2"><RadioInput value="1" name="wrEnLanguage" checked={wrEnLanguage.value === "1"} onChange={this.handleChange}/><FormattedMessage id="app.beginner"/></label>
                    <label className="pr-2"><RadioInput value="2" name="wrEnLanguage" checked={wrEnLanguage.value === "2"} onChange={this.handleChange}/><FormattedMessage id="app.intermediate"/></label>
                    <label className="pr-2"><RadioInput value="3" name="wrEnLanguage" checked={wrEnLanguage.value === "3"} onChange={this.handleChange}/><FormattedMessage id="app.advanced"/></label>
                    </div>
                    <p><span className="text-danger">{(wrEnLanguage.touch && !wrEnLanguage.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                </div>
                <div className="row mb-2">
                      <div className="col-sm-2 ">
                      <FormattedMessage id="app.other"/>
                      </div>
                    <div className="col-sm-6">
                    <div className="input-group">
                      <TextInput name="wrOther" value={wrOther} onChange={this.handleChange} />
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-8">
                <hr />
                </div>
                </div>
                <NextPrevious gotToPrev={() => this.goToNextForm("fl1")} goToShort={() => this.goToNextForm("fc1")} long="0" />
            </form>
          </div>
        </div>
    );
  }
}

export default Education;
