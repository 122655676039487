import React from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';

class Confirmation extends React.Component {
  constructor(props) {
    super(props);

    let dataObj = this.props.dataObj;
    this.state = {
      appliedTo: dataObj ? dataObj.appliedTo : false,
      errorFromServer: false//JSON.parse(sessionStorage.getItem('appliedTo'))
    };

  }

  componentDidMount() {
    let top = document.getElementById("header").offsetTop;
    window.scrollTo(0, top);

    let dataObj = this.props.dataObj;
    let id = dataObj.fl1;
    const lg = this.props.language;
    let applied = lg === "fr" ? dataObj.appliedTo.fr : dataObj.appliedTo.en;
    let request = {};
    request.request = "confirmation";
    request.email = id.email;
    request.name = id.fname;
    request.jobsname = applied.Title;
    request.text = this.getTextForEmail(dataObj.appliedTo.type, lg);
    request.lg = this.props.language;
    this.ajaxCall(request);
  }

  getTextForEmail = (prov, lg) => {
    let txt = lg === "fr" ? "Nous avons bien reçu votre candidature pour le poste suivant " : "We have received your application for the following position ";
    if (prov === "profil") {
      txt = lg === "fr" ? "L'action suivante a été exécutée avec succès " : "The following action was successfully executed ";
    }
    return txt;
  }

  ajaxCall = (request) => {
    const url = this.props.dataObj.env ? this.props.dataObj.env.urlJson : "http://scripts.local"; //Default devrait être prod.
    axios.post(url, request, { headers: {'Content-Type': 'application/json'}})
      .then((response)  => {
        this.setState({errorFromServer: false});
      })
      .catch((error)  => {
        this.setState({errorFromServer: true});
      });
  }

  getData(){
    this.timerHandle = setTimeout(() => {
      this.goToNextForm();
    }, 20000)
  }


  goToNextForm = () => {
    //sessionStorage.clear();
    let step = {"step" : "home"};
    this.props.nextForm(step);
    //window.location.reload();
  }

  render() {

    //const lg = this.props.language;
    const {appliedTo} = this.state;
    //const applied = lg === "fr" ? appliedTo.fr : appliedTo.en;

    let submessage;

    if(appliedTo.type === "spontaneous") {
      submessage = <div><p><FormattedMessage id="conf.confirmation"/></p><p><FormattedMessage id="conf.subConfirmationspontannee"/></p></div>;
    }

    else if(appliedTo.type === "profil") {
      submessage = <p><FormattedMessage id="conf.profil"/></p>;
    }

    else {
        submessage = <div><p><FormattedMessage id="conf.confirmation"/></p><p><FormattedMessage id="conf.subConfirmation"/></p></div>;
    }

    return (
      <div className="container mb-4">
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading text-center"><FormattedMessage id="conf.titleConfirmation"/></h4>
          {submessage}
          <p><FormattedMessage id="conf.pleasure"/></p>
          <p><FormattedMessage id="conf.signature"/></p>
        </div>
      </div>
    );
  }
}

export default Confirmation;
