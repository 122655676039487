import moment from 'moment';

export function formatJobsCategoryList(jobsList) {
  let jobsListFormat = [];
  let categoryListFormat = [];

  if(jobsList) {
    let fr = jobsList.hasOwnProperty("Fr") ? jobsList.Fr : null;
    let en = jobsList.hasOwnProperty("En") ? jobsList.En : null;

    if(fr && en) {
      jobsListFormat.push(getObjectLgArray(fr));
      jobsListFormat.push(getObjectLgArray(en));

      categoryListFormat.push(getCategoryList(jobsListFormat[0]));
      categoryListFormat.push(getCategoryList(jobsListFormat[1]));
    }
  }

  return categoryListFormat;
}

function getCategoryList(lst) {

  let categoryFormat = [];
  const dist = [...new Set(lst.map(x => x.category))]

  for (var j = 0; j < dist.length; j++) {
    let object = {};
    object.value = j + 1;
    object.label = dist[j];
    categoryFormat.push(object);
  }

  return categoryFormat;
}

//format job list
export function formatJobsList(jobsList) {

  let jobsListFormat = [];

  if(jobsList) {
    let fr = jobsList.hasOwnProperty("Fr") ? jobsList.Fr : null;
    let en = jobsList.hasOwnProperty("En") ? jobsList.En : null;

    if(fr && en) {
      jobsListFormat.push(getObjectLgArray(fr));
      jobsListFormat.push(getObjectLgArray(en));
    }
  }

  return jobsListFormat;
}

export function getJobListByLanguage(jobsList) {
  let obj = {fr:[], en:[]};

  obj.fr = jobsList.map(function (val) {
  return getJobListObj(val, "/", "fr");
  });

  obj.en = jobsList.map(function (val) {
  return getJobListObj(val, "/", "en");
  });

  return obj;
}

function getJobListObj(val, symb, lg) {

  let ind = val.Job.indexOf(symb);
  let job = (lg === "fr") ? val.Job.slice(0, ind) : val.Job.slice(ind + 1);

  return {
    id: val.ID,
    job: job,
    category: val.Category,
    subcategory: val.SubCat
  };
}

function getObjectLgArray(lgArray) {
  let lgListFormat = [];
    for (var j = 0; j < lgArray.length; j++) {
      let object = {};
      //Category
      let oriCategory = lgArray[j].category;
      let indCategory = oriCategory.indexOf("|");
      object.idCategory = oriCategory.slice(indCategory + 1);
      object.category = oriCategory.slice(0, indCategory);
      //OtherCategory
      let oriOtherCategory = lgArray[j].otherCategory;
      let indOtherCategory = oriOtherCategory.indexOf("|");
      object.idOtherCategory = oriOtherCategory.slice(indOtherCategory + 1);
      object.otherCategory = oriOtherCategory.slice(0, indOtherCategory);
      //Title
      let oriTitle = lgArray[j].title;
      let indTitle = oriTitle.indexOf("|");
      object.idTitle = oriTitle.slice(indTitle + 1);
      object.title = oriTitle.slice(0, indTitle);

      lgListFormat.push(object);
    }
  return lgListFormat;
}

export function formatRegionsList(regionsList) {
  let regionsListFormat = [];
  let regionsDistinct = [];

      if(regionsList) {
      let fr = regionsList.hasOwnProperty("Fr") ? regionsList.Fr : null;
      let en = regionsList.hasOwnProperty("En") ? regionsList.En : null;

      if(fr && en) {
        regionsListFormat.push(getFormatRegionsList(fr, "fr"));
        regionsListFormat.push(getFormatRegionsList(en, "en"));
      }

      regionsDistinct.push(getRegionsList(regionsListFormat[0]));
      regionsDistinct.push(getRegionsList(regionsListFormat[1]));
    }

    return regionsDistinct;
}

function getRegionsList(lst) {

  let regionsFormat = [];
  const dist = [...new Set(lst.map(x => x.region))]
  const rid = [...new Set(lst.map(x => x.rid))]

  for (var j = 0; j < dist.length; j++) {
    let object = {};
    object.value = rid[j];
    object.label = dist[j];
    regionsFormat.push(object);
  }

  return regionsFormat;
}

//format region list
export function formatRegionsTable(regionsList) {
let regionsListFormat = [];

    if(regionsList) {
    let fr = regionsList.hasOwnProperty("Fr") ? regionsList.Fr : null;
    let en = regionsList.hasOwnProperty("En") ? regionsList.En : null;

    if(fr && en) {
      regionsListFormat.push(getFormatRegionsList(fr, "fr"));
      regionsListFormat.push(getFormatRegionsList(en, "en"));
    }
  }

  return regionsListFormat;

}

function getFormatRegionsList(provincesLg, locale) {
  let prListFormat = [];
  //let rgListFormat = [];
  let provincelist = [];
  let ctList = [];
  let cttList = [];
  //let all = (locale === "en") ? {"region": "All", "city": "All", "id": "all"} : {"region": "Toutes", "city": "Toutes", "id": "all"};
  //cttList.push(all);

    for (let j = 0; j < provincesLg.length; j++) {
        let provObj = {};
        provObj.provinces = provincesLg[j];
        prListFormat.push(provObj);
    }

    for (let j = 0; j < prListFormat.length; j++) {
      for (let reg in prListFormat[j].provinces) {
        provincelist.push(reg);
        for (let i = 0; i < prListFormat[j].provinces[reg].length; i++) {
          ctList.push(prListFormat[j].provinces[reg][i]);
        }
      }
    }

    for (let j = 0; j < ctList.length; j++) {
      for (let reg in ctList[j]) {
        for (let i = 0; i < ctList[j][reg].length; i++) {
          let obj = {"region": reg, "city": ctList[j][reg][i].city, "id": ctList[j][reg][i].ID, "rid": ctList[j][reg][i].RID} // .city, .ID
          cttList.push(obj);
        }
      }
    }

    return cttList;
}

export function formatValueWithSymb(obj, symb) {
  var str = "";
    for (let it in obj) {
      (it === "0") ? str+=obj[it] : str+=symb+obj[it];
  }

  return str;
}

export function formatMultipleDate(obj, symb) {
  var str = "";
    for (let it in obj) {
      let dte = moment(obj[it]).format('YYYY-MM-DD');
      (it === "0") ? str+=dte : str+=symb+dte;
  }

  return str;
}

export function formatCity(obj) {

  let symb = "|"
  var str = "";
  for (let j = 0; j < obj.length; j++) {
    j === 0 ? str+=obj[j]._original.id : str+=symb+obj[j]._original.id;
  }
  return str;
}

export function formatJobData(obj) {
  let symb = "|";
  var str = "";
  for (let j = 0; j < obj.length; j++) {
    let tit = obj[j]._original.id;
    let cat = obj[j]._original.category;
    let sub = obj[j]._original.subcategory;
    j === 0 ? str+=tit+","+cat+","+sub : str+=symb+tit+","+cat+","+sub;
  }

  return str;
}

export function formatJobShortForm(arr) {

  let symb = "|";
  var str = "";
  for (let j = 0; j < arr.length; j++) {
    let tit = arr[j].value;
    let cat = 0;
    let sub = 0;
    j === 0 ? str+=tit+","+cat+","+sub : str+=symb+tit+","+cat+","+sub;
  }
  return str;
}

export function formatStrWithPipe(arr) {
  let symb = "|";
  var str = "";

  if(arr.length > 0) {
    for (let j = 0; j < arr.length; j++) {
      let val = arr[j].value;
      j === 0 ? str+=val : str+=symb+val;
    }
  }
  return str;
}


export function formatRegionSearch(obj) {
  let arrF = [];
  let arr = [];
  let reg0 = [];
  let cit0 = [];
  for (let j = 0; j < obj.length; j++) {
    let my = {};
    let c = obj[j]._original.id;
    let i = c.indexOf("_");

    if(i === -1) {
      my.city = parseInt(c);
      my.region = parseInt(obj[j]._original.rid);
      cit0.push(my);
    }

    else {
      my.city = 0;
      my.region = parseInt(c.slice(i+1));
      reg0.push(my);
    }
    arr.push(my);
  }

  for (let j = 0; j < arr.length; j++) {
    if(arr[j].city === 0) {
      reg0.push(arr[j].region);
    }
  }


  for (let j = 0; j < reg0.length; j++) {
    let v = arr.find(v => v.city === 0);
    arrF.push(v);
  }

  return arr;
}

export function formatJobSearch(obj) {
  let arr = [];
  for (let j = 0; j < obj.length; j++) {
    let id = parseInt(obj[j]._original.idTitle);
    arr.push(id);
  }
  return arr;
}

export function formatSelectedValues(obj) {
  let arr = [];
  for (let j = 0; j < obj.length; j++) {
    arr.push(parseInt(obj[j].value));
  }
  return arr;
}


export function formatString(str, symb) {
  let a = str.split(symb);
  return a;
}

export function countCharBack(value, max) {
  var parsed = parseInt(value.length);
  return max - parsed;
}

export function getSelectedOption(jobsData, catList) {
  const cat = [...new Set(jobsData.map(x => x._original.idCategory))];
  let a = [];
  for (let i = 0; i < cat.length; i ++) {
    let obj = catList.filter(x => x.value === parseInt(cat[i]));
    a.push(obj);
  }
  return a.flat();
}

export function getSelectedRegions(regionsData, regionsListComplete, regionsList) {
  let a = [];
  let selectedRegions = [];
  for (let i = 0; i < regionsData.length; i ++) {
    let id = regionsData[i]._original.id;
    let ind = id.indexOf("0_");
    let val = regionsListComplete.find(function(element) {
        if(ind === -1) {
          return element.id === id;
        }
        else {
          let rid = id.slice(2);
          return element.rid === rid;
        }
    });

    selectedRegions.push(val.rid);
  }
  let unique = [...new Set(selectedRegions)];
  for (let u = 0; u < unique.length; u ++) {
    let obj = regionsList.filter(x => x.value === unique[u]);
    a.push(obj);
  }

  return a.flat();
}

export function setJobFair() {
  let obj = {};

  let jobFairID = window.jobFair ? window.jobFair : false;
  let jobBlurb = window.jobBlurb ? window.jobBlurb : false;
  let jobTitle = window.jobTitle ? window.jobTitle : false;

  obj.jobFairIDV = jobFairID ? jobFairID : "0";

  //sessionStorage.setItem('jobFairID', JSON.stringify(obj.jobFairIDV));

  obj.jobBlurbLg = jobBlurb ? formatString(jobBlurb, "//") : ["", ""];
  obj.jobTitleLg = jobTitle ? formatString(jobTitle, "//") : ["", ""];

  return obj;
}

export function formatSubmitData(fl5, lg, dataObj) {

  let f0 = dataObj.f0;//JSON.parse(sessionStorage.getItem('f0'));
  //let fc1 = JSON.parse(sessionStorage.getItem('fc1'));
  let fl1 = dataObj.fl1;//JSON.parse(sessionStorage.getItem('fl1'));
  let fl2 = dataObj.fl2;//JSON.parse(sessionStorage.getItem('fl2'));
  let fl3 = dataObj.fl3;//JSON.parse(sessionStorage.getItem('fl3'));
  let fl4 = dataObj.fl4;//JSON.parse(sessionStorage.getItem('fl4'));
  //let fl5 = JSON.parse(sessionStorage.getItem('fl5'));
  let fl6 = dataObj.fl6;//JSON.parse(sessionStorage.getItem('fl6'));

  let appliedTo = dataObj.appliedTo;//jJSON.parse(sessionStorage.getItem('appliedTo'));
  let applied = lg === "fr" ? appliedTo.fr : appliedTo.en;

  let jobFairID = dataObj.jobFairID;//JSON.parse(sessionStorage.getItem('jobFairID'));

  const data = new FormData();

  let cell1 = fl1.hasOwnProperty('cell1') ? fl1.cell1 : '';
  let cell2 = fl1.hasOwnProperty('cell2') ? fl1.cell2 : '';
  let cell3 = fl1.hasOwnProperty('cell3') ? fl1.cell3 : '';

  data.append('Lang', lg === "fr" ? "1" : "2");

  data.append('AppliedTo', JSON.stringify(applied));

  data.append('Fname', fl1.fname);
  data.append('Lname', fl1.lname);
  data.append('Email', fl1.email);
  data.append('Phone', fl1.phone1+fl1.phone2+fl1.phone3);
  data.append('Cell', cell1+cell2+cell3);

  //fl2
  data.append('Address', fl2.address + fl2.additional);
  data.append('City', fl2.city);
  data.append('Street', fl2.route);
  data.append('Province', fl2.province);
  data.append('Zip', fl2.postalCode);
  data.append('Country', fl2.country);

  //fl3
  data.append('EducationLevel', fl3.lastDegree);
  data.append('EducationLevelDescription', fl3.lastDegreeInfo);
  data.append('DegreeObtained', fl3.degreeObtained);
  data.append('ProfessionalAssociation', fl3.association);
  data.append('SkillCard', fl3.skillCard);
  data.append('OtherFormation', fl3.otherFormation);

  //fl4
  let spValue = "FR;".concat(fl4.spFrLanguage.value, "|", "EN;", fl4.spEnLanguage.value);
  let wrValue = "FR;".concat(fl4.wrFrLanguage.value, "|", "EN;", fl4.wrEnLanguage.value);
  data.append('SpokenLanguage', spValue);
  data.append('OtherSpokenLanguage', fl4.spOther);
  data.append('WrittenLanguage', wrValue);
  data.append('OtherWrittenLanguage', fl4.wrOther);

  //fl5
  let symb = "-||-";
  let emp = formatValueWithSymb(fl5.employer.value, symb);
  let ttl = formatValueWithSymb(fl5.jobTitle.value, symb);
  let tsk = formatValueWithSymb(fl5.task.value, symb);
  let std = formatMultipleDate(fl5.startDate.value, symb);
  let end = formatMultipleDate(fl5.endDate.value, symb);

  data.append('EmployerName', emp);
  data.append('FormerJob', ttl);
  data.append('Task', tsk);
  data.append('StartDate', std);
  data.append('EndDate', end);

  data.append('Q1', fl6.q1 === null ? "0" : fl6.q1);
  data.append('Q2', fl6.q2 === null ? "0" : fl6.q2);
  data.append('Q2Details', fl6.q2Details === null ? "0" : fl6.q2Details);
  data.append('Q3', fl6.q3 === null ? "0" : fl6.q3);
  data.append('Q3Details', fl6.q3Details === null ? "0" : fl6.q3Details);
  data.append('Q4', fl6.q4 === null ? "0" : fl6.q4);
  data.append('Q5', fl6.q5 === null ? "0" : fl6.q5);
  data.append('Q6', fl6.q6 === null ? "0" : fl6.q6);
  data.append('Q7', fl6.q7 === null ? "0" : fl6.q7);

  //let jobData = formatJobShortForm(form.postuledJob);
  //let regionsData = formatStrWithPipe(form.regionsData);

  data.append('Job', formatJobData(f0.rowData));
  data.append('Region', formatStrWithPipe(f0.regionsData));

  data.append('JobFairID', jobFairID === null ? "1" : jobFairID);
  data.append('Long', "2");
  data.append('Origin', parseInt(dataObj.origin) ? parseInt(dataObj.origin) : "1");

  return data;
}

export function formatShortSubmitData(form, dataObj, lg) {

  let jobFairID = dataObj.jobFairID; // JSON.parse(sessionStorage.getItem('jobFairID'));

  const data = new FormData();

  data.append('Lang', lg ? lg : "en");

  data.append('AppliedTo', JSON.stringify(form.appliedTo));
  data.append('Fname', form.Identifications.fname);
  data.append('Lname', form.Identifications.lname);
  data.append('Email', form.Identifications.email);
  data.append('Phone', form.Identifications.phone);
  data.append('CV', form.Identifications.cv);
  data.append('CL', form.Identifications.cl);

  //questions
  data.append('Q1', form.Questions.q1 === null ? "0" : form.Questions.q1);
  data.append('Q2', form.Questions.q2 === null ? "0" : form.Questions.q2);
  data.append('Q2Details', form.Questions.q2Details === null ? "0" : form.Questions.q2Details);
  data.append('Q3', form.Questions.q3 === null ? "0" : form.Questions.q3);
  data.append('Q3Details', form.Questions.q3Details === null ? "0" : form.Questions.q3Details);
  data.append('Q4', form.Questions.q4 === null ? "0" : form.Questions.q4);
  data.append('Q5', form.Questions.q5 === null ? "0" : form.Questions.q5);
  data.append('Q6', form.Questions.q6 === null ? "0" : form.Questions.q6);
  data.append('Q7', form.Questions.q7 === null ? "0" : form.Questions.q7);

  data.append('Job', formatJobData(form.postuledJob));
  data.append('Region', formatStrWithPipe(form.regionsData));

  data.append('JobFairID', jobFairID === null ? "1" : jobFairID);
  data.append('Origin', parseInt(dataObj.origin) ? parseInt(dataObj.origin) : "1");

  return data;
}
