import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MyDatePicker = (props) => {
  return (
      <DatePicker {...props} />
  );
}

export default MyDatePicker;
