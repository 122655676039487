import React from 'react';
import {FormattedMessage} from 'react-intl';

const NextPrevious = (props) => {
  return (
    <div>
    <div className="row">
      <div className="col-6 col-sm-4">
          <button type="button" className="btn btn-success mb-2 text-white" onClick={props.gotToPrev}><FormattedMessage id="app.previous" defaultMessage="Previous" /></button>
      </div>
      <div className="col-6 col-sm-4 text-right">
          <button type="submit" className="btn btn-success mb-2" disabled={props.submit}>{props.apply === "0" ? <FormattedMessage id="app.submit"/> :
          <span><i className="far fa-save fa-lg pr-2"></i><FormattedMessage id="app.next" defaultMessage="Next" /></span>}
          </button>
      </div>
    </div>
    {props.long === "0" &&
      <div className="row mt-4">
        <div className="col-6 col-sm-4">
        <button type="button" className="btn btn-outline-secondary" onClick={props.goToShort}><FormattedMessage id="app.shortForm"/></button>
        </div>
      </div>
    }
    </div>
  );
}

export default NextPrevious;
