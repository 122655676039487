import React from 'react';
import axios from 'axios';
import {validateRequired} from '../Utils/Validate';
import {mapData} from '../Utils/Update';
import {FormattedMessage} from 'react-intl';

class EmailLooking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        code: "",
        errorFromServer: false,
        errorFromServerMessage: [],
        message: "",
        security:"",
        validate:false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.history.replaceState({}, document.title, "/");
  }

  handleChange(event) {
    this.setState({code: event.target.value});
  }

  validateCode = () => {
    let code = this.state.code;

    const url = this.props.dataObj.env ? this.props.dataObj.env.urlJson : "http://scripts.local"; //Default devrait être prod.

    if(validateRequired(code)) {
      axios.post(url, {
        request: "security",
        data: code
      },{ headers: {'Content-Type': 'application/json'}})
      .then((response)  => {
        if(response.data.validate) {
          this.setState({validate: false});
          let obj = mapData(response.data.candidat, this.props.language, this.props.dataObj);
          //sessionStorage.setItem("noValidate", true);
          this.goToNextForm(obj);
        }
        else {
          this.setState({validate: true});
        }
      })
      .catch((error)  => {
        this.setState({errorFromServerMessage: this.state.errorFromServerMessage.concat(error.message)});
        this.setState({errorFromServer: true});
      });
    }

    else {
      this.setState({validate: true});
    }

  }

  goToNextForm = (dataObj) => {
    this.props.setProfilUpdate();
    let step = {"step" : "fc1"};
    dataObj.noValidate = true;
    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  render() {
    const {errorFromServerMessage, errorFromServer, validate} = this.state;
    const secCode = this.props.language === "fr" ? "Entrez votre code ici" : "Enter your code here";

    return (
      <div>
        <div className="container mb-4 d-flex justify-content-center align-items-center">
          <div className="alert alert-success text-center" role="alert">
            <h4 className="alert-heading"><FormattedMessage id="app.EmailSendTitle"/></h4>
            <hr/>
            <FormattedMessage id="app.EmailSend"/>
          </div>
          </div>

      <div className="container mb-4">
          <div className="row justify-content-sm-center">
            <div className="col-lg-3 text-center">
            <input type="text" className="form-control textCenter" maxLength="6" value={this.state.code} onChange={this.handleChange} placeholder={secCode}/>
            {validate &&
                <p><span className="text-danger"><FormattedMessage id="error.code"/></span></p>
            }
            <button type="button" className="btn btn-success mt-3" onClick={() => this.validateCode()}><FormattedMessage id="app.submit"/></button>
            {errorFromServer &&
            <div className="alert alert-danger" role="alert">
              <p><FormattedMessage id="error.generic"/></p>
              {errorFromServerMessage}
            </div>
            }
            </div>
          </div>
      </div>
    </div>
    );
  }
}

export default EmailLooking;
