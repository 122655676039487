import React from 'react';
import { FormattedMessage } from 'react-intl';
import { setJobApply } from '../Utils/Search';
//import fCogirLogo from '../Images/Footer_LOGO_COGIR_REAL_ESTATE_BlancVert-e1620696328858.png';
import logoFr from '../Images/cogir-rev-fr-110x110.png';
import logoEn from '../Images/cogir-rev-en-110x110.png';
import fCogirFoundation from '../Images/Footer_cogir_foundation.png';
import fCogirFoundationFr from '../Images/CogirFondation_Logo_Blanc_FR.png';
import fCogirReaEstate from '../Images/Footer_cogir_real_estate.png';
import fCogirReaEstateFr from '../Images/LOGO_COGIR_IMMOBILIER_Blanc.png';
import fCogirResidences from '../Images/Footer_cogir_residences.png';
import fCogirResidencesFr from '../Images/CogirResidences_LogoSansIcône_Blanc_FR.png';
import fCogirRestaurant from '../Images/Footer_cogir_restaurants.png';
import fCogirSeniorLivingCa from '../Images/footer_cogir_senior_living_canada.png';
import fCogirSeniorLivingUs from '../Images/Footer_cogir_senior_living_usa.png';

class Footer extends React.Component {

  constructor(props) {
    super(props);
    let dataObj = this.props.dataObj;
    this.state = {
      noValidate: dataObj.noValidate //JSON.parse(sessionStorage.getItem('noValidate'))
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profilUpdate !== prevProps.profilUpdate) {
      this.setState({ noValidate: true });
    }
  }

  startFresh = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let step = { "step": "Search" };
    this.props.nextForm(step);
    window.location.replace("/");
  }

  goToPage = (value) => {
    let dataObj = this.props.dataObj;
    let step = { "step": value };
    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  setSpontaneous = () => {
    //let noValidate = JSON.parse(sessionStorage.getItem('noValidate'));
    let nojob = { fr: [{ Title: "Candidature spontanée" }], en: [{ Title: "Spontaneous application" }], type: "spontaneous" };//{fr:[{Title:"Mise à jour du profil"}], en:[{Title:"Profil update"}], type:"profil"};
    let dataObj = this.props.dataObj;

    if (dataObj.reset) {
      window.location.replace("/");
    }

    else {
      let objData = setJobApply(nojob);
      dataObj.appliedTo = objData;

      let step = { "step": "fc1" };
      step.dataObj = dataObj;

      this.props.nextForm(step);
    }
  }

  moveData = (data) => {
    this.props.dataFrom(data);
  }

  setNextForm = (step) => {
    let dataObj = this.props.dataObj;
    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  render() {

    const lg = this.props.language;

    return (
      <div>
        <footer className="footer bg-dark pb-3 d-none d-md-block">
          <div className="container">
            <div className="row mt-4">
              <div className="col-sm-2">
                <img className="img-fluid" src={lg === "fr" ? logoFr : logoEn} alt="Logo Cogir Blanc Vert" />
              </div>
              <div className="col-sm-3">
                <div className="cogirrealestatetext"><FormattedMessage id="footer.company" /></div>
                <div className="text-white mt-4"><FormattedMessage id="footer.phoneNumber" /></div>
                <div className="emailtext"><FormattedMessage id="footer.email" /></div>
              </div>
              <div className="col-sm-2">
                <div><a href="https://realestate.cogir.net/retirement-homes/" target="blank" className="footerGreytext"><FormattedMessage id="footer.retirementHome" /></a></div>
                <div><a href="https://realestate.cogir.net/restaurants/" target="blank" className="footerGreytext"><FormattedMessage id="footer.restaurant" /></a></div>
                <div><a href="https://realestate.cogir.net/condos-for-sale/" target="blank" className="footerGreytext"><FormattedMessage id="footer.condo" /></a></div>
              </div>
              <div className="col-sm-2 footerGreytext">
                <div><a href="https://realestate.cogir.net/services/" target="blank" className="footerGreytext"><FormattedMessage id="footer.expertise" /></a></div>
                <div><a href="https://realestate.cogir.net/news/" target="blank" className="footerGreytext"><FormattedMessage id="footer.news" /></a></div>
              </div>
              <div className="col-sm-1">

              </div>
              <div className="col-sm-2">
                <div className="cogirrealestatetext"><FormattedMessage id="footer.keepInTouch" /></div>
                <div className="mt-4">
                  <a href="https://www.facebook.com/CogirImmobilierRealEstate/" target="blank"><i className="fab fa-facebook-f fa-lg text-success pr-4"></i></a>
                  <a href="https://www.instagram.com/cogir_immobilier/" target="blank"><i className="fab fa-instagram fa-lg text-success pr-4"></i></a>
                  <a href="https://www.linkedin.com/company/soci-t-de-gestion-cogir" target="blank"><i className="fab fa-linkedin-in fa-lg text-success pr-4"></i></a>
                  <a href="https://www.youtube.com/user/sgCogir" target="blank"><i className="fab fa-youtube text-success"></i></a>
                </div>
              </div>
              <div className="whiteLine mt-4"></div>
              <div className="row mt-4">
                <div className="col-sm-2">
                  <a href="https://www.cogir.net/" target="blank"><img className="img-fluid" src={lg === "fr" ? fCogirReaEstateFr : fCogirReaEstate } alt="Logo Cogir real estate" /></a>
                </div>
                <div className="col-sm-2">
                  <a href="https://residencescogir.com/" target="blank"><img className="img-fluid" src={lg === "fr" ? fCogirResidencesFr : fCogirResidences} alt="Logo Cogir résidences" /></a>
                </div>
                <div className="col-sm-2">
                  <a href="https://www.cogirseniorliving.ca/" target="blank"><img className="img-fluid" src={fCogirSeniorLivingCa} alt="Logo Cogir senior living Canada" /></a>
                </div>
                <div className="col-sm-2">
                  <a href="https://cogirusa.com/" target="blank"><img className="img-fluid" src={fCogirSeniorLivingUs} alt="Logo Cogir senior living Usa" /></a>
                </div>
                <div className="col-sm-2">
                  <a href="https://immobilier.cogir.net/la-fondation-cogir/" target="blank"><img className="img-fluid" src={lg === "fr" ? fCogirFoundationFr : fCogirFoundation} alt="Logo Cogir restaurant" /></a>
                </div>
                <div className="col-sm-2">
                  <a href="https://immobilier.cogir.net/restaurants/" target="blank"><img className="img-fluid" src={fCogirRestaurant} alt="Logo Cogir foundation" /></a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <footer className="footer bg-dark pb-3 d-block d-md-none">
          <div className="container">
            <div className="row mt-2 text-center">
              <div className="col-sm-2">
                <img className="img-fluid" src={lg === "fr" ? logoFr : logoEn} alt="Logo Cogir Blanc Vert" />
              </div>
              <div className="col-sm-3">
                {/*<div className="cogirrealestatetext"><FormattedMessage id="footer.company" /></div>
                <div className="text-white mt-2"><FormattedMessage id="footer.phoneNumber" /></div>
                <div className="emailtext"><FormattedMessage id="footer.email" /></div>*/}
              </div>
              <div className="col-sm-2 mt-2">
                <div><a href="https://realestate.cogir.net/retirement-homes/" target="blank" className="footerGreytext"><FormattedMessage id="footer.retirementHome" /></a></div>
                <div><a href="https://realestate.cogir.net/restaurants/" target="blank" className="footerGreytext"><FormattedMessage id="footer.restaurant" /></a></div>
                <div><a href="https://realestate.cogir.net/condos-for-sale/" target="blank" className="footerGreytext"><FormattedMessage id="footer.condo" /></a></div>
              </div>
              <div className="col-sm-2 footerGreytext">
                <div><a href="https://realestate.cogir.net/services/" target="blank" className="footerGreytext"><FormattedMessage id="footer.expertise" /></a></div>
                <div><a href="https://realestate.cogir.net/news/" target="blank" className="footerGreytext"><FormattedMessage id="footer.news" /></a></div>
              </div>
              <div className="col-sm-1">

              </div>
              <div className="col-sm-2">
                <div className="cogirrealestatetext"><FormattedMessage id="footer.keepInTouch" /></div>
                <div className="mt-4">
                  <a href="https://www.facebook.com/CogirImmobilierRealEstate/" target="blank"><i className="fab fa-facebook-f fa-lg text-success pr-4"></i></a>
                  <a href="https://www.instagram.com/cogir_immobilier/" target="blank"><i className="fab fa-instagram fa-lg text-success pr-4"></i></a>
                  <a href="https://www.linkedin.com/company/soci-t-de-gestion-cogir" target="blank"><i className="fab fa-linkedin-in fa-lg text-success pr-4"></i></a>
                  <a href="https://www.youtube.com/user/sgCogir" target="blank"><i className="fab fa-youtube text-success"></i></a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

    );
  }
}

export default Footer;