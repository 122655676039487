import React from 'react';
import {FormattedMessage} from 'react-intl';
import TextInput from '../Controls/TextInput.js';
import EmailInput from '../Controls/EmailInput.js';
import NextPrevious from '../Controls/NextPrevious.js';
import SelectInput from '../Controls/SelectInput.js';
import JobApplyAlert from '../Controls/InfoMessage.js';
import provinces from "../translations/provinces.json";
import country from "../translations/country.json";
import {validateRequired, validateEmail, validatePhone, validatePostalCode} from '../Utils/Validate';
/* global google */

class Contact extends React.Component {
  constructor(props) {
    super(props);
    let dataObj = this.props.dataObj;
    let form1 = dataObj.fl1;//JSON.parse(sessionStorage.getItem('fl1'));
    let form = dataObj.fl2;//JSON.parse(sessionStorage.getItem('fl2'));

    if (form1) {
      var cell1 = form1.hasOwnProperty("cell1") ? form1.cell1 : "";
      var cell2 = form1.hasOwnProperty("cell2") ? form1.cell2 : "";
      var cell3 = form1.hasOwnProperty("cell3") ? form1.cell3 : "";
    }


    this.state = {
      fname: {value:(form1) ? form1.fname : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      lname: {value:(form1) ? form1.lname : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      email: {value:(form1) ? form1.email : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      phone1: {value:(form1) ? form1.phone1 : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      phone2: {value:(form1) ? form1.phone2 : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      phone3: {value:(form1) ? form1.phone3 : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      cell1: {value:(form1) ? cell1 : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      cell2: {value:(form1) ? cell2 : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},
      cell3: {value:(form1) ? cell3 : '', validate:(form1 ) ? true : false, touch:(form1 ) ? true : false},

      street_number: {value:(form ) ? form.address : '', validate:(form ) ? true : false, touch:(form ) ? true : false},
      route: {value:(form ) ? form.route : '', validate:(form ) ? true : false, touch:(form ) ? true : false},
      locality: {value:(form ) ? form.city : '', validate:(form ) ? true : false, touch:(form ) ? true : false},
      administrative_area_level_1: {value:(form ) ? form.province : '', validate:(form ) ? true : false, touch:(form ) ? true : false},
      country: {value:(form ) ? form.country : '', validate:(form ) ? true : false, touch:(form ) ? true : false},
      postal_code: {value:(form ) ? form.postalCode : '', validate:(form ) ? true : false, touch:(form ) ? true : false},
      additional: {value:(form ) ? form.additional : ''},
      jobApply: dataObj.appliedTo,//JSON.parse(sessionStorage.getItem('appliedTo')),
      myCoord: []
    };

    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {
    let top = document.getElementById("header").offsetTop;
    window.scrollTo(0, top);

    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current);
    this.autocomplete.setComponentRestrictions({'country': ['ca']});
    this.autocomplete.setFields(['address_components']);
    this.autocomplete.setTypes(['address']);
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  getCoord = (geocoder, list) => {
    let arr = [];
    for (let j = 0; j < list.length; j++) {
      geocoder.geocode({'address': list[j].address}, function(results, status) {
          let obj = {};

          obj.name = list[j].name;
          obj.address = list[j].address;
          obj.coord = JSON.parse(JSON.stringify(results[0].geometry.location));

          arr.push(JSON.stringify(obj));
      });
    }
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();

    let componentForm = {
      street_number: 'long_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'long_name'
    };

    let stateValue = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: ''
    }

    for (var i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = place.address_components[i][componentForm[addressType]];
        stateValue[addressType] = val;
      }
    }

    this.setState(prevState => ({ street_number: {...prevState.street_number, value: stateValue["street_number"]}}), this.validateInput("street_number", stateValue["street_number"]));
    this.setState(prevState => ({ route: {...prevState.route, value: stateValue["route"]}}), this.validateInput("route", stateValue["route"]));
    this.setState(prevState => ({ locality: {...prevState.locality, value: stateValue["locality"]}}), this.validateInput("city", stateValue["locality"]));
    this.setState(prevState => ({ administrative_area_level_1: {...prevState.administrative_area_level_1, value: stateValue["administrative_area_level_1"]}}), this.validateInput("province", stateValue["administrative_area_level_1"]));
    this.setState(prevState => ({ country: {...prevState.country, value: stateValue["country"]}}), this.validateInput("country", stateValue["country"]));
    this.setState(prevState => ({ postal_code: {...prevState.postal_code, value: stateValue["postal_code"]}}), this.validateInput("postalCode", stateValue["postal_code"]));
  }

  handleChange(event) {

    const value = event.target.value;
    const name = event.target.name;

    switch(name) {
      case "fname":
        this.setState(prevState => ({ fname: {...prevState.fname, value: value}}), this.validateInput(name, value));
        break;
      case "lname":
        this.setState(prevState => ({ lname: {...prevState.lname, value: value}}), this.validateInput(name, value));
        break;
      case "email":
        this.setState(prevState => ({ email: {...prevState.email, value: value}}), this.validateInput(name, value));
        break;
      case "phone1":
        this.setState(prevState => ({ phone1: {...prevState.phone1, value: value}}), this.validateInput(name, value));
        break;
      case "phone2":
        this.setState(prevState => ({ phone2: {...prevState.phone2, value: value}}), this.validateInput(name, value));
        break;
      case "phone3":
        this.setState(prevState => ({ phone3: {...prevState.phone3, value: value}}), this.validateInput(name, value));
        break;
      case "cell1":
        this.setState(prevState => ({ cell1: {...prevState.cell1, value: value}}), this.validateInput(name, value));
        break;
      case "cell2":
        this.setState(prevState => ({ cell2: {...prevState.cell2, value: value}}), this.validateInput(name, value));
        break;
      case "cell3":
        this.setState(prevState => ({ cell3: {...prevState.cell3, value: value}}), this.validateInput(name, value));
        break;
        case "street_number":
          this.setState(prevState => ({ street_number: {...prevState.street_number, value: value}}), this.validateInput(name, value));
          break;
        case "route":
          this.setState(prevState => ({ route: {...prevState.route, value: value}}), this.validateInput(name, value));
          break;
        case "city":
          this.setState(prevState => ({ locality: {...prevState.locality, value: value}}), this.validateInput(name, value));
          break;
        case "province":
          this.setState(prevState => ({ administrative_area_level_1: {...prevState.administrative_area_level_1, value: value}}), this.validateInput(name, value));
          break;
        case "country":
          this.setState(prevState => ({ country: {...prevState.country, value: value}}), this.validateInput(name, value));
          break;
        case "postalCode":
          this.setState(prevState => ({ postal_code: {...prevState.postal_code, value: value}}), this.validateInput(name, value));
          break;
        case "additional":
          this.setState(prevState => ({ additional: {...prevState.additional, value: value}}));
          break;
      default:
        // code block
    }
  }

  validateInput = (name, value) => {

    switch(name) {
      case "fname":
        this.setState(prevState => ({ fname: {...prevState.fname, touch: true}}));
        validateRequired(value) ? this.setState(prevState => ({ fname: {...prevState.fname, validate: true}})) : this.setState(prevState => ({ fname: {...prevState.fname, validate: false}}));
        break;
      case "lname":
        this.setState(prevState => ({ lname: {...prevState.lname, touch: true}}));
        validateRequired(value) ? this.setState(prevState => ({ lname: {...prevState.lname, validate: true}})) : this.setState(prevState => ({ lname: {...prevState.lname, validate: false}}));
        break;
      case "email":
        this.setState(prevState => ({ email: {...prevState.email, touch: true}}));
        validateEmail(value) ? this.setState(prevState => ({ email: {...prevState.email, validate: true}})) : this.setState(prevState => ({ email: {...prevState.email, validate: false}}));
        break;
      case "phone1":
          this.setState(prevState => ({ phone1: {...prevState.phone1, touch: true}}));
          //validatePhone(value, 3) ? this.setState(prevState => ({ phone1: {...prevState.phone1, validate: true}})) : this.setState(prevState => ({ phone1: {...prevState.phone1, validate: false}}));
          if(validatePhone(value, 3)) {
            this.setState(prevState => ({ phone1: {...prevState.phone1, validate: true}}));
            document.getElementById("phone2").focus();
          }
          else {
            this.setState(prevState => ({ phone1: {...prevState.phone1, validate: false}}));
          }
          break;
      case "phone2":
          this.setState(prevState => ({ phone2: {...prevState.phone2, touch: true}}));
          //validatePhone(value, 3) ? this.setState(prevState => ({ phone2: {...prevState.phone2, validate: true}})) : this.setState(prevState => ({ phone2: {...prevState.phone2, validate: false}}));
          if(validatePhone(value, 3)) {
            this.setState(prevState => ({ phone2: {...prevState.phone2, validate: true}}));
            document.getElementById("phone3").focus();
          }
          else {
            this.setState(prevState => ({ phone2: {...prevState.phone2, validate: false}}));
          }
          break;
      case "phone3":
          this.setState(prevState => ({ phone3: {...prevState.phone3, touch: true}}));
          validatePhone(value, 4) ? this.setState(prevState => ({ phone3: {...prevState.phone3, validate: true}})) : this.setState(prevState => ({ phone3: {...prevState.phone3, validate: false}}));
          break;
      case "cell1":
            this.setState(prevState => ({ cell1: {...prevState.cell1, touch: true}}));
            //validatePhone(value, 3) ? this.setState(prevState => ({ cell1: {...prevState.cell1, validate: true}})) : this.setState(prevState => ({ cell1: {...prevState.cell1, validate: false}}));
            if(validatePhone(value, 3)) {
              this.setState(prevState => ({ cell1: {...prevState.cell1, validate: true}}));
              document.getElementById("cell2").focus();
            }
            else {
              this.setState(prevState => ({ cell1: {...prevState.cell1, validate: false}}));
            }

          break;
      case "cell2":
            this.setState(prevState => ({ cell2: {...prevState.cell2, touch: true}}));
            //validatePhone(value, 3) ? this.setState(prevState => ({ cell2: {...prevState.cell2, validate: true}})) : this.setState(prevState => ({ cell2: {...prevState.cell2, validate: false}}));
            if(validatePhone(value, 3)) {
              this.setState(prevState => ({ cell2: {...prevState.cell2, validate: true}}));
              document.getElementById("cell3").focus();
            }
            else {
              this.setState(prevState => ({ cell2: {...prevState.cell2, validate: false}}));
            }

          break;
      case "cell3":
          this.setState(prevState => ({ cell3: {...prevState.cell3, touch: true}}));
          validatePhone(value, 4) ? this.setState(prevState => ({ cell3: {...prevState.cell3, validate: true}})) : this.setState(prevState => ({ cell3: {...prevState.cell3, validate: false}}));
          break;
      case "street_number":
          this.setState(prevState => ({ street_number: {...prevState.street_number, touch: true}}));
          validateRequired(value) ? this.setState(prevState => ({ street_number: {...prevState.street_number, validate: true}})) :
          this.setState(prevState => ({ street_number: {...prevState.street_number, validate: false}}));
          break;
      case "route":
          this.setState(prevState => ({ route: {...prevState.route, touch: true}}));
          validateRequired(value) ? this.setState(prevState => ({ route: {...prevState.route, validate: true}})) :
          this.setState(prevState => ({ route: {...prevState.route, validate: false}}));
          break;
      case "city":
          this.setState(prevState => ({ locality: {...prevState.locality, touch: true}}));
          validateRequired(value) ? this.setState(prevState => ({ locality: {...prevState.locality, validate: true}})) :
          this.setState(prevState => ({ locality: {...prevState.locality, validate: false}}));
          break;
      case "province":
          this.setState(prevState => ({ administrative_area_level_1: {...prevState.administrative_area_level_1, touch: true}}));
          validateRequired(value) ? this.setState(prevState => ({ administrative_area_level_1: {...prevState.administrative_area_level_1, validate: true}})) :
          this.setState(prevState => ({ administrative_area_level_1: {...prevState.administrative_area_level_1, validate: false}}));
          break;
      case "country":
          this.setState(prevState => ({ country: {...prevState.country, touch: true}}));
          validateRequired(value) ? this.setState(prevState => ({ country: {...prevState.country, validate: true}})) :
          this.setState(prevState => ({ country: {...prevState.country, validate: false}}));
          break;
      case "postalCode":
          this.setState(prevState => ({ postal_code: {...prevState.postal_code, touch: true}}));
          validatePostalCode(value) ? this.setState(prevState => ({ postal_code: {...prevState.postal_code, validate: true}})) :
          this.setState(prevState => ({ postal_code: {...prevState.postal_code, validate: false}}));
          break;
      default:
    }
  }

  validateCell = (c1, c2, c3) => {
    let v = true;

    if (c1.validate && c2.validate && c3.validate) { v = true; }
    else { v = false; }

    if (c1.value.length === 0 && c2.value.length === 0 && c3.value.length === 0) { v = true; }
    if (c1.value.length > 0 && c2.value.length > 0 && c3.value.length === 0) { v = false; }
    if (c1.value.length > 0 && c2.value.length === 0 && c3.value.length > 0) { v = false; }
    if (c1.value.length === 0 && c2.value.length > 0 && c3.value.length > 0) { v = false; }
    if (c1.value.length === 0 && c2.value.length === 0 && c3.value.length > 0) { v = false; }
    if (c1.value.length === 0 && c2.value.length > 0 && c3.value.length === 0) { v = false; }
    if (c1.value.length > 0 && c2.value.length === 0 && c3.value.length === 0) { v = false; }

    return v;
  }

  handleSubmit(event) {

    const { fname, lname, email, phone1, phone2, phone3, cell1, cell2, cell3} = this.state;

    let contactdata = {"fname":fname.value, "lname":lname.value, "email": email.value,
    "phone1" : phone1.value,  "phone2" : phone2.value, "phone3" : phone3.value,
    "cell1" : cell1.value, "cell2" : cell2.value, "cell3" : cell3.value};

    let cellVal = this.validateCell(cell1, cell2, cell3);
    let contact = false;

    if(fname.validate && lname.validate && email.validate && phone1.validate && phone2.validate && phone3.validate && cellVal) {
      contact = true
    }

    if (!fname.validate){
        this.setState(prevState => ({ fname: {...prevState.fname, touch: true}}));
    }
    if (!lname.validate){
        this.setState(prevState => ({ lname: {...prevState.lname, touch: true}}));
    }
    if (!email.validate){
        this.setState(prevState => ({ email: {...prevState.email, touch: true}}));
    }
    if (!phone1.validate){
        this.setState(prevState => ({ phone1: {...prevState.phone1, touch: true}}));
    }
    if (!phone2.validate){
        this.setState(prevState => ({ phone2: {...prevState.phone2, touch: true}}));
    }
    if (!phone3.validate){
        this.setState(prevState => ({ phone3: {...prevState.phone3, touch: true}}));
    }

    /*Address*/

    const { street_number, route, locality, administrative_area_level_1, country, postal_code, additional} = this.state;

    let addressdata = {"address":street_number.value, "route": route.value, "city":locality.value, "province": administrative_area_level_1.value,
    "postalCode" : postal_code.value, "country":country.value, "additional" : additional.value};


    let address = false;

    if(street_number.validate && route.validate && locality.validate && administrative_area_level_1.validate && postal_code.validate && country.validate) {
      address = true;
    }

    if (!street_number.validate){
        this.setState(prevState => ({ street_number: {...prevState.street_number, touch: true}}));
    }
    if (!route.validate){
        this.setState(prevState => ({ route: {...prevState.route, touch: true}}));
    }
    if (!locality.validate){
        this.setState(prevState => ({ locality: {...prevState.locality, touch: true}}));
    }
    if (!administrative_area_level_1.validate){
        this.setState(prevState => ({ administrative_area_level_1: {...prevState.administrative_area_level_1, touch: true}}));
    }
    if (!country.validate){
        this.setState(prevState => ({ country: {...prevState.country, touch: true}}));
    }
    if (!postal_code.validate){
        this.setState(prevState => ({ postal_code: {...prevState.postal_code, touch: true}}));
    }

    if(contact && address) {
      let dataObj = this.props.dataObj;
      let step = {"step" : "fl3"};
      dataObj.fl1 = contactdata;
      dataObj.fl2 = addressdata;
      //sessionStorage.setItem('fl1', JSON.stringify(contactdata));
      //sessionStorage.setItem('fl2', JSON.stringify(addressdata));
      step.dataObj = dataObj;
      this.props.nextForm(step);
    }

    event.preventDefault();
  }

  goToNextForm = (value) => {

    let dataObj = this.props.dataObj;
    let step = {"step" : value};

    if(value === "fc1") {
      step = {"step" : value, "fromUpload" : true};
    }

    step.dataObj = dataObj;
    
    this.props.nextForm(step);
  }

  render() {

    const lg = this.props.language;
    const {jobApply} = this.state;
    const applied = lg === "fr" ? jobApply.fr : jobApply.en;
    const placeholder = lg === "fr" ? "Cherchez votre adresse" : "Search your address";

    return (
      <div>
        <div className="container mb-4">
          <JobApplyAlert gotToPrev={() => this.goToNextForm("Search")} jobApply={applied.Title} type={jobApply.type} />
          <form ref={this.form} onSubmit={this.handleSubmit} noValidate>
              <div className="row">
                  <div className="col-sm-8">
                    <h4><FormattedMessage id="app.contact"/></h4>
                    <hr />
                  </div>
              </div>
              <div className="row">
                <div className="col-sm-2 ">
                <FormattedMessage id="app.fname" /><span className="text-danger ml-2">*</span>
                </div>
                <div className="col-sm-6">
                <TextInput name="fname" value={this.state.fname.value} onChange={this.handleChange} maxLength="75" />
                <p><span className="text-danger">{(this.state.fname.touch && !this.state.fname.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">
                <FormattedMessage id="app.lname" /><span className="text-danger ml-2">*</span>
                </div>
                <div className="col-sm-6">
                <TextInput name="lname" value={this.state.lname.value} onChange={this.handleChange} maxLength="75" />
                <p><span className="text-danger">{(this.state.lname.touch && !this.state.lname.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">
                <FormattedMessage id="app.email" /><span className="text-danger ml-2">*</span>
                </div>
                <div className="col-sm-6">
                <EmailInput name="email" value={this.state.email.value} onChange={this.handleChange} maxLength="75" disabled />
                <p><span className="text-danger">{(this.state.email.touch && !this.state.email.validate) ? <FormattedMessage id="error.email"/> : ""}</span></p>
                </div>
              </div>
              {/* Phone */}
              <div className="row">
              <div className="col-sm-2">
              <FormattedMessage id="app.phone"/><span className="text-danger ml-2">*</span>
              </div>
                <div className="col-4 col-sm-2">
                <TextInput id="phone1" name="phone1" value={this.state.phone1.value} onChange={this.handleChange} maxLength="3"/>
                <p><span className="text-danger">{(this.state.phone1.touch && !this.state.phone1.validate) ? <FormattedMessage id="error.phone1"/> : ""}</span></p>
                </div>
                <div className="col-4 col-sm-2">
                <TextInput id="phone2" name="phone2" value={this.state.phone2.value} onChange={this.handleChange} maxLength="3"/>
                <p><span className="text-danger">{(this.state.phone2.touch && !this.state.phone2.validate) ? <FormattedMessage id="error.phone1"/> : ""}</span></p>
                </div>
                <div className="col-4 col-sm-2">
                <TextInput id="phone3" name="phone3" value={this.state.phone3.value} onChange={this.handleChange} maxLength="4"/>
                <p><span className="text-danger">{(this.state.phone3.touch && !this.state.phone3.validate) ? <FormattedMessage id="error.phone2"/> : ""}</span></p>
                </div>
              </div>

              {/* Cellphone */}
              <div className="row">
                <div className="col-sm-2">
                <FormattedMessage id="app.phone"/>
                </div>
                  <div className="col-4 col-sm-2">
                  <TextInput id="cell1" name="cell1" value={this.state.cell1.value} onChange={this.handleChange} maxLength="3"/>
                  <p><span className="text-danger">{(this.state.cell1.touch && !this.state.cell1.validate) ? <FormattedMessage id="error.phone1"/> : ""}</span></p>
                  </div>
                  <div className="col-4 col-sm-2">
                  <TextInput id="cell2" name="cell2" value={this.state.cell2.value} onChange={this.handleChange} maxLength="3"/>
                  <p><span className="text-danger">{(this.state.cell2.touch && !this.state.cell2.validate) ? <FormattedMessage id="error.phone1"/> : ""}</span></p>
                  </div>
                  <div className="col-4 col-sm-2">
                  <TextInput id="cell3" name="cell3" value={this.state.cell3.value} onChange={this.handleChange} maxLength="4"/>
                  <p><span className="text-danger">{(this.state.cell3.touch && !this.state.cell3.validate) ? <FormattedMessage id="error.phone2"/> : ""}</span></p>
                  </div>
              </div>
              <div className="row">
                  <div className="col-sm-8">
              <hr />
              </div>
            </div>

            {/*Address*/}

            <div className="form-row">
                <div className="form-group col-md-8">
                <h4><FormattedMessage id="progress.address"/></h4>
                    <hr />
                    <input ref={this.autocompleteInput}  id="autocomplete" className="form-control" placeholder={placeholder} type="text"></input>
                    <hr />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                    <FormattedMessage id="app.civ">
                    { plc=> <TextInput name="street_number" value={this.state.street_number.value} placeholder={plc} onChange={this.handleChange}/> }
                    </FormattedMessage>
                    <p><span className="text-danger">{(this.state.street_number.touch && !this.state.street_number.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                    </div>
                    <div className="form-group col-md-4">
                    <FormattedMessage id="app.street">
                    { plc=> <TextInput name="route" value={this.state.route.value} placeholder={plc} onChange={this.handleChange}/> }
                    </FormattedMessage>
                    <p><span className="text-danger">{(this.state.route.touch && !this.state.route.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                </div>
            </div>
            <div className="form-row">
                  <div className="form-group col-md-4">
                  <FormattedMessage id="app.city">
                  { plc=> <TextInput name="city" value={this.state.locality.value} placeholder={plc} onChange={this.handleChange}/> }
                  </FormattedMessage>
                  <p><span className="text-danger">{(this.state.locality.touch && !this.state.locality.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                  <div className="form-group col-md-4">
                  <FormattedMessage id="app.zip">
                  { plc=> <TextInput name="postalCode" value={this.state.postal_code.value} placeholder={plc} onChange={this.handleChange} /> }
                  </FormattedMessage>
                  <p><span className="text-danger">{(this.state.postal_code.touch && !this.state.postal_code.validate) ? <FormattedMessage id="error.zip"/> : ""}</span></p>
                  </div>
            </div>
            <div className="form-row">
                  <div className="form-group col-md-4">
                  <SelectInput name="province" value={this.state.administrative_area_level_1.value} placeholder="Province" options={this.props.language === "fr" ? provinces.fr : provinces.en} method={this.handleChange} />
                  <p><span className="text-danger">{(this.state.administrative_area_level_1.touch && !this.state.administrative_area_level_1.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
                  <div className="form-group col-md-4">
                  <SelectInput name="country" value={this.state.country.value} placeholder="Country" options={this.props.language === "fr" ? country.fr : country.en} method={this.handleChange} />
                  <p><span className="text-danger">{(this.state.country.touch && !this.state.country.validate) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                  </div>
            </div>
            <div className="form-row">
                  <div className="form-group col-md-4">
                  <FormattedMessage id="app.additional">
                  { plc=> <TextInput name="additional" value={this.state.additional.value} placeholder={plc} onChange={this.handleChange} /> }
                  </FormattedMessage>
                  </div>
            </div>
            <div className="row">
            <div className="col-md-8">
            <hr/>
            </div>
            </div>
            <NextPrevious gotToPrev={() => this.goToNextForm("Search")} goToShort={() => this.goToNextForm("fc1")} long="0" />
        </form>
        </div>
      </div>
    );
  }
}

export default Contact;
