/* Search functionality */

import moment from 'moment';

export function changeLanguageInControl(lg) {
  const filters = document.querySelectorAll("div.rt-th > input");
    if (filters.length > 1) {
      filters[0].placeholder = lg === "fr" ? "Chercher un poste" : "Search a job title";
      filters[1].placeholder = lg === "fr" ? "Chercher une catégorie" : "Search a category";
      filters[2].placeholder = lg === "fr" ? "Chercher une région" : "Search a job region";
      filters[3].placeholder = lg === "fr" ? "Chercher par date" : "Search by date";
    }
}

export function adjustListOnLgSwitch(lg, selectedOption, list ) {
  //if (lg !== prevProps) {
    if(selectedOption) {
      //let obj = {action: ""}
      let rgList = lg === "fr" ? list.fr : list.en;
      let b;
      let a = [...selectedOption];

      for (let j = 0; j < a.length; j++) {
        b = rgList.filter(x => x.value === a[j].value);
      }

      return b;

    }
  //}
}

export function getTableList(lg, values, listComplete, cat) {

  let globalList = []; //commun
  const indlg = lg === "fr" ? 0 : 1; //commun

  if(cat === "job") {
    for (let j = 0; j < values.length; j++) {
      //jobsList.push({title: values[j].label, idTitle: -1});
      let jobTitleListCategory = listComplete[indlg].filter(x => x.category === values[j].label);
      let jobTitleListOther = listComplete[indlg].filter(x => x.otherCategory === values[j].label);
      var jobTitleList = jobTitleListCategory.concat(jobTitleListOther);
      globalList.push(jobTitleList);
    }
  }

  if(cat === "region") {
    const textRegions = lg === "fr" ? " (Toutes les municipalités)" : " (All cities)";
    for (let j = 0; j < values.length; j++) {
      let obj = {region: values[j].label, city: values[j].label+textRegions, id:"0_"+values[j].value}
      globalList.push(obj);
      let cityList = listComplete[indlg].filter(x => x.rid === values[j].value);
      globalList.push(cityList);
    }
  }

  let globalListFlat = globalList.flat();
  let unique = globalListFlat.filter((v, i, a) => a.indexOf(v) === i);

  return unique;

}

export function removeSelectedValues(rowData, sel, cat) {
  let a = [...rowData];
  let o = [];

  if(cat === "job") {
    let t = a.filter(x => x._original.category !== sel);
    o = t.filter(x => x._original.otherCategory !== sel);
  }

  if(cat === "region") {
    //let a = [...this.state.rowData];
    o = a.filter(x => x._original.region !== sel);
  }

  return o;
}

export function setDataSelected(rowData, rowSelected, cat) {

  let ind = "";
  let a = [];

  if(cat === "job") {
    ind = rowData.findIndex(x => x._original.idTitle === rowSelected._original.idTitle);
  }

  if(cat === "region") {
    ind = rowData.findIndex(x => x._original.id === rowSelected._original.id);
  }

  if(cat === "search") {
    ind = rowData.findIndex(x => x._index === rowSelected._index);
  }


  if (ind === -1) {
    a = rowData.concat(rowSelected);
  }

  else {
    a = [...rowData];
    a.splice(ind, 1);
  }

  return a;
}

export function formatVolet(data) {
  let a = [];
  for (let j = 0; j < data.length; j++) {
    let obj = {};
    let ind =  data[j].Volet.indexOf("/");
    obj.id = data[j].VId;
    obj.fr = data[j].Volet.slice(0, ind);
    obj.en = data[j].Volet.slice(ind + 1);
    a.push(obj);
  }

  return a;
}

export function formatSearchTable(alldata, alljobs, allregions, volet) {
  let obj = {};

  obj.fr = formatSearchTableByLanguage(alldata.fr, allregions.fr, alljobs.fr, volet);//lg === "fr" ? alldata.fr : alldata.en;
  obj.en = formatSearchTableByLanguage(alldata.en, allregions.en, alljobs.en, volet);

  if(obj.fr.length !== obj.en.length) {
    filterJob(obj.fr, obj.en);
  }

  return obj;
}

function filterJob(jobFr, jobEn) {

  for (let i = 0; i < jobFr.length; i++) {
    var found = jobEn.find(function(element) {
      return element.Id === jobFr[i].Id;
    });

    if (!found) {jobEn.push(jobFr[i]);};
  }

}

function formatSearchTableByLanguage(data, regions, jobs, volet) {
  for (let j = 0; j < data.length; j++) {
    let reg = regions.find(r => parseInt(r.value) === data[j].Region);
    let cat = jobs.find(r => parseInt(r.value) === data[j].Category);
    data[j].Region = reg.label;
    data[j].Category = cat.label;
    data[j].JobDuties = formatJobDuties(data[j].JobDuties, volet);
    data[j].Benefits = formatBenefits(data[j].Benefits);// data[j].Benefits.split("|"); formatBenefits(data[j].Benefits);
    data[j].PerfectCandidate = data[j].PerfectCandidate.split("|");
    data[j].Qualifications = data[j].Qualifications.split("|");
    data[j].DateOpen = moment(data[j].DateOpen).format('YYYY-MM-DD');
    data[j].SpokenLanguage = formatLanguageData(data[j].SpokenLanguage);
    data[j].WrittenLanguage = formatLanguageData(data[j].WrittenLanguage);
  }

  return data;
}

function formatLanguageData(data) {
  let format = {data : data}
  if(data) {
    let lgformat = data.split("|");
    let lvformatfr = lgformat[0].split(";");
    let lvformaten = lgformat[1].split(";");

    format.lvformatfr = lvformatfr;
    format.lvformaten = lvformaten;

    let secondStringfr = switchLgLevel(lvformaten[1], "fr", "anglais") !== "" ? ", " + switchLgLevel(lvformaten[1], "fr", "anglais") : "";
    let secondStringen = switchLgLevel(lvformatfr[1], "en", "french") !== "" ? ", " + switchLgLevel(lvformatfr[1], "en", "french") : "";

    format.lvfr = switchLgLevel(lvformatfr[1], "fr", "français") + secondStringfr;
    format.lven = switchLgLevel(lvformaten[1], "en", "english") + secondStringen;
  }
  return format;
}

function switchLgLevel(level, lg, lang) {
  let txt = "";
    switch(level) {
    case "0":
      txt = "";
      break;
    case "1":
      txt = lg === "fr" ? lang +" de base" : "basic "+lang;
      break;
    case "2":
      txt = lg === "fr" ? lang +" intermédiaire" : "intermediary "+lang;
      break;
    case "3":
      txt = lg === "fr" ? lang +" avancé" : "advanced "+lang;
      break;
      default:
      txt = "";
  }
  return txt;
}

export function formatBenefits(data) {
  let arrFin = [];
  let arrBen = [];
  let arr = data.split("^^^");
  for (let j = 0; j < arr.length; j++) {
    let cat = arr[j];
    let arrInd = cat.split("|");
    arrBen.push(arrInd);
  }

  let arrFlat = arrBen.flat();

  for (let i = 0; i < arrFlat.length; i++) {
    let obj = {};
    let ind = arrFlat[i].indexOf(";");

    if(ind !== -1) {
      obj.ben = arrFlat[i].slice(0, ind);
      obj.subBen = arrFlat[i].slice(ind + 1).split(",");
      //let sub = arrFlat[i].slice(ind + 3);
    }

    else {
      obj.ben = arrFlat[i];
      obj.subBen = [];
    }

    arrFin.push(obj);
  }

  return arrFin;
}

export function formatStringWithSymbol(str, sym, lg) {
  let arr = str.split(sym);
  return lg === "fr" ? arr[0] : arr[1];
}

function formatJobDuties(data, jobDutiesCat) {
  let arrCat = [];
  let arrTextCat = [];
  let arr = data.split("|");
  for (let j = 0; j < arr.length; j++) {
    let obj = {};
    let ind = arr[j].indexOf("^");
    obj.txt = arr[j].slice(0, ind);
    let id = arr[j].slice(ind + 1);
    //let objdu = jobDutiesCat.find(j => j.id === parseInt(id));
    //obj.cat = objdu.fr;
    obj.cat = id;
    obj.id = j;
    arrCat.push(id);
    arrTextCat.push(obj);
  }

  const unique = [...new Set(arrCat)];
  let finalArr = [];

  for (let j = 0; j < unique.length; j++) {
    let obj = {};
    obj.cat = jobDutiesCat.find(z => z.id === parseInt(unique[j]));
    if(obj.cat === undefined) {
      obj.cat = jobDutiesCat.find(z => z.id === 1);
    }
    obj.values = arrTextCat.filter(x => x.cat === unique[j]);
    finalArr.push(obj);
  }

  return finalArr;
}

export function formatList(list, type) {
  let fr = [];
  let en = [];
  let obj = {fr : fr, en : en};

  if(type === "class") {
    let objfr = {value:0, label: "Toutes les propriétés"}
    let objen = {value:0, label: "All properties"}

    obj.fr.push(objfr);
    obj.en.push(objen);
  }

  for (var j = 0; j < list.length; j++) {
    let objfr = {};
    let objen = {};

    let lbl = [];
    let val;

    if(type === "cit") {lbl = [list[j].City, list[j].City]; val=list[j].ID;} //list[j].Region.split("/");}
    else if(type === "reg") {lbl = list[j].Region.split("/"); val=list[j].ID;}
    else if(type === "job") {lbl = list[j].Job.split("/"); val=list[j].ID;}
    else if(type === "cat") {lbl = list[j].Category.split("/"); val=list[j].ID;}
    else if(type === "class") {lbl = list[j].ClassName.split("/"); val=list[j].ClassId;}
    else {lbl = [];}

    objfr.value = val;
    objfr.label = lbl[0];
    objen.value = val;
    objen.label = lbl[1];
    fr.push(objfr);
    en.push(objen);
  }

  return obj;
}

export function setJobApply(rowData) {
let obj = {fr:{}, en:{}};
let resFr = false;
let resEn = false;
let jobfr = [];
let joben = [];

let objList = ["Title","Id","DateOpen","DateClosed","Region"];

if (rowData.hasOwnProperty("fr") && rowData.hasOwnProperty("en")) {
  jobfr = rowData.fr;
  joben = rowData.en;
  if(jobfr[0] && joben[0]) {
    resFr = objList.every(validateOwnProperty, jobfr[0]);
    resEn = objList.every(validateOwnProperty, joben[0]);
  }
}

if (resFr && resEn) {
    obj.fr = {
      Title: jobfr[0].Title,
      JobId: jobfr[0].Id,
      StartDate: jobfr[0].DateOpen,
      EndDate: jobfr[0].DateClosed,
      Region: jobfr[0].Region
    }

    obj.en = {
      Title: joben[0].Title,
      JobId: joben[0].Id,
      StartDate: joben[0].DateOpen,
      EndDate: joben[0].DateClosed,
      Region: joben[0].Region
    }
  }

  else {
    obj.fr = {
      Title: jobfr[0].Title,
      JobId: 0,
      StartDate: moment().format('YYYY-MM-DD'),
      EndDate: moment().format('YYYY-MM-DD'),
      Region: "",
    }
    obj.en = {
      Title: joben[0].Title,
      JobId: 0,
      StartDate: moment().format('YYYY-MM-DD'),
      EndDate: moment().format('YYYY-MM-DD'),
      Region: "",
    }
    obj.type = rowData.type ? rowData.type : "";
  }
  //sessionStorage.setItem("appliedTo", JSON.stringify(obj));
  return obj;
}

function validateOwnProperty(ele) {
  return this.hasOwnProperty(ele);
}

export function formatBuildingsList(buildings, jobs) {
  let addList = [];
  for (let j = 0; j < buildings.length; j++) {
    let obj = {coord:{}};
    obj.id = buildings[j].Id;
    obj.city = buildings[j].City;
    obj.name = buildings[j].ResName;
    obj.address = buildings[j].Address;
    obj.coord.lng = parseFloat(buildings[j].Lon);
    obj.coord.lat = parseFloat(buildings[j].Lat);
    obj.jobsAvailable = jobs.filter(job => job.LocId === buildings[j].Id).length;
    obj.class = buildings[j].ClassName;
    obj.classId = buildings[j].Class;
    obj.region = buildings[j].Region;
    addList.push(obj);
  }

  return addList;
}

export function formatBuildingForSelect(list, jobs, onlyJobsAvailable, classe) {
  let classeformat = formatList(classe, "class");
  
  let build = getBuildingsForSelect(list, jobs, onlyJobsAvailable, classeformat.fr);
  let builden = getBuildingsForSelect(list, jobs, onlyJobsAvailable, classeformat.en);
  let obj = {fr: build, en:builden}

  return obj;
}

function getBuildingsForSelect(list, jobs, onlyJobsAvailable, classArray) {
  
  let build = [];
  let reg = [];

  for (var k = 0; k < list.length; k++) {
    reg.push(list[k].Region)
  }

  const regList = [...new Set(reg)];

  for (var j = 0; j < regList.length; j++) {
    let obj = {label:"", options:[]};
    obj.label = regList[j];

    for (let i = 0; i < list.length; i ++) {
      if (regList[j] === list[i].Region) {        
        //let jobsS = jobsA === 0 ? "" : " ( ".concat(jobsA, " ) ");
        let classObj = classArray.filter(cl => cl.value === list[i].Class);
        let classLabel = " ( ".concat(classObj[0].label, " ) ");
        if(onlyJobsAvailable) {
          let jobsA = jobs.filter(job => job.LocId === list[i].Id).length;
          //only jobs available
          if(jobsA > 0) {
            obj.options.push({value:list[i].Id, label:list[i].ResName+classLabel});
          }
        }
        else {
          obj.options.push({value:list[i].Id, label:list[i].ResName+classLabel});
        }
      }
    }
    build.push(obj);
  }

  return build;
}

export function getBuildingsForSelect1(list, lg) {

  let build = [];
  let reg = [];

  for (var k = 0; k < list.length; k++) {
    reg.push(list[k].region)
  }

  const regList = [...new Set(reg)];

  for (var j = 0; j < regList.length; j++) {
    let obj = {label:"", options:[]};
    obj.label = regList[j];

    for (let i = 0; i < list.length; i ++) {
      if (regList[j] === list[i].region) {
        let classArray = list[i].class.split("/");
        let classLabel = lg === "fr" ? " ( "+classArray[0]+" ) " : " ( "+classArray[1]+" ) ";
        obj.options.push({value:list[i].id, label:list[i].name+classLabel});
      }
    }
    build.push(obj);
  }

  return build;
}

/************/
