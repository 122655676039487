import React from 'react';
import {FormattedMessage} from 'react-intl';

const JobApplyAlert =  (props) => (

  <div className="alert alert-success col-sm-8 text-center" role="alert">
      { props.type !== "profil" &&
        <div><FormattedMessage id="app.postuledJob"/> : </div>
      }
      <h5><strong>{props.jobApply}</strong></h5>
      <button type="button" className="btn btn-link" onClick={props.gotToPrev}><FormattedMessage id="pst.NewSearch"/></button>
  </div>
)

export default JobApplyAlert;
