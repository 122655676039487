import React from 'react';
import {FormattedMessage} from 'react-intl';
import 'react-table/react-table.css';
//import creer from '../Images/building40x40.png';building3.png
import buildingIcon from '../Images/building3.png';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";

const InfoWindow = (props) =>
<InfoBox
position={new window.google.maps.LatLng(props.lat, props.lng)}
options={{ closeBoxURL: ``, enableEventPropagation: true }}
>
<div style={{ backgroundColor: `white`, opacity: 0.85, padding: `12px` }}>
  <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
  <button className="btn btn-link close" onClick={() => props.close()}>
  &times;
  </button>
    <strong>{props.build.name}</strong>
    <div><small>{ props.build.classId === 1 ? <FormattedMessage id="src.residencelabel"/> : props.build.class }</small></div>
    <hr/>
    {props.build.item.jobsAvailable > 0 ?
    <div>
      <div>{props.build.item.jobsAvailable} {props.build.item.jobsAvailable > 1 ? <FormattedMessage id="app.jobsAvailable"/> : <FormattedMessage id="app.jobAvailable"/> }</div>
      <button type="button" className="btn btn-sm btn-dark mt-2 mb-2" onClick={() => props.filterList(props.build.item)}><FormattedMessage id="app.filterlist"/></button>
    </div>
    :
    <div><FormattedMessage id="app.noJobsAvailable"/></div>
    }
    </div>
  </div>
</InfoBox>;

const MyMapComponent = withGoogleMap((props) =>
  <GoogleMap
    ref={props.myMap}
    zoom={props.zoom}
    center={props.center}
    defaultOptions={{
      streetViewControl: false,
      scaleControl: true,
      mapTypeControl: false,
      panControl: true,
      zoomControl: true,
      rotateControl: false,
      fullscreenControl: true
    }}
  >
  {props.showInfo && <InfoWindow lat={props.myLat} lng={props.mylng} build={props.myText} filterList={props.filterList} close={props.close}/>}
  {props.buildings.map((item, index) => {
     return (
      <Marker key={index} position={{ lat: item.coord.lat, lng: item.coord.lng }} icon={buildingIcon} ref={props.myRef[index]} title={item.name} onClick={() => props.onMarkerClick(item, index)}  />
    );
  }
)}
  </GoogleMap>
)

export default MyMapComponent;

/*const MyMapComponent = withScriptjs(withGoogleMap((props) =>*/
