import React from 'react';
import { FormattedMessage } from 'react-intl';
import banniere from '../Images/ATS-V2-banniere.jpg';

import emerite from '../Images/emerite.png';
import chateauBaieComeau from '../Images/chateau-baie-comeau.png';
import appyHere from '../Images/appy-here.png';

class homepagebanners extends React.Component {
  jumpToFilterZone = () => {
    let top = document.getElementById("dreamjoblabel").offsetTop;
    window.scrollTo(0, top);
  }

  render() {
    const dataObj = this.props.dataObj;
    const resId = dataObj.resid ? dataObj.resid : null;
    let homeBannerGeneric =
      <div className="bannierecontainer">
        <img className="img-fluid banniereimage" src={banniere} alt="banniere" />
        <div className="bannierecenteredtext">
          <div className="bannieretext"><FormattedMessage id="accWidget.bannieretext" /></div>
          <div className="bannieresoustext"><FormattedMessage id="accWidget.bannieresoustext" /></div>
        </div>
      </div>;
    let homeImage = homeBannerGeneric;
    if (resId) {
      homeImage =
        <div className='row'>
          <div className='col-md-8'>
            {homeBannerGeneric}
          </div>
          <div className='col-md-4 resIdBox'>
            <div className="row text-center m-2">
              <span className="appyHere"><FormattedMessage id="src.apply" /><strong><FormattedMessage id="src.fiveMin" /></strong>
                <FormattedMessage id="src.appyHere" /></span>
            </div>
            <div className="row text-center m-2 appyHereContainer">
              <img className="img-fluid" src={appyHere} alt="appyHere app" />
              <span className="clickLogo"><FormattedMessage id="src.clickLogo" /></span>
            </div>
            <div className="row text-center mt-2 mb-2">
              <div className='col-6'><a href="https://t.appyhere.com/cogir/brossard" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={emerite} alt="Emerite de Brossard logo" /></a></div>
              <div className='col-6'><a href="https://t.appyhere.com/cogir/baie-comeau" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={chateauBaieComeau} alt="Chateau Baie Comeau logo" /></a></div>
            </div>
          </div>
        </div>;
    }

    return (
      <div>
        <div className='row'>
          {/*Home banner images*/}
          {homeImage}
        </div>
        {/*Encadrés sous bannière*/}
        <div className="row">
          <div className="col-md-4 encadresousbanniere1">
            <p className='titreencadresousbanniere1 text-uppercase'><FormattedMessage id="accWidget.textencadresousbanniere1" /></p>
            <div className="shortwhiteline"></div>
            <p className='paragrapheencadresousbanniere1 mt-2'><FormattedMessage id="accWidget.sstextencadresousbanniere1" /></p>
          </div>
          <div className="col-md-8 encadresousbanniere2">
            <p className='titreencadresousbanniere2 text-uppercase'><FormattedMessage id="accWidget.titreencadresousbanniere2" /></p>
            <div className="shortgreenline"></div>
            <p className='paragrapheencadresousbanniere2 mt-2'><FormattedMessage id="accWidget.paragraphe1textencadresousbanniere2" /></p>
            <p className='paragrapheencadresousbanniere2'><FormattedMessage id="accWidget.paragraphe2textencadresousbanniere2" /></p>
            <p className='paragrapheencadresousbanniere2'><FormattedMessage id="accWidget.paragraphe3textencadresousbanniere2" /></p>
            <p className='paragraphe4encadresousbanniere2'><FormattedMessage id="accWidget.paragraphe4textencadresousbanniere2" /></p>
          </div>
        </div>
        <div className="row">
          <div className="col mt-4 text-center encadresousbanniere3">
            <p className='titreencadresousbanniere3 text-uppercase'><FormattedMessage id="accWidget.titreencadresousbanniere3" /></p>
            <p className='paragraphe1textencadresousbanniere3'><FormattedMessage id="accWidget.paragraphe1textencadresousbanniere3" /></p>
          </div>
        </div>
      </div>
    );
  }
}

export default homepagebanners;
