import React from 'react';
import {FormattedMessage} from 'react-intl';
import {setJobApply} from '../Utils/Search';

class MessageCenter extends React.Component {
  constructor(props) {
    super(props);
    let dataObj = this.props.dataObj;
    this.state = {
      appliedTo: dataObj.appliedTo ? dataObj.appliedTo : ""//JSON.parse(sessionStorage.getItem('appliedTo'))
    };

  }

  componentDidMount() {
    window.history.replaceState({}, document.title, "/");
  }

  goToPage = (value) => {
    let dataObj = this.props.dataObj;
    let step = {"step" : value};
    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  setSpontaneous = () => {
    //let noValidate = JSON.parse(sessionStorage.getItem('noValidate'));
    let spont = {fr:[{Title:"Candidature spontanée"}], en:[{Title:"Spontaneous application"}], type:"spontaneous"};
    let dataObj = this.props.dataObj;

    let objData = setJobApply(spont);
    dataObj.appliedTo = objData;

    let step = {"step" : "fc1"};
    step.dataObj = dataObj;

    this.props.nextForm(step);
  }

  render() {

    let value = this.props.data;

    let element;

    switch(value) {
      case "error":
        element = <div className="alert alert-danger" role="alert"><FormattedMessage id="error.generic"/></div>;
      break;
      case "profil":
        element =
        <div>
        <div className="alert alert-success" role="alert"><FormattedMessage id="app.noprofil"/></div>
        <div className="text-center"><button type="button" className="btn btn-success mb-2" onClick={() => this.goToPage("Search")}><FormattedMessage id="app.homereturn"/></button></div>
        <div className="text-center"><button type="button" className="btn btn-success mt-2" onClick={() => this.setSpontaneous()}><FormattedMessage id="app.spontaneous"/></button></div>
        </div>;
      break;
      case "unsubsc":
        element = <div className="alert alert-success" role="alert"><FormattedMessage id="app.unsubscribe"/></div>;
      break;
      case "ie":
      element = <div className="alert alert-success" role="alert">
      <h3><FormattedMessage id="app.iet"/></h3>
      <p><FormattedMessage id="app.iep"/></p>
      <p><FormattedMessage id="app.iealternative"/></p>
      <div className="text-center"><button type="button" className="btn btn-success mb-2" onClick={() => this.goToPage("Search")}><FormattedMessage id="app.homereturn"/></button></div>
      </div>;
      break;
      default:
        element = <div className="alert alert-danger" role="alert"><FormattedMessage id="error.generic"/></div>;
    }

    return (
      <div className="container text-center">
      {element}
      </div>
    );
  }
}

export default MessageCenter;
