import React from 'react';
import {FormattedMessage} from 'react-intl';
import ModalBootstrap from 'react-bootstrap/Modal';
import {validateEmail} from '../Utils/Validate';
import {setJobApply} from '../Utils/Search';
import axios from 'axios';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    let dataObj = this.props.dataObj;

    if (dataObj.reset) {
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.replace("/");
    }

    this.state = {
      errorFromServer: false,
      noValidate:  dataObj ? dataObj.noValidate : false,
      showModal: false,
      email: "",
      errormessage: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  validateEmail = () => {
    let lg = this.props.language;
    let email = this.state.email;

    const url = this.props.dataObj.env ? this.props.dataObj.env.urlJson : "http://scripts.local"; //Default devrait être prod.   

    if (validateEmail(email)) {
      axios.post(url, {
        request: "validate",
        data: email,
        lg: lg,
        appliedTo: this.props.prov
      },{ headers: {'Content-Type': 'application/json'}})
      .then((response)  => {
        this.handleClose();
        let obj = response.data;
        if (obj.exist) {
          this.goToEmailLooking(obj);
        }
        else {
          this.redirectTo(obj);
        }
      })
      .catch(()  => {       
        this.getErrorMessage();
      });
    }

    else {
      this.setState({errormessage: true});
    }

   
  }

  setProfil = () => {
    let prov = this.props.prov;
    let obj = {};
    let objData = {};
    let pass = false;
    let dataObj = this.props.dataObj;

    switch(prov) {
      case "profil":
        obj = {fr:[{Title:"Mise à jour du profil"}], en:[{Title:"Profil update"}], type:"profil"};
      break;
      case "spontaneous-link":
        obj = {fr:[{Title:"Candidature spontanée"}], en:[{Title:"Spontaneous application"}], type:"spontaneous"};
      break;
      case "spontaneous":
        obj = {fr:[{Title:"Candidature spontanée"}], en:[{Title:"Spontaneous application"}], type:"spontaneous"};
      break;
      case "jobApply":
        pass = true;
      break;
      default:
        obj = {fr:[{Title:"Candidature spontanée"}], en:[{Title:"Spontaneous application"}], type:"spontaneous"};
    }

    if (pass) {
      objData = dataObj.appliedTo;
    }
    else {
      objData = setJobApply(obj);
    }

    return objData;
  }

  goToEmailLooking(obj) {
    let dataObj = this.props.dataObj;
    let objData = this.setProfil();
    dataObj.appliedTo = objData;

    let step = {"step" : "EmailLooking"};

    if(obj.email) {
      //sessionStorage.setItem('fl1', JSON.stringify({"email": obj.email}));
      dataObj.fl1 = obj;
    }

    step.dataObj = dataObj;
    this.props.dataFrom(obj);
    this.props.nextForm(step);
  }

  redirectTo(obj) {
    let prov = this.props.prov;
    let dataObj = this.props.dataObj;

    let step = {"step" : "Search"};

    if (prov === "profil") {
      step = {"step" : "mess"};
      this.props.dataFrom("profil");
    }
    else {
      step = {"step" : "fc1"};
      //sessionStorage.setItem("noValidate", true);
      let objData = this.setProfil();
      dataObj.appliedTo = objData;
      dataObj.noValidate = true;
      //step.dataObj = this.setDataObj(objData);
    }

    if(obj.email) {
      //sessionStorage.setItem('fl1', JSON.stringify({"email": obj.email}));
      dataObj.fl1 = obj;
    }

    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  getErrorMessage() {
    let step = {"step" : "mess"};
    let dataObj = this.props.dataObj;
    step.dataObj = dataObj;
    this.props.dataFrom("error");
    this.props.nextForm(step);
  }

  handleChange(event) {
    this.setState({errormessage: false});
    const value = event.target.value;

    this.setState({email: value});
  }

  handleClose = () => {
    this.setState({showModal: false});
    this.setState({errormessage: false});
  }

  showModal = () => {
    this.setState({showModal: true});
  }

  render() {
    let prov = this.props.prov;
    let btn;
    switch (prov) {
      case "profil":
        btn = <button type="button" onClick={() => this.showModal() } className="btn btn-link text-success pl-0 pt-2"><FormattedMessage id="app.profil"/></button>;
      break;
      case "spontaneous-link":
        btn = <button type="button" onClick={() => this.showModal() } className="btn btn-link text-success pl-0 pt-2"><FormattedMessage id="app.spontaneous"/></button>;
      break;
      case "spontaneous":
        btn = <button type="button" onClick={() => this.showModal() } className="btn btn-sm btn-success mt-2"><FormattedMessage id="app.spontaneous"/></button>;
      break;
      case "jobApply":
        btn = <button type="button" onClick={() => this.showModal() } className="btn btn-lg btn-success mb-2"><FormattedMessage id="app.apply"/></button>;
      break;
      default:
        btn = <button type="button" onClick={() => this.showModal() } className="btn btn-lg btn-success mb-2"><FormattedMessage id="app.apply"/></button>;
    }

    return (
        <div>
            <div>{btn}</div>
            <ModalBootstrap show={this.state.showModal} onHide={() => this.handleClose() } centered>
                <ModalBootstrap.Header closeButton>          
                  <h5 className="mb-3 mt-3 text-center w-100"><FormattedMessage id="app.emailAsking" /></h5>               
                </ModalBootstrap.Header>
                <ModalBootstrap.Body>
                <div className="row justify-content-center">
                <div className="col-lg-8">
                    <input type="email" value={this.state.email} name="email" className="form-control popup-input" maxLength="100" onChange={this.handleChange}/>
                    <span className="text-danger">{this.state.errormessage ? <FormattedMessage id="error.email" /> : ""}</span>
                </div>
                </div>
                <div className="row justify-content-center">
                  <button
                  className="btn btn-sm btn-success mt-4"
                  onClick={() => {this.validateEmail()}}
                  >
                    <FormattedMessage id="app.submit" />
                  </button>
                </div>
                </ModalBootstrap.Body>
            </ModalBootstrap>
            
        </div>
    );
  }
}

export default Modal;
