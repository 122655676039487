//Fucking internet explorer
import 'babel-polyfill';
import 'core-js/features/array/flat';
import 'core-js/features/map';
import 'core-js/features/set';
import 'raf/polyfill';
//import {getEnvVariables} from './Utils/Validate.js';

import TagManager from 'react-gtm-module';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css';
import * as serviceWorker from './serviceWorker';
import './custom.scss';
import './atsV2.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import * as Sentry from '@sentry/browser';

if(process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://9e5bb39fb8e84554b68016ab0619df09@sentry.io/1427732"
  });
}

const tagManagerArgs = {
    gtmId: 'GTM-T6Z79JM'
}

TagManager.initialize(tagManagerArgs);

// should have been called before using it here
// ideally before even rendering your react app

ReactDOM.render(<App />,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
