import moment from 'moment';

export function cookieExist(cookie) {
  /* Test Cookie */
  //document.cookie = "update=E!RPn0ec8PrhfbLp**RuwQgF!O66IlnV_T]]6WTjBdufW.-G]TrOZSgnKMVtymHKW"; //add
  //document.cookie = "update=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; //remove
  let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
  if(cookieValue) {
    return cookieValue === cookie;
  }

  return false;
}

export function getCookie() {
  return document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
}

export function validateCookie(cookie) {
  return (cookie.length > 60 || cookie.length < 70);
}

export function setData(key, data) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function mapData(data, lg, dataObj) {
  let obj = {};
  if(Array.isArray(data)) {
    obj = formatData(data[0], lg, dataObj);
  }

  return obj;
}

function setCookie(val) {
  let cookie = val ? val : "";
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "token="+cookie+";sessionid=38afes7a8; path=/;";
}

function formatData(data, lg, dataObj) {
  let objList = ["Contact","Education","Language","Employer","Questions","Job","Region","id"];

  let res = objList.every(validateOwnProperty, data);

  if (res) {

    let contact = formatContactData(data["Contact"]);
    let contactValues = validateNull(contact);

    let job = formatJobUpdateData(data["Job"], lg);

    let jobDesc = formatJobDescSelonLangue(data["JobDesc"], lg);

    let jobsTitleList = formatJobTitleList(jobDesc, job);
    let rowData = formatRowData(jobDesc, data["JobWish"]);
    //let jobValues = Object.keys(job).length > 0;

    let region = formatRegionUpdateData(data["Region"], lg);
    //let regionValues = Object.keys(region).length > 0;

    let emploi = formatJobHistoryData(data["Employer"]);

    let doc = formatDoc(data["Contact"]);
    let address = formatAddressData(data["Contact"]);
    let education = formatEducationData(data["Education"]);
    let language = formatLanguageData(data["Language"]);

    let questions = formatQuestionsData(data["Questions"]);
    let jobReg = {postuledJob : job, regionsData: region, rowData: rowData, jobsTitleList: jobsTitleList};

    if(contactValues) {
      //setData("jobFairID", "0");
      //dataObj.jobFairID = "0";
      //setData("update", true);
      dataObj.update = true;
      dataObj.noValidate = true;
      //setData("f0", jobReg);
      dataObj.f0 = jobReg;
      //setData("fl1", contact);
      dataObj.fl1 = contact;
      //setData("ref", contact.email);
      dataObj.ref = contact.email;
      //setData("fc1", doc);
      dataObj.fc1 = doc;
      //setData("fl6", questions);
      dataObj.fl6 = questions;
      setCookie(data["SessKey"]);

      if(validateNull(address)) {
        //setData("fl2", address);
        dataObj.fl2 = address;
        //setData("fl3", education);
        dataObj.fl3 = education;
        //setData("fl4", language);
        dataObj.fl4 = language;
        //setData("fl5", emploi);
        dataObj.fl5 = emploi;
      }
    }
  }

  return dataObj;
}

function validateOwnProperty(ele) {
  return this.hasOwnProperty(ele);
}

function validateNull(obj) {
  for (let el in obj) {
    return (obj[el] !== null);
  }
}

function validateLength(obj, long) {
  for (let el in obj) {
    return (obj[el].length === long);
  }
}

function formatJobDescSelonLangue(allJobs, lg) {
  let a = [];
  if (Array.isArray(allJobs)) {
    if (allJobs.length > 0) {
      a = allJobs.map(function(j) {
        let obj = {};
        obj.id = j.ID;
        let arr = j.Job.split("/");
        obj.job = lg === "fr" ? arr[0] : arr[1];
        obj.category = j.Category;
        obj.subcategory = j.SubCat;
        return obj;
      });
    }
  }

  return a;
}

function formatJobTitleList(allJobs, category) {
  let jobTitleList = [];

  if (Array.isArray(allJobs) && Array.isArray(category)) {
      if (allJobs.length > 0 && category.length > 0) {
      for(let i = 0; i < category.length; i ++) {
        let jobs = allJobs.filter(j => j.category === category[i].value);
        if (jobs) {
          jobTitleList.push(jobs);
        }
      }
    }
  }

  return jobTitleList.flat();
}

function formatRowData(allJobs, appJobWish, lg) {
  let rowData = [];

  if (Array.isArray(allJobs) && Array.isArray(appJobWish)) {
    if (allJobs.length > 0 && appJobWish.length > 0) {
      for(let i = 0; i < appJobWish.length; i ++) {
        let val = allJobs.find(j => j.id === appJobWish[i].JobTitle);
        if (val) {
          let obj = {_original:val};
          rowData.push(obj);
        }
      }
    }
  }

  return rowData;
}

function formatJobUpdateData(data, lg) {

  let arr = [];
  let id = data.hasOwnProperty("ID") ? data["ID"] : null;
  let category = data.hasOwnProperty("Category") ? data["Category"] : null;

  if(Array.isArray(category) && Array.isArray(id)) {
    if (category.length > 0 && id.length > 0) {
      for(let i = 0; i < category.length; i ++) {
        let obj = {};
        let catArr = category[i].split("/");
        obj.value = id[i];
        obj.label = lg === "fr" ? catArr[0] : catArr[1];
        arr.push(obj);
      }
    }
  }

  return arr;
}

function formatRegionUpdateData(data, lg) {

  let arr = [];
  //let pj = {};
  let id = data.hasOwnProperty("Id") ? data["Id"] : null;
  let reg = data.hasOwnProperty("RegionName") ? data["RegionName"] : null;

  if(Array.isArray(reg) && Array.isArray(id)) {
    if (reg.length > 0 && id.length > 0) {
      const idDis = [...new Set(id)];
      const regDist = [...new Set(reg)];

      for(let i = 0; i < regDist.length; i ++) {
        let regArr = regDist[i].split("/");
        let obj = {};
        obj.value = idDis[i];
        obj.label = lg === "fr" ? regArr[0] : regArr[1];
        arr.push(obj);
      }
    }
  }

  return arr;
}

function formatContactData(data) {

  let obj = {};

  let phone = data.hasOwnProperty("Phone") ? data["Phone"] : null;
  let cell = data.hasOwnProperty("Cell") ? data["Cell"] : null;

  obj.fname = data.hasOwnProperty("Fname") ? data["Fname"] : null;
  obj.lname = data.hasOwnProperty("Lname") ? data["Lname"] : null;
  obj.email = data.hasOwnProperty("Email") ? data["Email"] : null;
  obj.phone1 = phone ? phone.slice(0,3) : null;
  obj.phone2 = phone ? phone.slice(3,6) : null;
  obj.phone3 = phone ? phone.slice(6) : null;
  obj.cell1 = cell ? cell.slice(0,3) : "";
  obj.cell2 = cell ? cell.slice(3,6) : "";
  obj.cell3 = cell ? cell.slice(6) : "";

  return obj;

}

function formatDoc(data) {
  let obj = {};

  obj.CvName = data.hasOwnProperty("CV") ? data["CV"] : "";
  obj.ClName = data.hasOwnProperty("CL") ? data["CL"] : "";
  return obj;
}

function formatAddressData(data) {

  let obj = {};
  obj.address = data.hasOwnProperty("Address") ? data["Address"] : null;
  obj.route = data.hasOwnProperty("Street") ? data["Street"] : null;
  obj.city = data.hasOwnProperty("City") ? data["City"] : null;
  obj.province = data.hasOwnProperty("Province") ? data["Province"] : null;
  obj.country = data.hasOwnProperty("Country") ? data["Country"] : null;
  obj.postalCode = data.hasOwnProperty("Zip") ? data["Zip"] : null;
  obj.additional = "";

  return obj;

}

function formatEducationData(data) {
  let obj = {};
  obj.lastDegree = data.hasOwnProperty("EducationLevel") ? data["EducationLevel"] : "";
  obj.lastDegreeInfo = data.hasOwnProperty("EducationLevelDescription") ? data["EducationLevelDescription"] : "";
  obj.degreeObtained = data.hasOwnProperty("DegreeObtained") ? data["DegreeObtained"].toString() : "";
  obj.association = data.hasOwnProperty("ProfessionalAssociation") ? data["ProfessionalAssociation"] : "";
  obj.skillCard = data.hasOwnProperty("SkillCard") ? data["SkillCard"] : "";
  obj.otherFormation = data.hasOwnProperty("OtherFormation") ? data["OtherFormation"] : "";

  return obj;
}

function formatLanguageData(data) {

  let obj = {};
  let sp = data.hasOwnProperty("SpokenLanguage") ? data["SpokenLanguage"] : "";
  let wr = data.hasOwnProperty("WrittenLanguage") ? data["WrittenLanguage"] : "";

  let regex = /[1-3]/g;
  let spa = sp.match(regex);
  let wra = wr.match(regex);

  obj.spFrLanguage = {"value": spa !== null ? spa[0] : ""};
  obj.spEnLanguage = {"value": spa !== null && spa.length === 2 ? spa[1] : ""};
  obj.wrFrLanguage = {"value": wra !== null ? wra[0] : ""};
  obj.wrEnLanguage = {"value": wra !== null && wra.length === 2 ? wra[1] : ""};
  obj.wrOther = data.hasOwnProperty("OtherWrittenLanguage") ? data["OtherWrittenLanguage"] : "";

  return obj;

}

function formatJobHistoryData(data) {

  let employer = data.hasOwnProperty("EmployerName") ? data["EmployerName"] : null;
  let former = data.hasOwnProperty("FormerJob") ? data["FormerJob"] : null;
  let task = data.hasOwnProperty("Task") ? data["Task"] : null;
  let start = data.hasOwnProperty("StartDate") ? data["StartDate"] : null;
  let end = data.hasOwnProperty("EndDate") ? data["EndDate"] : null;

  let obj = {
    employer: {value:[], validate:[], touch:[]},
    task: {value:[], validate:[], touch:[], count:[]},
    jobTitle: {value:[], validate:[], touch:[]},
    startDate: {value:[], validate:[], touch:[]},
    endDate: {value:[], validate:[], touch:[]},
    list:[],
    counter: 1
  };

  if(validateNull(employer) && validateNull(former) && validateNull(task) && validateNull(start) && validateNull(end) && validateLength(data, employer.length)) {

    obj.counter = employer.length;

    for(let i = 0; i < employer.length; i++) {
        obj.list.push(i + 1);

        obj.employer.value.push(employer[i]);
        obj.employer.validate.push(true);
        obj.employer.touch.push(true);

        obj.task.value.push(task[i]);
        obj.task.validate.push(true);
        obj.task.touch.push(true);
        obj.task.count.push(500 - task[i].length);

        obj.jobTitle.value.push(former[i]);
        obj.jobTitle.validate.push(true);
        obj.jobTitle.touch.push(true);

        obj.startDate.value.push(moment(start[i]));
        obj.startDate.validate.push(true);
        obj.startDate.touch.push(true);

        obj.endDate.value.push(moment(end[i]));
        obj.endDate.validate.push(true);
        obj.endDate.touch.push(true);
    }
  }
  return obj;
}

function formatQuestionsData(data) {

  let obj = {};

  obj.q1 = data.hasOwnProperty("Q1") ? data.Q1.toString() : "";
  obj.q2 = data.hasOwnProperty("Q2") ? data.Q2.toString() : "";
  obj.q2Details = data.hasOwnProperty("Q2Details") ? data.Q2Details : "";
  obj.q3 = data.hasOwnProperty("Q3") ? data.Q3.toString() : "";
  obj.q3Details = data.hasOwnProperty("Q3Details") ? data.Q3Details : "";
  obj.q4 = data.hasOwnProperty("Q4") ? data.Q4.toString() : "";
  obj.q5 = data.hasOwnProperty("Q5") ? data.Q5.toString() : "0";
  obj.q6 = data.hasOwnProperty("Q6") ? data.Q6.toString() : "";
  obj.count = data.hasOwnProperty("Q6") ? 500 - data.Q6.length : "";
  obj.q7 = data.hasOwnProperty("Q7") ? data.Q7 : 0;

  return obj;
}
