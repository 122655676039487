import React from 'react';
import worker from '../Images/carriere_banniere_inv.jpg';

const WorkerBanner = () => {
  return (
    <section className="py-4">
        <div className="container">
          <img className="mt-1 mb-1 img-fluid" src={worker} alt="Employees" />
        </div>
    </section>
  );
}

export default WorkerBanner;
