import React from 'react';

const TextAreaInput = (props) => {
  //const obj = {"whiteSpace":"pre-line"}
  return (
      <textarea className="form-control" rows="5" maxLength="500" {...props} />
  );
}

export default TextAreaInput;
