import React from 'react';
import {FormattedMessage} from 'react-intl';

const UploadFile = (props) => {
  return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text"><FormattedMessage id="app.upload"/></span>
        </div>
        <div className="custom-file">
          <input type="file" className="custom-file-input" name={props.name} onChange={props.method} accept={props.accept} />
          <label className="custom-file-label">{props.placeholder}</label>
        </div>
      </div>
  );
}

export default UploadFile;
