import React from 'react';
//import Select from 'react-select';

const SelectInput = (props) => {
  return (
    <select className="custom-select" name={props.name} value={props.value} onChange={props.method}>
        {props.options.map((item, index) => (
          <option key={index} value={item.value}>{item.label}</option>
        ))}
    </select>
  );
}

export default SelectInput;
