import React from 'react';
import {FormattedMessage} from 'react-intl';
import creer from '../Images/CREER_CMYK80.png';
import humaniser from '../Images/HUMANISER_CMYK80.png';
import depasser from '../Images/SE_DEPASSER_CMYK80.png';

class Identity extends React.Component {

  componentDidMount() {
    let top = document.getElementById("header").offsetTop;
    window.scrollTo(0, top);
  }

  render() {
    return (
      <div className="container mt-2">
        <div className="row text-center">
          <div className="col-sm-4">
            <img className="mt-1 mb-1 img-fluid" src={humaniser} alt="Humaniser" />
            <div className="mt-3"><strong><FormattedMessage id="app.humaniserLabel"/></strong></div>
            <div className="mt-2"><FormattedMessage id="app.humaniser"/></div>
          </div>
          <div className="col-sm-4">
            <img className="mt-1 mb-1 img-fluid" src={creer} alt="Creer" />
            <div className="mt-2"><strong><FormattedMessage id="app.creerLabel"/></strong></div>
            <div className="mt-2"><FormattedMessage id="app.creer"/></div>
          </div>
          <div className="col-sm-4">
            <img className="mt-1 mb-1 img-fluid" src={depasser} alt="Depasser" />
            <div className="mt-3"><strong><FormattedMessage id="app.depasserLabel"/></strong></div>
            <div className="mt-2"><FormattedMessage id="app.depasser"/></div>
          </div>
        </div>
        <hr />
        <div className="text-center mt-2"><strong className="text-center"><FormattedMessage id="app.engagementLabel"/></strong></div>
        <div className="text-center mt-2"><FormattedMessage id="app.engagement"/></div>
      </div>
    );
  }
}

export default Identity;
