import React from 'react';
import {FormattedMessage} from 'react-intl';
import Header from './Forms/Header';
import troisValeursFr from './Images/3ValeursBlancVert250X250.png';
import troisValeursEn from './Images/3ValuesBlancVert250X250.png';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, language: this.props.language };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    sessionStorage.clear();
  }

  startFresh = () => {
    let step = {"step" : "Search"};
    this.props.nextForm(step);
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.reload();
  }

  getLanguage = (val) => {
    this.props.setLanguage(val);
  }

  render() {
    const {language} = this.state;
    const logos3val = language === 'fr' ? troisValeursFr : troisValeursEn;

    if (this.state.hasError) {
      return (
        <div>
          <Header setLanguage={this.getLanguage} nextForm={this.setNextForm} language={language} />
          <div className="text-center">
          <h1 className="text-center"><FormattedMessage id="error.generic"/></h1>
          <button type="button" className="btn btn-success" onClick={() => this.startFresh()}><FormattedMessage id="app.reset"/></button>
          </div>
          <footer className="footer bg-dark pb-3">
            <div className="text-center mt-4">
            <img className="img-fluid" src={logos3val} alt="3 values Cogir" />
            <div className="container text-center mt-3">
              <div className="text-white">&copy; <FormattedMessage id="app.copyright"/></div>
            </div>
            <div className="container text-center mt-3 mb-2">
            <a href="https://www.facebook.com/CogirImmobilierRealEstate/" target="blank"><i className="fab fa-facebook-f fa-lg text-white"></i></a>
            <a href="https://twitter.com/CogirImmobilier" target="blank"><i className="fab fa-twitter fa-lg text-white pr-4 pl-4"></i></a>
            <a href="https://www.linkedin.com/company/soci-t-de-gestion-cogir" target="blank"><i className="fab fa-linkedin-in fa-lg text-white"></i></a>
            </div>
            </div>
          </footer>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
