import React from 'react';
import {FormattedMessage} from 'react-intl';
import Popup from 'reactjs-popup';
import {validateEmail} from '../Utils/Validate';
import axios from 'axios';
import moment from 'moment';
import ModalBootstrap from 'react-bootstrap/Modal';
import Modal from '../Controls/Modal.js';
import MetaTags from 'react-meta-tags';
import Origines from '../translations/origines.json';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon
} from "react-share";

class JobDescription extends React.Component {
  constructor(props) {
    super(props);

    let dataObj = this.props.dataObj;
    let noValidate = dataObj.noValidate;//JSON.parse(sessionStorage.getItem('noValidate'));
    let appliedTo = dataObj.appliedTo;//JSON.parse(sessionStorage.getItem('appliedTo'));
    const rowData = this.props.data;
    const job = rowData.en;
    
    this.state = {
        jobsDescription:[],
        scroll: "no value",
        email:"",
        emailBoot: "",
        emailValid:false,
        errorFromServer: false,
        errorFromServerMessage: [],
        noValidate : noValidate ? noValidate : false,
        rowData: rowData,
        appliedTo: appliedTo,
        isClosed: this.validatePst(appliedTo, dataObj, rowData),
        showCopyMessage: false,
        copyStatus: false,
        show: false,
        copyClass: "alert-success",
        errormessage: false,
        jobId: job[0].Id
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  validatePst = (pst, dataObj, rowData) => {
    let obj = this.props.language === "fr" ? pst.fr : pst.en;
    let stat = false;
    if (obj.EndDate) {
      let today = moment();
      if (moment(obj.EndDate).isBefore(today)) {
        stat = true;
        let oriname = Origines[dataObj.origin] ? Origines[dataObj.origin] : "";
        if(dataObj.origin !== "0") {
          let request = {};
          request.request = "support";
          request.jobId = obj.JobId + " - " + obj.Title + " " + rowData.fr[0].LocationName + " - ";
          request.ori = dataObj.origin + " - " + oriname;

          this.sendEmail(request);
        }
      }
    }
    return stat;
  }

  sendEmail = (request) => {
    const url = this.props.dataObj.env.urlJson;

    axios.post(url, request, { headers: {'Content-Type': 'application/json'}})
      .then((response)  => {
        //this.setState({errorFromServer: false});
      })
      .catch((error)  => {
        //this.setState({errorFromServer: true});
      });
  }

  componentDidMount() {
    let top = document.getElementById("header").offsetTop;
    window.scrollTo(0, top);

    window.addEventListener('scroll', this.handleScroll);
    window.history.replaceState({}, document.title, "/");
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  validateEmail = (email, status) => {
    const { rowData } = this.state;
    let val = true;
    let lg = this.props.language;
    const job = lg === 'fr' ? rowData.fr : rowData.en;

    const url = this.props.dataObj.env ? this.props.dataObj.env.urlJson : "https://jobs.cogir.net/ajax/ConnectDb.php";

    if (validateEmail(email)) {
      axios.post(url, {
        request: "validate",
        data: email,
        lg: lg,
        appliedTo: job[0].Id
      },{ headers: {'Content-Type': 'application/json'}})
      .then((response)  => {
        let obj = response.data;
        if (obj.exist) {
          this.goToEmailLooking(obj, status);
        }
        else {
          this.redirectTo(obj, status);
        }
      })
      .catch((error)  => {
        this.setState({errorFromServerMessage: this.state.errorFromServerMessage.concat(error.message)});
        this.setState({errorFromServer: true});
      });
    }

    else {
      val = false;
    }

    return val;
  }

  goToEmailLooking(obj, status) {
    let step = {"step" : "EmailLooking"};
    let dataObj = this.props.dataObj;

    if (status === "spontaneous") {
      dataObj.appliedTo = {fr:{Title:"Candidature spontanée"}, en:{Title:"Spontaneous application"}, type:"spontaneous"};
    }

    if(obj.email) {

      dataObj.fl1 = obj;
    }

    step.dataObj = dataObj;
    this.props.dataFrom(obj);
    this.props.nextForm(step);
  }

  redirectTo(obj, status) {
    let dataObj = this.props.dataObj;

    if (status === "spontaneous") {
      dataObj.appliedTo = {fr:{Title:"Candidature spontanée"}, en:{Title:"Spontaneous application"}, type:"spontaneous"};
    }

    if(obj.email) {

      dataObj.fl1 = obj;
    }

    dataObj.noValidate = true;
    let step = {"step" : "fc1"};

    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  handleScroll(event) {
    let btn = document.getElementById("applyBtn");
    let btnFx = document.getElementById("applyBtnFx");
    let scrY = window.scrollY;

    let btnFxPos = scrY + btnFx.offsetTop;
    let res = btn.offsetTop - btnFxPos;

    if(res < 0) {
      btnFx.style.visibility = "hidden";
      btn.style.visibility = "visible";
    }

    else {
      btnFx.style.visibility = "visible";
      btn.style.visibility = "hidden";
    }
  }

  goToNextForm = (value) => {
    let dataObj = this.props.dataObj;
    let step = {"step" : value};

    if(value === "fc1") {
      step = {"step" : value, "fromUpload" : true};
    }

    step.dataObj = dataObj;

    this.props.nextForm(step);
  }

  getShareableLink = () => {
    const data = this.props.language === 'fr' ? this.state.rowData.fr : this.state.rowData.en;
    let jobId = data[0].Id;

    let domain = window.location.hostname;
    let url = "https://jobs.cogir.net/";

    if (domain === "emplois.cogir.net") {
      url = "https://emplois.cogir.net/";
    }

    return url.concat("?job=",jobId,"&ori=", 2);
  }

  handleBoot = () => {
    this.setState({show: true});
  }

  copyToClipboard = () => {
    let link = this.getShareableLink();
    var vm = this;
    navigator.clipboard.writeText(link).then(function() {
      vm.setState({copyStatus: true});
      vm.setState({showCopyMessage: true});
      vm.setState({copyClass: "alert-success"});
    }, function() {
      vm.setState({showCopyMessage: true});
      vm.setState({copyStatus: false});
      vm.setState({copyClass: "alert-danger"});
    });

  }

  getValueFromClipboard = (val) => {
    return val;
  }

  handleClose = () => {
    this.setState({show: false});
    this.setState({showCopyMessage: false});
    this.setState({copyStatus: false});
  }

  moveData = (data) => {
    this.props.dataFrom(data);
  }

  setNextForm = (step) => {
    this.props.nextForm(step);
  }

  render() {
    const { errorFromServer, noValidate, rowData, isClosed, showCopyMessage, copyStatus, show, copyClass} = this.state;
    
    const lg = this.props.language;
    const data = this.props.language === 'fr' ? rowData.fr : rowData.en;
    const bodyEmailText = this.props.language === 'fr' ? "3500 collègues passionnés, faites carrière chez Cogir ! " : "3500 colleagues enthusiasts, make a career at Cogir ! ";

    const MyMetaTags = () => (
      <div className="wrapper">
          <MetaTags>
            <meta property="og:description" content={data[0].ShortDescription} />
            <meta property="og:title" content={data[0].Title} />
          </MetaTags>
        </div>
    )

    const ModalBoot = () => (
      <ModalBootstrap show={show} onHide={() => this.handleClose() }>
      <ModalBootstrap.Header closeButton>
          <div className="container text-center">
            <h4><FormattedMessage id="app.link" /></h4>
          </div>
        </ModalBootstrap.Header>
        <ModalBootstrap.Body>
          <div className="d-none d-md-block">
            <div className="mt-2 row justify-content-center">
              <div className="input-group mb-2 col">
                <div className="input-group-prepend">
                  <div className="input-group-text"><i
                  onClick={
                    () => {
                    this.copyToClipboard();
                  }}
                  className="far fa-copy pointer" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Copy">
                  </i>
                  </div>
                </div>
                <input type="text" className="form-control text-center" value={this.getShareableLink()} readOnly />
              </div>
            </div>
            { showCopyMessage &&
              <div className="mt-2 row justify-content-center">
              <div className={copyClass}>{ copyStatus ? <FormattedMessage id="app.successCopy" /> : <FormattedMessage id="app.errorCopy" /> }</div>
              </div>
            }
          </div>

        <div className="mt-2 d-block d-md-none">
            <a href={this.getShareableLink()} className="mr-2" target="blank">{this.getShareableLink()}</a>
            <i onClick={() => {
              this.copyToClipboard();
            }}
            className="far fa-copy pointer" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Copy">
            </i>
            { showCopyMessage &&
              <div className={copyClass}>{ copyStatus ? <FormattedMessage id="app.successCopy" /> : <FormattedMessage id="app.errorCopy" /> }</div>
            }
        </div>

        </ModalBootstrap.Body>
      </ModalBootstrap>
    )
    
      const ModalClosedPst = (props) => (
        <Popup
          open={true}
          modal
          closeOnDocumentClick
        >
        {close => (
          <div className="container text-center">
          <button className="btn btn-link close" onClick={close}>
          &times;
          </button>
          <div className="mb-3 mt-3"><FormattedMessage id="app.jobIsClosed" /></div>
          <hr />
          <p><FormattedMessage id="pst.modalclosed1var" /></p>
          <p><FormattedMessage id="pst.modalclosed2var" /></p>
          <div className="mb-3 mt-3"><FormattedMessage id="app.emailAskingJobClosedvar" /></div>
          <hr />
          <div className="row justify-content-md-center">
          <div className="col-4">
          <input type="email" id="email" name="email" className="form-control textCenter" maxLength="100"/>
          <span id="message" className="text-danger emailpopupmessage"><FormattedMessage id="error.email" /></span>
          </div>
          </div>
          <button
            className="btn btn-sm btn-success mt-4"
            onClick={() => {
              var email = document.getElementById("email").value;
              var message = document.getElementById("message");
              this.validateEmail(email, "spontaneous") ? close() : message.style.display = "block";
            }}
          >
            <FormattedMessage id="app.submit" />
          </button>
          </div>
          )}
        </Popup>
        )

    return (

      <div>
      <MyMetaTags />
            {data.length > 0 &&
              <div className="container mb-4">
              {data.map((pst) => (
                <div className="card" key={pst.Id}>
                <div className="d-none d-md-block">
                  <div className="card-header ">
                  <div className="d-flex justify-content-between">
                      <h4>{pst.Title}</h4>
                      <div>
                      <FacebookShareButton url={this.getShareableLink()} quote={pst.Title} className="mr-2">
                      <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                      <LinkedinShareButton url={this.getShareableLink()} title={pst.Title} summary={pst.ShortDescription} className="mr-2">
                      <LinkedinIcon size={32} round={true} />
                      </LinkedinShareButton>
                      <EmailShareButton url={this.getShareableLink()} subject={pst.Title} body={bodyEmailText} separator=" " className="mr-2">
                      <EmailIcon size={32} round={true} />
                      </EmailShareButton>
                      <i className="fas fa-share-alt fa-2x align-bottom pointer" onClick={ () => this.handleBoot() } aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Copy"></i>
                      <ModalBoot />
                      </div>
                      <button type="button" className="btn btn-success d-none d-md-block" onClick={() => this.goToNextForm("Search")}><FormattedMessage id="app.return"/></button>
                    </div>
                    </div>
                </div>
                <div className="d-block d-md-none">
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <h4>{pst.Title}</h4>
                        <button type="button" className="btn btn-success d-block d-md-none btnListJobMb" onClick={() => this.goToNextForm("Search")}><i className="fas fa-arrow-left"></i></button>
                    </div>
                    <FacebookShareButton url={this.getShareableLink()} quote={pst.Title} className="mr-2">
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <LinkedinShareButton url={this.getShareableLink()} title={pst.Title} summary={pst.ShortDescription} className="mr-2">
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <EmailShareButton url={this.getShareableLink()} subject={pst.Title} body={bodyEmailText} separator=" " className="mr-2">
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                    <i className="fas fa-share-alt fa-2x align-bottom pointer" onClick={ () => this.handleBoot() } aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Copy"></i>
                    <ModalBoot />
                  </div>
                </div>

                  <div className="card-body">
                    <div>{pst.LongDescription}</div>
                    <hr />
                    {pst.LocationName.length > 0 && <div><strong><FormattedMessage id="pst.LocationName"/></strong> : {pst.LocationName}</div>}
                    {pst.LocationCity.length > 0 && <div><strong><FormattedMessage id="pst.LocationCity"/></strong> : {pst.LocationCity}</div>}
                    {pst.JobStatus.length > 0 && <div><strong><FormattedMessage id="pst.JobStatus"/></strong> : {pst.JobStatus}</div>}
                    {pst.JobSchedule.length > 0 && <div><strong><FormattedMessage id="pst.JobSchedule"/></strong> : {pst.JobSchedule}</div>}
                    {pst.ReportingTo.length > 0 && <div><strong><FormattedMessage id="pst.Superior"/></strong> : {pst.ReportingTo}</div>}
                    {pst.HoursW.length > 0 && <div><strong><FormattedMessage id="pst.Hours"/></strong> : {pst.HoursW}</div>}
                    <hr />
                    <h5><FormattedMessage id="pst.ShortDescription"/></h5>
                    <div>{pst.ShortDescription}</div>
                    <div className="mt-3 mb-3"><strong><FormattedMessage id="pst.JobDuties"/></strong></div>
                    <ul>
                    {pst.JobDuties.map((item) =>
                      <div key={item.cat.id}>
                      {/*<h5>
                      {this.props.language === 'en' ? (item.cat.en ? item.cat.en : "") : (item.cat.fr ? item.cat.fr : "")}
                      </h5>*/}

                        {item.values.map((obj) =>
                          <li key={obj.id}>
                            {obj.txt}
                          </li>
                        )}

                      </div>
                    )}
                    </ul>
                    <hr />
                    <h5><FormattedMessage id="pst.PerfectCandidate"/></h5>
                    <ul>
                      {pst.PerfectCandidate.map((item, index) =>
                        <li key={index}>
                          {item}
                        </li>
                      )}
                    </ul>
                    <hr />
                    <h5><FormattedMessage id="pst.Qualifications"/></h5>
                    <ul>
                      {pst.Qualifications.map((item, index) =>
                        <li key={index}>
                          {item}
                        </li>
                      )}
                      {/*pst.SpokenLanguage.data && <li><FormattedMessage id="pst.spokenLevel"/>{this.props.language === 'fr' ? pst.SpokenLanguage.lvfr : pst.SpokenLanguage.lven }</li>*/}
                      {/*pst.WrittenLanguage.data && <li><FormattedMessage id="pst.writtenLevel"/>{this.props.language === 'fr' ? pst.WrittenLanguage.lvfr : pst.WrittenLanguage.lven}</li>*/}
                    </ul>
                    <hr />
                    <h5><FormattedMessage id="pst.Benefits"/></h5>
                    <ul>
                      {pst.Benefits.map((item, index) =>
                        <li key={index}>
                          {
                            <div>
                            {item.ben}
                            <ul>
                              {item.subBen.map((obj, index) =>
                                <li key={index}>
                                  {obj}
                                </li>
                              )}
                            </ul>
                            </div>
                          }
                        </li>
                      )}
                    </ul>
                    <hr/>
                    <div>{pst.FootNote}</div>
                  </div>
                </div>
              ))}
              </div>
            }
            <div className="container">
              <div className="row">
                <div className="col">
                {errorFromServer &&
                <div className="alert alert-danger" role="alert">
                  <p><FormattedMessage id="error.generic"/></p>
                  {/*errorFromServerMessage*/}
                </div>
                }
                </div>
            </div>
            </div>

            {
              isClosed ?
              <div>
                {noValidate === false && <ModalClosedPst /> }
                <div className="container">
                <div id="applyBtn" className="alert alert-danger text-center txtAlertPst" role="alert">
                  <FormattedMessage id="app.jobIsClosed"/>
                </div>
                </div>
                <nav id="applyBtnFx" className="navbar navbar-light fixed-bottom closedPst">
                    <div className="col text-center closedpst">
                      <FormattedMessage id="app.jobIsClosed"/>
                    </div>
                </nav>
              </div>
                :
                <div>
                <div id="applyBtn" className="row mb-4">
                    <div className="col text-center">
                      {data.length > 0 ?
                        noValidate ?
                          <button className="btn btn-lg btn-success mb-2" onClick={() => this.goToNextForm("fc1")}><FormattedMessage id="app.apply" defaultMessage="Apply"/></button>
                          :
                          <Modal prov="jobApply" language={lg} dataFrom={this.moveData} nextForm={this.setNextForm} dataObj={this.props.dataObj}/>
                        :
                        <div>
                          <button type="button" className="btn btn-success" onClick={() => this.goToNextForm("Search")}><FormattedMessage id="app.return"/></button>
                        </div>
                      }
                    </div>
                  </div>
                  <nav id="applyBtnFx" className="navbar navbar-light fixed-bottom openPst">
                      <div className="col text-center">
                      {noValidate ?
                      <button className="btn btn-lg btn-success mb-2" onClick={() => this.goToNextForm("fc1")}><FormattedMessage id="app.apply" defaultMessage="Apply"/></button>
                      :
                      <Modal prov="jobApply" language={lg} dataFrom={this.moveData} nextForm={this.setNextForm} dataObj={this.props.dataObj}/>
                    }
                      </div>
                  </nav>
                  </div>
            }
          </div>
    );
  }
}

export default JobDescription;