import React from 'react';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';
import TextInput from '../Controls/TextInput.js';
import MyDatePicker from '../Controls/DatePicker.js';
import TextAreaInput from '../Controls/TextArea.js';
import JobApplyAlert from '../Controls/InfoMessage.js';
import axios from 'axios';
import {validateRequired, validateTwoDates, validateDate, countCharBack} from '../Utils/Validate';
import {formatSubmitData} from '../Utils/Format';

class JobsHistory extends React.Component {
  constructor(props) {
    super(props);
    let dataObj = this.props.dataObj;
    let form = dataObj.fl5; //JSON.parse(sessionStorage.getItem('fl5'));

    this.state = {
      list:  (form) ? form.list : [1],
      counter: (form) ? form.counter : 1,
      employer: {value : (form) ? form.employer.value : [""], validate: (form) ? form.employer.validate : [false], touch: (form) ? form.employer.touch : [false]},
      task: {value : (form) ? form.task.value : [""], validate: (form) ? form.task.validate : [false], touch: (form) ? form.task.touch : [false] , count: (form) ? form.task.count : [500]},
      jobTitle: {value : (form) ? form.jobTitle.value : [""], validate: (form) ? form.jobTitle.validate : [false], touch: (form) ? form.jobTitle.touch : [false]},
      startDate: {value : (form) ? form.startDate.value : [new Date()], validate: (form) ? form.startDate.validate : [true], touch: (form) ? form.startDate.touch : [true]},
      endDate: {value : (form) ? form.endDate.value : [new Date()], validate: (form) ? form.endDate.validate : [true], touch: (form) ? form.endDate.touch : [true]},
      errorFromServer: false,
      errorFromServerMessage: "",
      jobApply: dataObj.appliedTo, //JSON.parse(sessionStorage.getItem('appliedTo')),
      update: dataObj.update, //JSON.parse(sessionStorage.getItem('update')) ? true : false
      submit: false,
      alreadyapplied: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);

  }

  componentDidMount() {
    let top = document.getElementById("header").offsetTop;
    window.scrollTo(0, top);
  }

  handleStartDate(date, index) {
    let a = [...this.state.startDate.value];
    a[index] = date;
    this.setState(prevState => ({ startDate: {...prevState.startDate, value: a}}), this.validateInput("startDate", date, index));
  }

  handleEndDate(date, index) {
    let a = [...this.state.endDate.value];
    a[index] = date;
    this.setState(prevState => ({ endDate: {...prevState.endDate, value: a}}), this.validateInput("endDate", date, index));
  }

  handleChange(e, index) {
    const value = e.target.value;
    const name = e.target.name;

    switch(name) {
      case "employer":
          let emp = [...this.state.employer.value];
          emp[index] = value;
          this.setState(prevState => ({ employer: {...prevState.employer, value: emp}}), this.validateInput(name, value, index));
          break;
      case "task":
          let tsk = [...this.state.task.value];
          tsk[index] = value;
          this.setState(prevState => ({ task: {...prevState.task, value: tsk}}), this.validateInput(name, value, index));
        break;
      case "jobTitle":
          let jbt = [...this.state.jobTitle.value];
          jbt[index] = value;
          this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, value: jbt}}), this.validateInput(name, value, index));
        break;
      default:

    }
  }

  validateInput = (name, value, index) => {

    switch(name) {
      case "employer":
          let empv = [...this.state.employer.validate];
          empv[index] = validateRequired(value);
          let empt = [...this.state.employer.touch];
          empt[index] = true;
          this.setState(prevState => ({ employer: {...prevState.employer, touch: empt}}));
          this.setState(prevState => ({ employer: {...prevState.employer, validate: empv}}));
        break;
      case "task":
          let tskv = [...this.state.task.validate];
          tskv[index] = validateRequired(value);
          let tskt = [...this.state.task.touch];
          tskt[index] = true;
          let count = [...this.state.task.count];
          count[index] = countCharBack(value, 500);
          this.setState(prevState => ({ task: {...prevState.task, touch: tskt}}));
          this.setState(prevState => ({ task: {...prevState.task, validate: tskv}}));
          this.setState(prevState => ({ task: {...prevState.task, count: count}}));
        break;
      case "jobTitle":
          let jbtv = [...this.state.jobTitle.validate];
          jbtv[index] = validateRequired(value);
          let jbtt = [...this.state.jobTitle.touch];
          jbtt[index] = true;
          this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, touch: jbtt}}));
          this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, validate: jbtv}}));
        break;
      case "startDate":
          let sdv = [...this.state.startDate.validate];
          sdv[index] = validateTwoDates(value, this.state.endDate.value[index]);
          let endv = [...this.state.endDate.validate];
          endv[index] = sdv[index];
          let sdt = [...this.state.startDate.touch];
          sdt[index] = true;
          this.setState(prevState => ({ startDate: {...prevState.startDate, touch: sdt}}));
          this.setState(prevState => ({ startDate: {...prevState.startDate, validate: sdv}}));
          this.setState(prevState => ({ endDate: {...prevState.endDate, validate: endv}}));
          break;
      case "endDate":
          let edv = [...this.state.endDate.validate];
          edv[index] = validateTwoDates(this.state.startDate.value[index], value);
          let edt = [...this.state.endDate.touch];
          edt[index] = true;
          this.setState(prevState => ({ endDate: {...prevState.endDate, touch: edt}}));
          this.setState(prevState => ({ endDate: {...prevState.endDate, validate: edv}}));
          break;
      default:
        // code block
    }
  }

  addEmployerObj = () => {
    const {update} = this.state;
   let e = [""];
   let t = [false];

   if (update) {
     this.setState(prevState => ({ employer: {...prevState.employer, value: e.concat(this.state.employer.value)}}));
     this.setState(prevState => ({ employer: {...prevState.employer, touch: t.concat(this.state.employer.touch)}}));
     this.setState(prevState => ({ employer: {...prevState.employer, validate: t.concat(this.state.employer.validate)}}));
   }

   else {
     this.setState(prevState => ({ employer: {...prevState.employer, value: this.state.employer.value.concat("")}}));
     this.setState(prevState => ({ employer: {...prevState.employer, touch: this.state.employer.touch.concat(false)}}));
     this.setState(prevState => ({ employer: {...prevState.employer, validate: this.state.employer.validate.concat(false)}}));
   }
  }
  removeEmployerObj = (ind) => {
    let v = [...this.state.employer.value];
    let t = [...this.state.employer.touch];
    let va = [...this.state.employer.validate];
    v.splice(ind, 1);
    t.splice(ind, 1);
    va.splice(ind, 1);

    this.setState(prevState => ({ employer: {...prevState.employer, value: v}}));
    this.setState(prevState => ({ employer: {...prevState.employer, touch: t}}));
    this.setState(prevState => ({ employer: {...prevState.employer, validate: va}}));
  }

  addTaskObj = () => {
    const {update} = this.state;
    let e = [""];
    let t = [false];
    let n = [500];

    if (update) {
      this.setState(prevState => ({ task: {...prevState.task, value: e.concat(this.state.task.value)}}));
      this.setState(prevState => ({ task: {...prevState.task, touch: t.concat(this.state.task.touch)}}));
      this.setState(prevState => ({ task: {...prevState.task, validate: t.concat(this.state.task.validate)}}));
      this.setState(prevState => ({ task: {...prevState.task, count: n.concat(this.state.task.count)}}));
    }

    else {
      this.setState(prevState => ({ task: {...prevState.task, value: this.state.task.value.concat("")}}));
      this.setState(prevState => ({ task: {...prevState.task, touch: this.state.task.touch.concat(false)}}));
      this.setState(prevState => ({ task: {...prevState.task, validate: this.state.task.validate.concat(false)}}));
      this.setState(prevState => ({ task: {...prevState.task, count: this.state.task.count.concat(500)}}));
    }
  }
  removeTaskObj = (ind) => {
    let v = [...this.state.task.value];
    let t = [...this.state.task.touch];
    let va = [...this.state.task.validate];
    let ct = [...this.state.task.count];
    v.splice(ind, 1);
    t.splice(ind, 1);
    va.splice(ind, 1);
    ct.splice(ind, 1);

    this.setState(prevState => ({ task: {...prevState.task, value: v}}));
    this.setState(prevState => ({ task: {...prevState.task, touch: t}}));
    this.setState(prevState => ({ task: {...prevState.task, validate: va}}));
    this.setState(prevState => ({ task: {...prevState.task, count: ct}}));
  }

  addJobTitleObj = () => {
    const {update} = this.state;
    let e = [""];
    let t = [false];

    if (update) {
      this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, value: e.concat(this.state.jobTitle.value)}}));
      this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, touch: t.concat(this.state.jobTitle.touch)}}));
      this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, validate: t.concat(this.state.jobTitle.validate)}}));
    }

    else {
      this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, value: this.state.jobTitle.value.concat("")}}));
      this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, touch: this.state.jobTitle.touch.concat(false)}}));
      this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, validate: this.state.jobTitle.validate.concat(false)}}));
    }
  }
  removeJobTitleObj = (ind) => {
    let v = [...this.state.jobTitle.value];
    let t = [...this.state.jobTitle.touch];
    let va = [...this.state.jobTitle.validate];
    v.splice(ind, 1);
    t.splice(ind, 1);
    va.splice(ind, 1);

    this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, value: v}}));
    this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, touch: t}}));
    this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, validate: va}}));
  }

  addStartDateObj = () => {
    const {update} = this.state;
    let e = [new Date()];
    let t = [false];

    if (update) {
      this.setState(prevState => ({ startDate: {...prevState.startDate, value: e.concat(this.state.startDate.value)}}));
      this.setState(prevState => ({ startDate: {...prevState.startDate, touch: t.concat(this.state.startDate.touch)}}));
      this.setState(prevState => ({ startDate: {...prevState.startDate, validate: t.concat(this.state.startDate.validate)}}));
    }

    else {
      this.setState(prevState => ({ startDate: {...prevState.startDate, value: this.state.startDate.value.concat(new Date())}}));
      this.setState(prevState => ({ startDate: {...prevState.startDate, touch: this.state.startDate.touch.concat(false)}}));
      this.setState(prevState => ({ startDate: {...prevState.startDate, validate: this.state.startDate.validate.concat(false)}}));
    }
  }
  removeStartDateObj = (ind) => {
    let v = [...this.state.startDate.value];
    let t = [...this.state.startDate.touch];
    let va = [...this.state.startDate.validate];
    v.splice(ind, 1);
    t.splice(ind, 1);
    va.splice(ind, 1);

    this.setState(prevState => ({ startDate: {...prevState.startDate, value: v}}));
    this.setState(prevState => ({ startDate: {...prevState.startDate, touch: t}}));
    this.setState(prevState => ({ startDate: {...prevState.startDate, validate: va}}));
  }

  addEndDateObj = () => {
    const {update} = this.state;
    let e = [new Date()];
    let t = [false];

    if (update) {
      this.setState(prevState => ({ endDate: {...prevState.endDate, value: e.concat(this.state.endDate.value)}}));
      this.setState(prevState => ({ endDate: {...prevState.endDate, touch: t.concat(this.state.endDate.touch)}}));
      this.setState(prevState => ({ endDate: {...prevState.endDate, validate: t.concat(this.state.endDate.validate)}}));
    }

    else {
      this.setState(prevState => ({ endDate: {...prevState.endDate, value: this.state.endDate.value.concat(new Date())}}));
      this.setState(prevState => ({ endDate: {...prevState.endDate, touch: this.state.endDate.touch.concat(false)}}));
      this.setState(prevState => ({ endDate: {...prevState.endDate, validate: this.state.endDate.validate.concat(false)}}));
    }
  }
  removeEndDateObj = (ind) => {
    let v = [...this.state.endDate.value];
    let t = [...this.state.endDate.touch];
    let va = [...this.state.endDate.validate];
    v.splice(ind, 1);
    t.splice(ind, 1);
    va.splice(ind, 1);

    this.setState(prevState => ({ endDate: {...prevState.endDate, value: v}}));
    this.setState(prevState => ({ endDate: {...prevState.endDate, touch: t}}));
    this.setState(prevState => ({ endDate: {...prevState.endDate, validate: va}}));
  }

  setTouchValue = (obj) => {

    const { list } = this.state;
    let newObj = [];

    list.map((value, index) => {
      newObj[index] = (obj.validate[index] === false) ? true : false;
      return newObj;
    });

    return newObj;

  }

  getValidateValue = (obj) => {
    const { list } = this.state;
    let res = true;

    for (let i = 0; i < list.length; i++) {
      if (obj.validate[i] === false) {res = false}
    }

    return res;
  }

  addBox = () => {
    const ind = this.state.counter;
    this.setState((state) => ({
      counter: state.counter + 1
    }));

    this.setState({
      list: this.state.list.concat(ind + 1)
    });

    this.addEmployerObj();
    this.addTaskObj();
    this.addJobTitleObj();
    this.addStartDateObj();
    this.addEndDateObj();
  }

  removeBox = (ind) => {
    let a = [...this.state.list];
    //let ind = a.indexOf(item);
    a.splice(ind, 1);
    this.setState({
      list: a
    });
    this.removeEmployerObj(ind);
    this.removeTaskObj(ind);
    this.removeJobTitleObj(ind);
    this.removeStartDateObj(ind);
    this.removeEndDateObj(ind);
  }

  handleSubmit(event) {
    let dataObj = this.props.dataObj;
    const { list, counter, employer, jobTitle, task, startDate, endDate } = this.state;
    const lg = this.props.language;

    let el = this.setTouchValue(employer);
    let tl = this.setTouchValue(task);
    let jt = this.setTouchValue(jobTitle);
    let sd = this.setTouchValue(startDate);
    let ed = this.setTouchValue(endDate);

    this.setState(prevState => ({ employer: {...prevState.employer, touch: el}}));
    this.setState(prevState => ({ jobTitle: {...prevState.jobTitle, touch: jt}}));
    this.setState(prevState => ({ task: {...prevState.task, touch: tl}}));
    this.setState(prevState => ({ startDate: {...prevState.startDate, touch: sd}}));
    this.setState(prevState => ({ endDate: {...prevState.endDate, touch: ed}}));

    let emplValue = {"employer": employer, "jobTitle" : jobTitle ,"task" : task, "startDate": startDate, "endDate": endDate, "list": list, "counter": counter};

    if(this.getValidateValue(startDate) && this.getValidateValue(endDate) && this.getValidateValue(employer) && this.getValidateValue(task) && this.getValidateValue(jobTitle)) {
      let formData = formatSubmitData(emplValue, lg, dataObj);
      let object = {};
      formData.forEach((value, key) => {object[key] = value});
      this.submitFormData(formData, object);
    }

    event.preventDefault();
  }

  submitFormData = (formData, object) => {
    this.setState({submit:true});
    let dataObj = this.props.dataObj;
    const {update} = this.state;
    let emailref = dataObj.ref; //JSON.parse(sessionStorage.getItem('ref'));

    if(update) {
      formData.append('Update', "1");
      formData.append('EmailRef', emailref);
    }

    const url = this.props.dataObj.env ? this.props.dataObj.env.urlFormData : "https://jobs.cogir.net/ajax/processApplicant.php"; //Default devrait être prod

    axios({
          method: 'post',
          url: url,
          data: formData,
          responseType:'text',
          config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
          let fl1 = res.data;
            if (fl1 === "") {
              /*let dataObj = this.props.dataObj;
              let step = {"step" : "conf"};
              step.dataObj = dataObj;
              this.props.nextForm(step);*/
              this.goToNextForm("conf");
            }
            else {
              if (fl1.hasOwnProperty("alreadyapplied")) {
                this.setState({alreadyapplied: fl1.alreadyapplied});
              }
              else {
                this.setState({errorFromServerMessage: res.data});
                this.setState({errorFromServer: true});
              }
            }
          })
          .catch(error => {
          this.setState({errorFromServerMessage: error.message});
          this.setState({errorFromServer: true});
        })
  }

  goToNextForm = (value) => {
    let dataObj = this.props.dataObj;
    let step = {"step" : value};

    if(value === "conf") {
      dataObj.reset = true;
      dataObj.update = true; //permet de reinitialiser ts les controles
    }

    if(value === "fc1") {
      step = {"step" : value, "fromUpload" : true};
    }
    step.dataObj = dataObj;
    this.props.nextForm(step);
  }

  render() {
    const lg = this.props.language;
    const { list, employer, task, jobTitle, startDate, endDate, errorFromServer, errorFromServerMessage, jobApply, update, alreadyapplied } = this.state;
    const applied = lg === "fr" ? jobApply.fr : jobApply.en;

    return (
      <div>
        <div className="container mb-4">
        <JobApplyAlert gotToPrev={() => this.goToNextForm("Search")} jobApply={applied.Title} type={jobApply.type} />
          <form ref={this.form} onSubmit={this.handleSubmit} noValidate>
                  <h4><FormattedMessage id="progress.jobs"/></h4>
                  <small className="text-muted"><FormattedMessage id="app.subtitleJob"/></small>
                  <hr />
                  <div className="text-center mt-3">
                  {update && list.length < 3 &&
                  <button type="button" className="btn btn-outline-success" onClick={() => this.addBox()}><FormattedMessage id="app.add"/></button>
                  }
                  </div>
                  {employer.value.map((item, index) => (
                    <div key={index}>
                    <div>
                        <div className="card mt-3">
                            <div className="card-header d-flex justify-content-between">
                              <div>{index + 1} <FormattedMessage id="app.formJob"/></div>
                              {employer.value.length > 1 &&
                                <div>
                                <button type="button" className="btn btn-danger" onClick={() => this.removeBox(index)}><FormattedMessage id="app.remove"/></button>
                                </div>
                                }
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-2 ">
                                  <FormattedMessage id="app.employer" />
                                  </div>
                                  <div className="col-sm-6">
                                  <TextInput name="employer" value={employer.value[index]} onChange={(e) => this.handleChange(e, index)} />
                                  <p><span className="text-danger">{(employer.touch[index] && !employer.validate[index]) ? <FormattedMessage id="error.required"/> : ""}</span></p>

                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-2 ">
                                  <FormattedMessage id="app.jobTitle" />
                                  </div>
                                  <div className="col-sm-6">
                                  <TextInput name="jobTitle" value={jobTitle.value[index]} onChange={(e) => this.handleChange(e, index)} />
                                  <p><span className="text-danger">{(jobTitle.touch[index] && !jobTitle.validate[index]) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-2 ">
                                  <FormattedMessage id="app.task" />
                                  </div>
                                  <div className="col-sm-6">
                                  <TextAreaInput name="task" value={task.value[index]} onChange={(e) => this.handleChange(e, index)} />
                                  <small>{task.count[index]} <FormattedMessage id="app.characterCount"/></small>
                                  <p><span className="text-danger">{(jobTitle.touch[index] && !jobTitle.validate[index]) ? <FormattedMessage id="error.required"/> : ""}</span></p>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-sm-2 ">
                                  <FormattedMessage id="app.startDate" />
                                  </div>
                                  <div className="col-sm-6">
                                  <MyDatePicker
                                    selected={validateDate(startDate.value[index]) ? startDate.value[index] : new Date(startDate.value[index])}
                                    onChange={(date) => this.handleStartDate(date, index)}
                                    locale={lg}
                                    dateFormat={lg === 'fr' ? "d MMMM yyyy" : "MMMM d, yyyy"}
                                    maxDate={new Date()}
                                    placeholderText="Click to select a date"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-2 ">
                                  <FormattedMessage id="app.endDate" />
                                  </div>
                                  <div className="col-sm-6">
                                  <MyDatePicker
                                    selected={validateDate(endDate.value[index]) ? endDate.value[index] : new Date(endDate.value[index])}
                                    onChange={(date) => this.handleEndDate(date, index)}
                                    locale={lg}
                                    dateFormat={lg === 'fr' ? "d MMMM yyyy" : "MMMM d, yyyy"}
                                    maxDate={new Date()}
                                    placeholderText="Click to select a date"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                              <p><span className="text-danger">{(endDate.touch[index] && !endDate.validate[index]) ? <FormattedMessage id="error.dateLength"/> : ""}</span></p>
                                </div>
                              </div>
                              </div>
                            </div>
                      </div>
                    </div>
                  ))}
                  <div className="text-center mt-3">
                  {!update && list.length < 3 &&
                  <button type="button" className="btn btn-outline-success" onClick={() => this.addBox()}><FormattedMessage id="app.add"/></button>
                  }
                  </div>
                  <div>
                  {errorFromServer &&
                    <div className="alert alert-danger" role="alert">
                      <p><FormattedMessage id="error.generic"/> {errorFromServerMessage}</p>
                      {errorFromServerMessage === "A1" ? <FormattedMessage id="error.emailAlreadyExist"/> : ""}
                    </div>
                    }

                    { alreadyapplied &&
                      <div className="alert alert-danger" role="alert">
                        <div><FormattedMessage id="error.alreadyapplied" values={{date: moment(alreadyapplied).format('DD-MM-YYYY')}}/></div>
                      </div>
                    }
                  </div>
            <hr />
            <div className="row">
              <div className="col-6 col-sm-6">
                  <button type="button" className="btn btn-success mb-2 text-white" onClick={() => this.goToNextForm("fl3")}><FormattedMessage id="app.previous" defaultMessage="Previous" /></button>
              </div>
              <div className="col-6 col-sm-6 text-right">
                  <button type="submit" className="btn btn-success mb-2" disabled={this.state.submit}><FormattedMessage id="app.submit"/></button>
              </div>
            </div>
              <div className="row mt-4">
                <div className="col-6 col-sm-6">
                <button type="button" className="btn btn-outline-secondary" onClick={() => this.goToNextForm("fc1")}><FormattedMessage id="app.shortForm"/></button>
                </div>
              </div>
          </form>
        </div>
      </div>
    );
  }
}

export default JobsHistory;
