import React from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {formatVolet, formatSearchTable, formatList, setJobApply } from '../Utils/Search';

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorFromServer: false,
      residenceJob: {fr:[], en:[]}
    }
  };

  componentDidMount() {

    let top = document.getElementById("header").offsetTop;
    window.scrollTo(0, top);

    const resid = this.props.dataObj.resid;

    const url = this.props.dataObj.env ? this.props.dataObj.env.urlJson : "http://scripts.local"; //Default devrait être prod.

    axios.post(url, {
          request: "residenceJob",
          resid: resid
        },{ headers: {'Content-Type': 'application/json'}})
        .then((response)  => {
          let cat = formatList(response.data.cat, "cat");
          let reg = formatList(response.data.reg, "reg");
          let vol = formatVolet(response.data.vol);
          let job = formatSearchTable(response.data, cat, reg, vol);
          this.setState({ residenceJob : job });
        })
        .catch((error)  => {
          this.setState({errorFromServer: true});
        });
  }

  setDataObj = (valueToAdd) => {
    let dataObj = this.props.dataObj;
    dataObj.appliedTo = valueToAdd;

    return dataObj;
  }


  getDataSelected = (Id) => {
      let step = {"step" : "JobDesc"};

      let data = {
        fr: this.state.residenceJob.fr.filter(d => d.Id === Id),
        en: this.state.residenceJob.en.filter(d => d.Id === Id)
      };

      let objFormat = setJobApply(data);
      step.dataObj = this.setDataObj(objFormat);

      this.props.dataFrom(data);
      this.props.nextForm(step);
    }

  render() {
    const {residenceJob} = this.state;
    const data = this.props.language === "fr" ? residenceJob.fr : residenceJob.en;

    return (
      <div>
        <div className="container mb-4">
        <div id="jobsList">
        {data && data.length > 0 ?
          data.map((pst) => (
            <div className="card mb-4" key={pst.Id}>
              <div className="card-header d-flex justify-content-between">
                <h4>{pst.Title}</h4>
                <button type="button" className="btn btn-success d-none d-md-block" onClick={() => this.getDataSelected(pst.Id)}><FormattedMessage id="app.moreinfo"/></button>
                <button type="button" className="btn btn-sm btn-success d-block d-md-none" onClick={() => this.getDataSelected(pst.Id)}><i className="fas fa-plus"></i></button>
              </div>
              <div className="card-body">
              <div><strong><FormattedMessage id="app.dateopen"/> : </strong>{pst.DateOpen}</div>
               <div><strong><FormattedMessage id="app.category2"/> : </strong>{pst.Category}</div>
                <div><strong><FormattedMessage id="pst.LocationCity"/> : </strong>{pst.LocationCity}</div>
                <div><strong><FormattedMessage id="pst.LocationName"/> : </strong>{pst.LocationName}</div>
                <hr />
                <div>{pst.ShortDescription}</div>
                <div className="text-center mt-4">
                </div>
              </div>
            </div>
          ))
          :
          <div>No job for that place</div>
        }
        </div>
        </div>
      </div>
    );
  }
}

export default Landing;
